import { filterContainerHeight, menuContainerTop } from './impl';
import { createGlobalStyle } from 'styled-components';
import { css } from 'emotion';

export const Global = createGlobalStyle`
  #mainBody {
    background: var(--gray-100);
  }
`;

export const filler = height => css`
  display: block;
  height: ${height}px;
`;

export const storeContainer = css`
  @media (max-width: 1024px) {
    background: var(--gray-200);
    margin: -24px;
    padding: 24px;
  }
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'store-info store-info'
    'menu-container menu-container'
    'filter-sort-button search-container'
    'result-clear-container result-clear-container'
    'results-container results-container';

  .store-info {
  }

  .filter-sort-button {
    grid-area: filter-sort-button;
    padding: 14px 0;
  }

  .filter-container {
    grid-area: filter-container;

    background: var(--gray-100);
    border-right: 1px solid var(--gray-300);
  }

  .search-container {
    grid-area: search-container;
    padding: 14px 0 14px 24px;
  }

  .result-clear-container {
    grid-area: result-clear-container;
    padding: 14px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      color: var(--gray-700);
    }
    .divider {
      align-self: stretch;
      width: 1px;
      margin: 0 16px 0 22px;
      background: var(--gray-400);
    }
  }

  .menu-container {
    @media (max-width: 1024px) {
      box-shadow: 0 4px 6px -4px var(--gray-400);
    }
    position: sticky;
    top: ${menuContainerTop};
    z-index: 100;
    grid-area: menu-container;
    margin: 0 -24px;
    padding: 14px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    background: var(--gray-100);

    > p:first-of-type {
      text-transform: uppercase;
      color: var(--gray-500);
    }

    .menu-filters-container {
      background: var(--gray-100);
      width: 100%;
      overflow-x: scroll;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 25px;

      .scroll-indicator {
        flex: 0 0 1px;
        width: 1px;
        height: 1px;
      }

      &:after {
        content: '';
        display: block;
        flex: 0 0 1px;
        width: 1px;
        height: 1px;
      }

      button {
        &.active {
          color: var(--purp-300);
        }
        & + & {
          margin-left: 25px;
        }
      }
    }

    button.arrow-scroll {
      margin-left: 24px;
    }
  }

  .results-container {
    grid-area: results-container;
    @media (max-width: 1023px) {
      padding: 0 24px;
      margin: 0 -24px;
    }

    .product-anchor:not(:first-of-type) {
      margin-top: 18px;
    }

    .product-anchor {
      text-transform: uppercase;
      margin-bottom: 25px;

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  .result-pagination-container {
    display: flex;
    justify-content: center;
    padding: 40px 5px 25px 5px;

    .ant-pagination-item-active {
      border-color: #473dcc !important;
      a {
        color: #473dcc !important;
      }
    }
    .ant-pagination-item:hover {
      border-color: #6b57f8 !important;
      a {
        color: #6b57f8 !important;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: auto 278px 1fr;
    column-gap: 0;
    grid-template-areas:
      'store-info store-info store-info'
      'menu-container menu-container menu-container'
      'filter-sort-button search-container result-clear-container'
      'results-container results-container results-container';

    .filter-sort-button {
    }

    .search-container {
      padding: 14px 24px;
    }

    .result-clear-container {
    }

    .menu-container {
    }

    .results-container {
      background: var(--gray-200);
    }
  }

  @media (min-width: 1024px) {
    margin-right: -24px;
    grid-template-columns: 260px 1fr;
    grid-template-areas:
      'filter-container store-info'
      'filter-container menu-container'
      'filter-container result-clear-container'
      'filter-container results-container';

    .result-clear-container {
      padding: 14px 0px;

      .sort-by-container {
        margin-left: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          color: var(--gray-700);
        }
        > div {
          margin-left: 0px;
        }

        .ant-select-selection-placeholder,
        .ant-select-selection-item,
        .ant-select-arrow {
          opacity: 1;
          color: var(--purp-200);
          text-transform: uppercase;
        }
      }
    }

    .filter-container {
      .filter-sticky-container {
        position: sticky;
        top: '49px';
        z-index: 100;
        @media (min-width: 1024px) {
          height: ${filterContainerHeight};
          overflow: scroll;
        }
      }
    }

    .menu-container {
      margin: 0;
    }

    .results-container {
      background: var(--gray-200);
      padding: 0 24px;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 260px 1fr 324px;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'filter-container menu-container store-info'
      'filter-container result-clear-container store-info'
      'filter-container results-container store-info';

    .menu-container {
    }

    .result-clear-container {
      .sort-by-container {
        .ant-select-open {
          min-width: 200px;
        }
      }
    }

    .results-container {
      box-shadow: inset 0 4px 6px -4px var(--gray-400);
      background: var(--gray-200);
      padding: 0 24px;
    }
  }

  @media (min-width: 1440px) {
    margin-right: 0;

    .menu-container {
      padding: 14px 0 14px 24px;
    }

    .results-container {
      background: var(--gray-200);
      padding-left: 24px;
    }
  }
`;
