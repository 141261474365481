import { component, hocWithMappings as hoc } from 'utils/views';
import Template from './template';
import { compose } from 'utils/core/funcy';
import controller from './controller';
import controllerFilter from '../Filter/controller';
import controllerSort from '../../template/controller.sort';
import { createTasks } from './tasks';
import { createViewModel } from '../Filter/ux/viewModel';

const TemplateWithFilterController = hoc(
  'Filter Modal component - Filter ux',
  controllerFilter
);
const TemplateWithController = hoc('Filter Modal component - UX', controller);
const TemplateWithSortController = hoc(
  'Filter Modal component - Sort UX',
  controllerSort
);
const FullTemplate = compose(
  TemplateWithFilterController,
  TemplateWithSortController,
  TemplateWithController
)(Template);

export default component({
  Template: FullTemplate,
  createViewModel,
  createTasks,
});
