import * as analyticsImpl from 'analytics';
import { debounce as debounceImpl } from 'utils/core/funcy';

const dependencies = {
  analytics: analyticsImpl,
  debounce: debounceImpl,
};

const StoreListingAnalyticsController = ({ storeFront } = {}) => {
  const { analytics } = dependencies;

  const trackProductsSearched = query => {
    if (query.length > 0) {
      analytics.productsSearched(query, storeFront);
    }
  };

  const trackProductClicked = product => {
    analytics.productClicked(product, storeFront);
  };

  const trackProductAdded = product => {
    analytics.productAdded(product, storeFront);
  };

  const trackProductRemoved = product => {
    analytics.productRemoved(product, storeFront);
  };

  const trackProductListFiltered = filters => {
    analytics.productListFiltered(filters, storeFront);
  };

  return {
    trackProductsSearched,
    trackProductClicked,
    trackProductAdded,
    trackProductRemoved,
    trackProductListFiltered,
  };
};

export default StoreListingAnalyticsController;

export const __test__ = {
  dependencies,
};
