import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { initialPath } from '../app/viewModel';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const LANDING_PATH_PATTERN = /\/landing/;

const redirectPath = viewModelAttribute(initialPath, initialPath =>
  LANDING_PATH_PATTERN.test(initialPath) ? '/' : initialPath
);

const accessors = { redirectPath };

export const createViewModel = createViewModelMap(accessors);
