import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

export const getStoreFrontsKey = `${base}-getStoreFronts`;

export const eventMap = {
  initialized: () => ({}),
  changedDetails: details => ({ details }),
};

export const { eventNames, events } = createEvents(base, eventMap);
