import { events, signupKey } from './events';

import { allRuleResults } from './passwordComplianceRules';
import { errorMapper as authErrorMapper } from './errorMapper';
import { signup as createAuthAccount } from 'utils/auth0';
import { createTasks as createLoginTasks } from '../login/tasks';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { route } from 'router';

export const createTasks = dispatch => {
  const init = async () => {};
  const signupRequest = createRequestTasks(signupKey, dispatch);
  const { login } = createLoginTasks(dispatch);

  const display = () => {
    route({ path: '/signup' });
  };

  const changeDetails = details => {
    dispatch(events.changedDetails(details));
  };

  const displayError = error => {
    changeDetails({ errorMessage: error });
  };

  const _createAccount = errorMapper => async ({ email, password }) => {
    const result = await createAuthAccount({ email, password });

    const { errors } = result;

    if (errors.length > 0) throw new Error(errorMapper(errors[0].message));
  };

  const createAccount = _createAccount(authErrorMapper);

  const signup = async ({ email, password }) => {
    changeDetails({ errorMessage: '' });

    try {
      await signupRequest.runRequest(createAccount({ email, password }));
    } catch (e) {
      displayError(e.message);
    }
  };

  const updatePasswordStatus = async password => {
    changeDetails({
      passwordModified: true,
      complianceRulesMet: allRuleResults(password),
    });
  };

  return {
    display,
    init,
    login,
    signup,
    updatePasswordStatus,
  };
};
