import { createTasks as createStoreTasks } from '../../../tasks';
import { noOp } from 'utils/core/funcy';

export const createTasks = dispatch => {
  const storeTasks = createStoreTasks(dispatch);

  const init = noOp;

  return {
    init,
    ...storeTasks,
  };
};
