import * as styles from './styles';

import { Alert, AutoComplete, Button, Input, Select } from 'antd';
import React, { Fragment } from 'react';

import { Field } from 'formik';
import Link from 'components/Link';
import ProgressBar from 'components/ProgressBar';
import RequestStatus from 'components/RequestStatus';
import { SearchOutlined } from '@ant-design/icons';
import config from 'config';
import { form } from 'utils/forms';
import { noOp } from 'utils/core/funcy';
import { uris } from 'router';

const { browseStoresUri } = uris;

const { Option } = AutoComplete;

const AccountSetup = ({
  cancel = noOp,
  onFormInitialized = noOp,
  onFormSubmit = noOp,
  onSave = noOp,
  onFilterChanged = noOp,
  onStateChanged = noOp,
  onValidate = noOp,
  removeVendor = noOp,
  onVendorSelected = noOp,

  addBuyingLicenseRequestStatus,
  canAttemptSave,
  canChangeState = true,
  contactInfo = {},
  countryState,
  getJurisdictionsRequestStatus,
  getStoreFrontUserVendorsRequestStatus,
  hasAGoodRep = false,
  hasValidContactDetails,
  hasValidShoppingDetails,
  /* isLoadingVendors = false, */
  selectedVendorsList = [],
  showStateChangeWarning = false,
  states = [],
  unselectedVendors = [],
  shouldRefineSearch = false,
  vendorFilter = '',
  /* userInfo, */
} = {}) => {
  const selectedVendorTemplate = ({ id, name, licenseNumber }, index) => (
    <div className="license-row">
      <p className="regular">
        {index + 1}. {name} - {licenseNumber}
      </p>
      <Button className="remove-button" type="link" onClick={() => removeVendor(id)}>
        REMOVE
      </Button>
    </div>
  );

  const userVendorsTemplate = selectedVendorsList.map(selectedVendorTemplate);

  const failedToLoadBuyingLicensesTemplate = (
    <Alert
      className="setup-alert"
      type="warning"
      showIcon
      message="Failed to load your buying licenses"
    />
  );

  const selectedVendorsTemplate = (
    <RequestStatus
      status={getStoreFrontUserVendorsRequestStatus}
      inactiveTemplate={userVendorsTemplate}
      completedTemplate={userVendorsTemplate}
      startedTemplate={<ProgressBar simulateProgress={true} />}
      failedTemplate={failedToLoadBuyingLicensesTemplate}
    />
  );

  const vendorOptionTemplate = ({ title, id }) => (
    <Option key={id} value={String(id)}>
      {title}
    </Option>
  );

  const pickVendorListTemplate = (
    <div className="search-licenses">
      <label className="regular" htmlFor="licenses">
        Add a License:
      </label>
      {shouldRefineSearch && (
        <Alert
          className="setup-alert"
          type="info"
          showIcon
          message="Too many results, please refine your search"
        />
      )}
      <AutoComplete
        id="licenses"
        name="licenses"
        className="search-autocomplete"
        defaultValue={vendorFilter}
        onSelect={onVendorSelected}
        onChange={onFilterChanged}
        dataSource={unselectedVendors.map(vendorOptionTemplate)}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder="Start search by typing the first 2 letters"
        />
      </AutoComplete>
    </div>
  );

  const failedToAddVendorLicenseTemplate = (
    <>
      <Alert
        className="setup-alert"
        type="warning"
        showIcon
        message="Failed to add vendor"
      />
      {pickVendorListTemplate}
    </>
  );

  const pickVendorTemplate = (
    <RequestStatus
      status={addBuyingLicenseRequestStatus}
      inactiveTemplate={pickVendorListTemplate}
      completedTemplate={pickVendorListTemplate}
      startedTemplate={<ProgressBar simulateProgress={true} />}
      failedTemplate={failedToAddVendorLicenseTemplate}
    />
  );

  const hasAGoodRepTemplate = (
    <Alert
      className="setup-alert"
      type="info"
      showIcon
      message="Looks like you've got a good rep!"
      description="Someone out there pre-approved you for the licenses below. Please confirm and add
any others you wish to purchase under."
    />
  );

  const stateChangeWarningTemplate = (
    <Alert
      className="setup-alert"
      type="warning"
      showIcon
      message="You can only purchase in one state. Please remove your licenses below first or
    create a new account"
    />
  );
  const stateTemplate = (
    <div className="state-wrapper">
      <label className="regular" htmlFor="state">
        Your State
      </label>
      <Select
        id="state"
        name="state"
        className="state-selector"
        disabled={!canChangeState}
        options={states}
        value={countryState}
        onChange={onStateChanged}
        placeholder="Select A State..."
      >
        {states.map(state => (
          <Select.Option key={state.state} value={state.value}>
            {state.text}
          </Select.Option>
        ))}
      </Select>
      {/* "You can only purchase in one state. Please remove your licenses below first or create a new account" */}
    </div>
  );

  const licensesTemplate = (
    <Fragment>
      {showStateChangeWarning && stateChangeWarningTemplate}
      {stateTemplate}
      {hasAGoodRep && hasAGoodRepTemplate}
      {selectedVendorsTemplate}
      {pickVendorTemplate}
    </Fragment>
  );

  const failedToLoadJurisdictionsTemplate = (
    <Alert
      className="setup-alert"
      type="warning"
      showIcon
      message="Failed to load jurisdictions"
    />
  );

  const buyTemplate = (
    <RequestStatus
      status={getJurisdictionsRequestStatus}
      inactiveTemplate={licensesTemplate}
      completedTemplate={licensesTemplate}
      startedTemplate={<ProgressBar simulateProgress={true} />}
      failedTemplate={failedToLoadJurisdictionsTemplate}
    />
  );

  const fieldsTemplate = ({ values, handleChange, errors, touched }) => (
    <Fragment>
      <div>
        <label className="regular" htmlFor="firstName">
          First Name
        </label>
        <Field name="firstName" error={touched.firstName && errors.firstName}>
          {({ field }) => (
            <Input
              {...field}
              className={touched.firstName && errors.firstName}
              id="firstName"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>
      <div>
        <label className="regular" htmlFor="lastName">
          Last Name
        </label>
        <Field name="lastName">
          {({ field }) => (
            <Input
              {...field}
              className={touched.lastName && errors.lastName}
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>
      <div>
        <label className="regular" htmlFor="phone">
          Phone Number
        </label>
        <Field name="phone">
          {({ field }) => (
            <Input
              {...field}
              className={touched.phone && errors.phone}
              id="phone"
              name="phone"
              placeholder="Phone #"
              value={values.phone}
              onChange={handleChange}
            />
          )}
        </Field>
      </div>
    </Fragment>
  );

  const genericGreetingTemplate = (
    <div className="greeting">
      <h2>Almost There!</h2>
      <h3>Just need some information to build your profile</h3>
      <p className="greeting">
        We just need to know who you are and the licenses you want to purchase under to
        finish setting up your profile.
      </p>
    </div>
  );

  const personalizedGreetingTemplate = (
    <div className="greeting">
      <h3>Hey, {contactInfo.firstName}!</h3>
      <p className="greeting">{contactInfo.email}</p>
    </div>
  );

  const greetingTemplate = hasValidContactDetails
    ? personalizedGreetingTemplate
    : genericGreetingTemplate;

  const browseStoresImage = (
    <img loading="lazy" alt="GrowFlow Logo" src={config.images.logo.desktop} />
  );
  return (
    <div className={styles.setupWrapper}>
      {hasValidShoppingDetails && <Link to={browseStoresUri()}>{browseStoresImage}</Link>}
      {!hasValidShoppingDetails && browseStoresImage}
      <div className={styles.setupContainer}>
        {greetingTemplate}
        {form({
          renderFields: fieldsTemplate,
          validator: onValidate,
          onSubmit: onFormSubmit,
          initialValues: contactInfo,
          showActions: false,
          formInitHandler: onFormInitialized,
        })}
        {buyTemplate}
        <div className="buttonContainer">
          <Button onClick={cancel} disabled={!hasValidShoppingDetails}>
            CANCEL
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={!canAttemptSave}
            onClick={onSave}
          >
            SAVE PROFILE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountSetup;
