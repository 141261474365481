import './listing/';
import './cart/';
import './reducers';
import './components';
import {
  hocWithMappings as hoc,
  pageWithAuth,
  withBreakpointDetection,
  withSEO,
} from 'utils/views';
import Template from './template';
import { compose } from 'utils/core/funcy';
import controllerCategoryMenuDisclosureTracking from './template/controller.categoryMenuDisclosureTracking';
import controllerCategoryVirtualScrollTracking from './template/controller.categoryVirtualScrollTracking';
import controllerFilterModal from './template/controller.filterModal';
import controllerListing from './listing/controller';
import controllerSearch from './template/controller.search';
import controllerSort from './template/controller.sort';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Main as withLayout } from 'layouts';

const TemplateWithListingController = hoc('Store - Listing UX', controllerListing);
const TemplateWithCategoryMenuDisclosureTrackingUX = hoc(
  'Store - UX - Category Menu DisclosureTracking',
  controllerCategoryMenuDisclosureTracking
);
const TemplateWithCategoryVirtualScrollTrackingUX = hoc(
  'Store - UX - Category Virtual Scroll Tracking',
  controllerCategoryVirtualScrollTracking
);
const TemplateWithFilterModalUX = hoc('Store - UX - Filter Modal', controllerFilterModal);
const TemplateWithSearchUX = hoc('Store - Search UX', controllerSearch);
const TemplateWithSortUX = hoc('Store - Sort UX', controllerSort);

const FullTemplate = compose(
  withBreakpointDetection({ mobileMaxWidth: 767 }),
  TemplateWithCategoryMenuDisclosureTrackingUX,
  TemplateWithFilterModalUX,
  TemplateWithSearchUX,
  TemplateWithCategoryVirtualScrollTrackingUX,
  TemplateWithSortUX,
  TemplateWithListingController
)(Template);

export default withSEO({
  title: 'Store',
})(
  pageWithAuth({
    Template: FullTemplate,
    withLayout,
    createTasks,
    createViewModel,
  })
);

