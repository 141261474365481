import { page, withSEO } from 'utils/views';
import Busy from 'components/Busy';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Landing as withLayout } from 'layouts';

export default withSEO({
  title: 'GrowFlow Live',
})(
  page({
    Template: Busy,
    createTasks,
    withLayout,
    createViewModel,
  })
);
