import { identity } from 'utils/core/funcy';

const mapWithMappingErrorDefault = (mapper, failureFallbackValue) => (...args) => {
  try {
    return mapper(...args);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error mapping from local storage');
    return failureFallbackValue;
  }
};

export const createMapper = ({
  mapToStorage = identity,
  mapFromStorage = identity,
} = {}) => ({
  mapToStorage,

  mapFromStorage,

  withErrorDefault: fallback =>
    createMapper({
      mapToStorage: mapWithMappingErrorDefault(mapToStorage, fallback),
      mapFromStorage: mapWithMappingErrorDefault(mapFromStorage, fallback),
    }),
});

export const object = createMapper({
  mapToStorage: JSON.stringify,
  mapFromStorage: JSON.parse,
});

export const number = createMapper({ mapFromStorage: Number });

export const simple = createMapper();
