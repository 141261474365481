import { Modal } from 'antd';
import styled from 'styled-components';

export const ThumbnailWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    font-size: 26px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(61, 60, 63, 0.5);
    svg {
      color: #f2f2f2;
      fill: #f2f2f2;
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  && {
    .ant-modal-content {
      .ant-modal-close {
        top: -15px;
        right: -15px;
      }
    }
  }
`;
