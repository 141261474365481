import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

export const storeFrontCreatePurchaseOrderKey = `${base}-storeFrontCreatePurchaseOrder`;

export const eventMap = {
  initialized: () => ({}),
  changedDetails: details => ({ details }),
  changedCartProductQuantity: ({ storeFrontId, productId, quantity }) => ({
    storeFrontId,
    productId,
    quantity,
  }),
};

export const { eventNames, events } = createEvents(base, eventMap);
