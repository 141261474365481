import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { equal_to, equal_to_any_values, not } from '@developwithpassion/matchers_js';
import { identity, prop } from 'utils/core/funcy';
import { fileAbsolute } from 'paths.macro';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const statuses = {
  started: 1,
  completed: 2,
  failed: 3,
};

const viewData = viewModelAttribute(identity, prop('status'));
const inactive = viewModelAttribute(
  viewData,
  not(equal_to_any_values(...Object.values(statuses)))
);
const started = viewModelAttribute(viewData, equal_to(statuses.started));
const completed = viewModelAttribute(viewData, equal_to(statuses.completed));
const failed = viewModelAttribute(viewData, equal_to(statuses.failed));

const accessors = {
  inactive,
  started,
  completed,
  failed,
};

export const createViewModel = createViewModelMap(accessors);
