import config from 'config';
import { runGQLRequest } from 'utils/xhrs';

const query = `
 mutation ($details: StoreFrontSignupInput!) {
  storeFrontSignup(details: $details) 
}`;

export default async ({ email = '', password = '' } = {}) => {
  const r = await runGQLRequest('storeFrontSignup', config.legacyGQL.endpoint, {
    operationName: null,
    variables: {
      details: { email, password },
    },
    query,
  });

  const errors = r.data.errors || [];

  return {
    success: errors.length === 0 && r.data.data.storeFrontSignup,
    errors,
  };
};
