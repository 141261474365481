import { useEffect, useState } from 'react';
import { noOp } from 'utils/core/funcy';

export default ({
  minStart = 0,
  maxStart = 0,
  onChange: originalOnChange = noOp,
} = {}) => {
  const [values, setValues] = useState([minStart, maxStart]);

  const onChange = ([newStart, newEnd]) => {
    setValues([newStart, newEnd]);
  };

  const onAfterChange = ([newStart, newEnd]) => {
    originalOnChange([newStart, newEnd]);
  };

  useEffect(() => {
    setValues([minStart, maxStart]);
  }, [minStart, maxStart]);

  return {
    onChange,
    onAfterChange,
    minStart: values[0],
    maxStart: values[1],
  };
};
