import { Button, Tooltip } from 'antd';
import React, { Fragment } from 'react';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { cartSummaryContainer } from '../../template/styles';
import { noOp } from 'utils/core/funcy';

const CartSummaryTemplate = ({
  cartTotal = 0.0,
  isDesktop = false,
  viewCart = noOp,
  isEmpty = true,
  totalLineItems = 0,
  setShowCartByCategory = noOp,
  flags: { sfShopByCategory = false } = {},
} = {}) => {
  const mobileTemplate = isDesktop ? null : (
    <Tooltip title="View cart">
      <div className="mobile-total-items">
        <p className="semi-bold">{totalLineItems}</p>
        <Button size="large" onClick={viewCart} icon={<ShoppingCartOutlined />} />
      </div>
    </Tooltip>
  );

  const emptyTemplate = isEmpty ? (
    <p className="total-items semi-bold">Your cart is empty</p>
  ) : null;

  const nonEmptyTemplate = isEmpty ? null : (
    <Fragment>
      <p className="total-items semi-bold">{`${totalLineItems} item${
        totalLineItems > 1 ? 's' : ''
      }`}</p>
      <div className="total-container">
        <h5>TOTAL</h5>
        {sfShopByCategory && totalLineItems > 0 ? (
          <a onClick={() => setShowCartByCategory(true)}>
            <p className="semi-bold">${cartTotal.toFixed(2)}</p>
          </a>
        ) : (
          <p className="semi-bold">${cartTotal.toFixed(2)}</p>
        )}
      </div>
    </Fragment>
  );

  const desktopTemplate = isDesktop ? (
    <div className="count-total-container">
      {emptyTemplate}

      {nonEmptyTemplate}

      <Button type="primary" onClick={viewCart} icon={<ShoppingCartOutlined />} block>
        VIEW CART
      </Button>
    </div>
  ) : null;

  return (
    <div className={`${cartSummaryContainer} cart-summary-container`}>
      {mobileTemplate}

      {desktopTemplate}
    </div>
  );
};

export default CartSummaryTemplate;
