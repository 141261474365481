import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 mutation ($state: String!, $vendorId: Int!) {
  storeFrontAddBuyingLicense(state: $state, vendorId: $vendorId) {
    Id,
    UserId,
    BuyerVendorId,
    BuyerVendorName,
    BuyerVendorLicenseNumber
  }
}`;

export default async (state, vendorId) => {
  const r = await runGQLRequest(
    'storeFrontAddBuyingLicense',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        vendorId,
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.storeFrontAddBuyingLicense;
};
