/* eslint-disable */

import { css } from 'emotion';

export const landing = css`
  max-width: 450px;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
`;

export const details = css`
  padding: 24px 12px;
  @media (min-width: 768px) {
    padding: 24px 32px;
  }
`;

export const heading = css`
  text-align: center;

  p {
  }
`;

export const actions = css`
  margin-top: 2em;
`;

export const contactButton = css``;
