import Axios from 'axios';
import { authOptions } from 'utils/xhrs/authToken';
import config from 'config';

const query = `
 query {
  getStoreFrontUserProfile {
   firstName,
   lastName,
   phone,
   state,
   email
  }
}`;

export default async () => {
  const r = await Axios.post(
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {},
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.getStoreFrontUserProfile;
};
