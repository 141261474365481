import { add, byType, createFilteredReducer, createSliceReducer } from 'utils/reducers';
import { eventsForKey } from './events';
import { statuses } from './viewModel';

export const newState = () => ({
  status: 0,
});

export const createReducers = (key, sliceSelector) => {
  const { eventNames } = eventsForKey(key);
  const sliceReducer = createSliceReducer(sliceSelector);

  const started = createFilteredReducer(
    byType(eventNames.started),
    sliceReducer(data => {
      data.status = statuses.started;
    })
  );

  const completed = createFilteredReducer(
    byType(eventNames.completed),
    sliceReducer(data => {
      data.status = statuses.completed;
    })
  );

  const failed = createFilteredReducer(
    byType(eventNames.failed),
    sliceReducer(data => {
      data.status = statuses.failed;
    })
  );

  return {
    started,
    completed,
    failed,
  };
};

export const registerReducers = (key, selector) => {
  const { started, completed, failed } = createReducers(key, selector);

  add(started);
  add(completed);
  add(failed);
};
