import {
  addVendorLicenseKey,
  events,
  getJurisdictionsKey,
  getStoreFrontUserVendorsKey,
  getUserInfoKey,
  getVendorLicensesKey,
} from './events';
import {
  buyerVendorMap,
  buyerVendorsArrayToVendorMap,
  createViewModel,
  jurisdictionToStateMap,
} from './viewModel';
import { getUserInfo, updateUserProfile } from 'utils/auth0';
import addBuyingLicense from 'apiRequests/storeFrontAddBuyingLicense';
import { createTasks as createBrowseStoresTasks } from 'src/routes/browse-stores/tasks';
import createDelayedBehaviour from 'utils/core/delayedBehaviour';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { getCurrentState } from 'utils/redux';
import getJurisdictions from 'apiRequests/getJurisdictions';
import getStoreFrontUserVendors from 'apiRequests/getStoreFrontUserVendors';
import getVendors from 'apiRequests/getVendors';
import { logException } from 'utils/core/funcy';
import removeBuyingLicense from 'apiRequests/storeFrontRemoveBuyingLicense';

const _dependencies = {
  buyerVendorsArrayToVendorMap,
  createBrowseStoresTasks,
  createViewModel,
  getCurrentState,
  getJurisdictions,
  getStoreFrontUserVendors,
  getUserInfo,
  jurisdictionToStateMap,
  updateUserProfile,
};

export const createTasks = (dispatch, { dependencies = _dependencies } = {}) => {
  const {
    buyerVendorsArrayToVendorMap,
    createBrowseStoresTasks,
    createViewModel,
    getCurrentState,
    getJurisdictions,
    getStoreFrontUserVendors,
    getUserInfo,
    jurisdictionToStateMap,
    updateUserProfile,
  } = dependencies;

  const views = (state = getCurrentState()) => createViewModel(state);

  const browseStoresTasks = createBrowseStoresTasks(dispatch);

  const changeDetails = details => {
    dispatch(events.changedDetails(details));
  };

  const getVendorLicensesStatusTasks = () =>
    createRequestTasks(getVendorLicensesKey, dispatch);

  const getUserInfoStatusTasks = () => createRequestTasks(getUserInfoKey, dispatch);

  const addBuyingLicenseStatusTasks = () =>
    createRequestTasks(addVendorLicenseKey, dispatch);

  const getStoreFrontUserVendorsStatusTasks = () =>
    createRequestTasks(getStoreFrontUserVendorsKey, dispatch);

  const getJurisdictionsStatusTasks = () =>
    createRequestTasks(getJurisdictionsKey, dispatch);

  const loadBuyerLicenses = async () => {
    const { countryState: state } = views();

    await logException(async () => {
      const userVendors = await getStoreFrontUserVendorsStatusTasks().runRequest(
        getStoreFrontUserVendors(state)
      );

      changeDetails({
        selectedVendors: buyerVendorsArrayToVendorMap(userVendors),
      });
    });
  };

  const loadVendorLicenses = async () => {
    logException(async () => {
      const {
        hasValidVendorFilter,
        vendorsRequestDetails,
        ownState: { vendors: existingVendors, vendorsPageNumber },
      } = views();

      if (!hasValidVendorFilter) return;

      const vendors = await getVendorLicensesStatusTasks().runRequest(
        getVendors(vendorsRequestDetails)
      );

      changeDetails({
        vendors: vendorsPageNumber === 1 ? vendors : existingVendors.concat(vendors),
      });
    });
  };

  const vendorSearch = createDelayedBehaviour(loadVendorLicenses, 1000);

  const save = async formData => {
    const { countryState: state } = views();

    changeDetails({ contactInfo: formData });

    updateUserProfile({ ...formData, state });

    browseStoresTasks.run();
  };

  const unFocusVendorSearch = () => {
    changeDetails({ vendorSearchIsFocused: false });
  };

  const focusVendorSearch = () => {
    const { hasValidVendorFilter } = views();

    changeDetails({ vendorSearchIsFocused: hasValidVendorFilter });
  };

  const performVendorSearch = async pattern => {
    changeDetails({ vendorFilter: pattern, vendorsPageNumber: 1 });

    const { hasValidVendorFilter } = views();

    if (!hasValidVendorFilter) {
      changeDetails({ vendors: [] });
      unFocusVendorSearch();
      return;
    }

    await vendorSearch.restart();
    focusVendorSearch();
  };

  const changeState = async countryState => {
    changeDetails({
      state: countryState,
      vendors: [],
      vendorFilter: '',
      vendorSearchIsFocused: false,
      vendorsPageNumber: 1,
      selectedVendors: {},
    });

    await loadBuyerLicenses();
  };

  const selectVendor = async vendorId => {
    unFocusVendorSearch();

    await logException(async () => {
      const vendor = await addBuyingLicenseStatusTasks().runRequest(
        addBuyingLicense(views().countryState, vendorId)
      );

      dispatch(events.selectedVendor(buyerVendorMap(vendor)));
    });
  };

  const removeVendor = async vendorId => {
    const { countryState } = views();

    await removeBuyingLicense(countryState, vendorId);

    dispatch(events.removedVendor(vendorId));
  };

  const reloadJurisdictions = async () => {
    const { states } = views();

    if (states.length > 0) return;

    const jurisdictions = await getJurisdictionsStatusTasks().runRequest(
      getJurisdictions()
    );

    changeDetails({ states: jurisdictions.map(jurisdictionToStateMap) });
  };

  const loadAccountInfo = async () => {
    const accountInfo = await getUserInfoStatusTasks().runRequest(getUserInfo());

    const {
      firstName = '',
      lastName = '',
      phone = '',
      state = '',
      email = '',
    } = accountInfo;

    changeDetails({
      contactInfo: {
        firstName,
        lastName,
        phone,
        email,
      },
      state,
    });
  };

  const init = async () => {
    await logException(async () => {
      await reloadJurisdictions();

      await loadAccountInfo();

      const { hasValidState, countryState } = views();

      if (!hasValidState) return;

      await changeState(countryState);
    });
  };

  const resume = async () => {
    await init();

    const { hasValidShoppingDetails } = views();

    return hasValidShoppingDetails;
  };

  const changeSearchFilter = async filter => {
    changeDetails({ vendorFilter: filter });
  };

  const fetchMoreVendors = async () => {
    const {
      ownState: { vendorsPageNumber },
      canAttemptToFetchMoreVendors,
    } = views();
    if (!canAttemptToFetchMoreVendors) return;

    changeDetails({ vendorsPageNumber: vendorsPageNumber + 1 });
    await vendorSearch.restart();
  };

  return {
    changeSearchFilter,
    performVendorSearch,
    focusVendorSearch,
    unFocusVendorSearch,
    fetchMoreVendors,
    init,
    save,
    cancel: browseStoresTasks.run,
    changeState,
    selectVendor,
    removeVendor,
    resume,
  };
};
