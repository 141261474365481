import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getLocalUserInfo } from 'utils/auth0/client';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const analytics_userid_key =
  'https://growflow.com/app_metadata/global/analytics_userid';

export const userInfo = viewModelAttribute(
  'User - Local User Info',
  () => getLocalUserInfo(),
  identity
);

export const analytics_userid = viewModelAttribute(
  'User - analytics_userid',
  userInfo,
  ({ [analytics_userid_key]: id }) => id
);

const accessors = {
  analytics_userid,
  userInfo: analytics_userid,
};

export const createViewModel = createViewModelMap(accessors);
