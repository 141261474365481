import * as styles from './styles';
import { navigate, uris } from 'router';

import Link from 'components/Link';
import Profile from '../Profile';
import React from 'react';
import config from 'config';
import useMedia from 'utils/hooks/useMedia';

const { browseStoresUri } = uris;

const NavBar = ({ location }) => {
  const browsePath = new RegExp(browseStoresUri());
  const isBrowsePath = browsePath.test(location.pathname);
  const storePath = new RegExp('/store');
  const isStorePath = storePath.test(location.pathname);
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <header className={styles.navContainer}>
      <div className="inner-container">
        <Link to={browseStoresUri()} className="logo blue-link">
          <img
            loading="lazy"
            alt="GrowFlow Logo"
            src={isMobile ? config.images.logo.mobile : config.images.logo.desktop}
          />
        </Link>
        {!isMobile ? (
          <div className={`page-indicator ${isBrowsePath ? 'active-path' : ''}`}>
            <p onClick={() => navigate(browseStoresUri())} className="browse semi-bold">
              Browse Stores
            </p>
          </div>
        ) : null}
        <div
          className={`profile-container page-indicator ${
            isStorePath ? 'active-path' : ''
          }`}
        >
          <Profile />
        </div>
      </div>
    </header>
  );
};

export default NavBar;
