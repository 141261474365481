import { css } from 'emotion';

// cart page styles
export const cartPageContainer = css`
  .cart-header-container {
    .cart-alerts {
      align-self: flex-end;
      margin: 24px 0px;
    }
    .desktop-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      .nav-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .store-info {
        flex: 1 1 100%;
        margin-bottom: 24px;
      }
      .back-cart {
        font-size: 13px;
        color: var(--blue-700);
      }
    }
    textarea {
      min-height: 80px;
      margin-top: 24px;
    }
    .cart-errors-container {
      width: 100%;
      .ant-alert-description {
        line-height: 30px;
      }
    }
  }

  .place-order-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    .total-container {
      text-align: right;
    }
    button {
      margin-left: 35px;
    }
  }

  .back-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 24px;
    p {
      text-transform: uppercase;
      color: var(--blue-700);
      .icon {
        position: relative;
        top: 1px;
      }
    }
  }

  .bottom-place-order {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 1200px) {
    .back-cart {
      margin-top: 24px;
    }

    .place-order-container {
      justify-content: flex-start;
      margin-top: 0;
    }

    .bottom-place-order {
      margin-top: 24px;
      justify-content: flex-start;
      padding-left: 50px;
    }
  }
`;

// summary view styles
export const cartSummaryContainer = css`
  .count-total-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    p {
      &.total-items {
        font-size: 22px;
        color: var(--gray-900);
      }
    }
    .total-container {
      text-align: right;
      h5 {
        color: var(--gray-600);
      }
    }
    button {
      flex: 1 1 100%;
      margin-top: 24px;
    }
  }
  .mobile-total-items {
    text-align: center;
    position: relative;
    p {
      position: absolute;
      top: -10px;
      left: 0;
      right: -3px;
      margin: 0 auto;
    }
    button {
      background-color: transparent;
      background: transparent;
      border-color: transparent;
      color: var(--purp-200);
      fill: var(--purp-200);
      box-shadow: none;
      span {
        font-size: 24px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 20px 40px 40px;
    background: var(--gray-300);
    border: 1px solid var(--gray-400);
    border-radius: 3px;
  }
`;
