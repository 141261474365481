import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

export const init = (
  options = {
    autoClose: 1700,
  }
) => {
  toast.configure(options);
};

export const displayToast = toast;
