import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames, signupKey } from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';

import { allRuleResults } from './passwordComplianceRules';

const sliceReducer = createSliceReducer(x => x.signup);

export const newState = () => ({
  signupRequestStatus: requestState(),
  errorMessage: '',
  passwordModified: false,
  complianceRulesMet: allRuleResults(''),
});

export const signupStatusSelector = x => x.signup.signupStatus;

const initialState = {
  signup: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

export const changedDetails = sliceReducer((signup, { payload: { details } }) => {
  Object.assign(signup, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

registerRequestStatusChangeReducers(signupKey, val => val.signup.signupRequestStatus);
