import { css } from 'emotion';

export const browseWrapper = css`
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--purp-200);
    box-shadow: 0 0 0 2px rgba(107, 87, 248, 0.2);
  }
  .browse-header {
    .inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      h3 {
        flex: 0 0 auto;
      }
      .store-search {
        max-width: 505px;
        margin-left: 20px;
      }
    }
  }

  .store-list-container {
    margin-top: 10px;
    .ant-tabs-tab {
      display: inline-flex;
      align-items: center;
      &:hover {
        p {
          color: var(--purp-300);
        }
      }
      p {
        color: var(--gray-700);
      }
    }
    .ant-tabs-tab-active {
      p {
        color: var(--purp-200);
      }
    }
    .ant-tabs-nav {
      width: 100%;
      margin: 0 auto;
      padding: 0 16px;
    }
    .tab-container {
      background: var(--gray-000);

      &.ant-tabs-tabpane-active {
        min-height: 100vh;
      }
    }
  }

  .store-card-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    .css-grid-hack {
      grid-column: 1/-1;
    }
  }

  .anticon svg {
    color: var(--gray-400);
  }

  .browse-alert {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 0px;
    text-align: center;
    background-color: var(--gray-000);
    margin-top: 100px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    .ant-alert-message {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-900);
    }
    .ant-alert-description {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-900);
    }
  }

  @media (min-width: 768px) {
    .store-list-container {
      .ant-tabs-nav {
        padding: 0 20px;
      }
    }
    .store-card-grid-container {
      gap: 20px;
    }
  }

  @media (min-width: 1024px) {
    .store-list-container {
      .ant-tabs-nav {
        padding: 0 22px;
      }
    }
  }

  @media (min-width: 1200px) {
    .store-list-container {
    }
  }

  @media (min-width: 1440px) {
    .store-list-container {
      margin: 0 calc(((100vw - 1400px) / 2) * -1);
      .ant-tabs-nav {
        padding: 0 calc((100vw - 1360px) / 2);
      }
    }
  }
`;
