import React from 'react';

const RequestStatusTemplate = ({
  status: { inactive = false, completed = false, started = false, failed = false },
  startedTemplate = <></>,
  completedTemplate = <></>,
  failedTemplate = <span>Failure Occured...</span>,
  inactiveTemplate = <></>,
} = {}) => (
  <>
    {inactive && inactiveTemplate}
    {started && startedTemplate}
    {completed && completedTemplate}
    {failed && failedTemplate}
  </>
);

export default RequestStatusTemplate;
