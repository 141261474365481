import { createRef, useState } from 'react';
import { noOp } from 'utils/core/funcy';

export const isInvalidValue = ({ value, maxAmountInCart, cartQuantity }) =>
  isNaN(value) || value <= 0 || value > maxAmountInCart || value === cartQuantity;

export default (
  {
    id: productId,
    maxAmountInCart = 0,
    cartQuantity = 0,
    isCountBased = false,
    changeCartQuantity = noOp,
    textQuantityForProduct = noOp,
  } = {},
  quantityRefs = {}
) => {
  const [quantityIsValid, setQuantityIsValid] = useState(false);

  const getQuantityInput = productId => quantityRefs[productId].current;

  const onQuantityInputChange = ({ target: { value } }) => {
    setQuantityIsValid(
      !isInvalidValue({
        value: Number(value),
        maxAmountInCart,
        cartQuantity,
      })
    );
  };

  const resetInput = (input, quantity) => {
    const val = quantity === 0 ? null : quantity;

    input.input.value = val;
    input.setState({ value: val });
  };

  const updateProductQuantityInputs = (productId, newQuantity) => {
    resetInput(getQuantityInput(productId), newQuantity);
  };

  const attemptQuantityChange = ({ maxAmountInCart, cartQuantity }) => {
    const target = getQuantityInput(productId);

    let inputValue = target.input.value.trim();

    if (isCountBased) {
      inputValue = inputValue.replace(/[^\d](.+)$/, '');
    }

    const proposedValue = Number(inputValue);

    if (proposedValue === cartQuantity) {
      resetInput(target, proposedValue);
      return;
    }

    if (
      isInvalidValue({
        value: proposedValue,
        maxAmountInCart,
        cartQuantity,
      })
    ) {
      resetInput(target, cartQuantity);
      return;
    }

    changeCartQuantity({ productId, proposedValue });
    setQuantityIsValid(false);
  };

  const onQuantityInputKeyPress = e => {
    if (e.key === 'Enter') {
      attemptQuantityChange({ productId, maxAmountInCart, cartQuantity });
    }
  };

  const onRemoveFromCart = () => {
    resetInput(getQuantityInput(productId), 0);
    changeCartQuantity({ productId, proposedValue: 0 });
  };

  const onAttemptQuantityChange = () => {
    attemptQuantityChange({ productId, maxAmountInCart, cartQuantity });
  };

  const createQuantityTrackingRef = () =>
    quantityRefs[productId] || (quantityRefs[productId] = createRef());

  const quantityValue = textQuantityForProduct(productId);

  return {
    createQuantityTrackingRef,
    onQuantityInputChange,
    onQuantityInputKeyPress,
    onAttemptQuantityChange,
    updateProductQuantityInputs,
    onRemoveFromCart,
    quantityIsValid,
    quantityRefs,
    getQuantityInput,
    quantityValue,
  };
};
