import * as style from './styles';
import React from 'react';

export const NoSecurity = () => (
  <div>
    <img
      className={style.image}
      src="https://cdn.growflow.com/img/logos/growflow-live-logox300.png"
    />
    <h1 className={style.heading}>No Security</h1>
  </div>
);

export default NoSecurity;
