import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import {
  is_empty,
  is_false,
  is_null_or_undefined,
  not,
} from '@developwithpassion/matchers_js';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getLocalUserInfo } from 'utils/auth0';
import { identity } from 'utils/core/funcy';
import { accessors as sharedDataViewModelAccessors } from 'routes/sharedData/viewModel';
import { sort } from '@developwithpassion/comparers_js';
import { sort as sortArray } from '@developwithpassion/arrays_js';
import { to_object } from '@developwithpassion/core_utils_js';
import { criteria as where } from '@developwithpassion/match_js';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const nonEmptyString = not(is_null_or_undefined.or(is_empty));
const vendorsPageSize = 50;

export const jurisdictionToStateMap = ({ regionName, regionAbbreviation }) => ({
  name: regionName,
  state: regionAbbreviation,
  text: regionName,
  value: regionAbbreviation,
});

const accountSetup = viewModelAttribute(
  'Account Setup ViewModel - Main',
  ({ accountSetup }) => accountSetup,
  identity
);

const validContactDetails = where({
  firstName: nonEmptyString,
  lastName: nonEmptyString,
  phone: nonEmptyString,
  email: nonEmptyString,
});

const getVendorLicensesRequestStatus = viewModelAttribute(
  accountSetup,
  ({ getLicensesRequestStatus }) => createRequestStatusViews(getLicensesRequestStatus)
);

const addBuyingLicenseRequestStatus = viewModelAttribute(
  accountSetup,
  ({ addBuyingLicenseRequestStatus }) =>
    createRequestStatusViews(addBuyingLicenseRequestStatus)
);

const getStoreFrontUserVendorsRequestStatus = viewModelAttribute(
  accountSetup,
  ({ getStoreFrontUserVendorsRequestStatus }) =>
    createRequestStatusViews(getStoreFrontUserVendorsRequestStatus)
);

const getJurisdictionsRequestStatus = viewModelAttribute(
  accountSetup,
  ({ getJurisdictionsRequestStatus }) =>
    createRequestStatusViews(getJurisdictionsRequestStatus)
);

const getUserInfoRequestStatus = viewModelAttribute(
  accountSetup,
  ({ getUserInfoRequestStatus }) => createRequestStatusViews(getUserInfoRequestStatus)
);

const contactInfo = viewModelAttribute(accountSetup, ({ contactInfo }) => contactInfo);

export const countryState = viewModelAttribute(accountSetup, ({ state }) => state);

export const vendorFilter = viewModelAttribute(
  accountSetup,
  ({ vendorFilter }) => vendorFilter
);

const states = viewModelAttribute(accountSetup, ({ states }) =>
  sortArray(sort.by('name'), states)
);

const vendorsPageNumber = viewModelAttribute(
  accountSetup,
  ({ vendorsPageNumber }) => vendorsPageNumber
);

const profileData = viewModelAttribute(
  contactInfo,
  countryState,
  (contactInfo, countryState) => ({
    ...contactInfo,
    state: countryState,
  })
);

const vendorsRequestDetails = viewModelAttribute(
  countryState,
  vendorsPageNumber,
  vendorFilter,
  (countryState, vendorsPageNumber, vendorFilter) => ({
    state: countryState,
    filter: vendorFilter,
    pagination: {
      page: vendorsPageNumber,
      pageSize: vendorsPageSize,
    },
  })
);

const hasValidVendorFilter = viewModelAttribute(
  vendorFilter,
  vendorFilter => vendorFilter.trim().length > 0
);

export const vendorMap = ({ name, locationId, id }) => ({
  id,
  name,
  locationId,
  title: `${name} - ${locationId}`,
  key: id,
  value: id,
});

export const buyerVendorMap = ({
  buyerVendorName,
  buyerVendorId,
  buyerVendorLicenseNumber,
}) => ({
  id: buyerVendorId,
  name: buyerVendorName,
  licenseNumber: buyerVendorLicenseNumber,
  title: `${buyerVendorName} - ${buyerVendorLicenseNumber}`,
});

export const buyerVendorsArrayToVendorMap = vendors =>
  to_object(({ id }) => id, vendors.map(buyerVendorMap));

const hasValidContactDetails = viewModelAttribute(contactInfo, validContactDetails);

const hasValidState = viewModelAttribute(countryState, nonEmptyString);

const allApplicableVendors = viewModelAttribute(accountSetup, ({ vendors }) =>
  vendors.filter(({ name }) => !!name).map(vendorMap)
);

const canAttemptToFetchMoreVendors = viewModelAttribute(
  allApplicableVendors,
  val => val.length % vendorsPageSize === 0
);

const selectedVendors = viewModelAttribute(
  accountSetup,
  ({ selectedVendors }) => selectedVendors
);

const selectedVendorList = viewModelAttribute(selectedVendors, values =>
  Object.values(values).sort(sort.by('name'))
);

/* const hasAGoodRep = viewModelAttribute( */
/*   hasValidContactDetails, */
/*   selectedVendorList, */
/*   (hasValidContactDetails, selectedVendorList) => */
/*     !hasValidContactDetails && selectedVendorList.length > 0 */
/* ); */

const unselectedVendors = viewModelAttribute(
  allApplicableVendors,
  selectedVendors,
  (all, selected) => all.filter(({ id }) => is_false(!!selected[id]))
);

const hasStateAndVendorsSet = viewModelAttribute(
  hasValidState,
  selectedVendorList,
  (hasValidState, selectedVendorList) => hasValidState && selectedVendorList.length > 0
);

const isLoadingVendors = viewModelAttribute(
  getVendorLicensesRequestStatus,
  ({ started }) => started
);

export const hasValidShoppingDetails = viewModelAttribute(
  hasValidContactDetails,
  hasStateAndVendorsSet,
  (hasValidContactDetails, hasStateAndVendorsSet) =>
    hasValidContactDetails && hasStateAndVendorsSet
);

export const shouldRefineSearch = viewModelAttribute(
  unselectedVendors,
  val => val.length === vendorsPageSize
);

const accessors = {
  ownState: accountSetup,

  accountSetup,

  getJurisdictionsRequestStatus,

  getUserInfoRequestStatus,

  getVendorLicensesRequestStatus,

  addBuyingLicenseRequestStatus,

  getStoreFrontUserVendorsRequestStatus,

  isLoadingVendors,

  contactInfo,

  hasValidContactDetails,

  hasValidShoppingDetails,

  hasValidState,

  // TODO - Will need to store extra state at the auth0 level to account for being
  // able to correctly display this message. Technically, the only time this message
  // should display is if all the following conditions are met:
  // * they have never manually added any licenses to their account
  // * they have licenses that are already assigned to their account (this would have been done by a rep)
  /* return hasAGoodRep(state); */
  hasAGoodRep: () => false,

  unselectedVendors,

  userInfo: getLocalUserInfo,

  selectedVendors,

  selectedVendorsList: selectedVendorList,

  countryState,

  states,

  canChangeState: () => true,

  canAttemptToFetchMoreVendors,

  canAttemptSave: hasStateAndVendorsSet,

  vendorsRequestDetails,

  hasValidVendorFilter,

  shouldRefineSearch,

  profileData,

  ...sharedDataViewModelAccessors,
};

export const createViewModel = createViewModelMap(accessors);
