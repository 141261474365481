import { analytics_userid, userInfo } from 'state/user/viewModel';
import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { createViewModel as createAccountSetupViewModel } from 'routes/account-setup/viewModel';
import { data } from 'utils/localStorage';
import { fileAbsolute } from 'paths.macro';
import { getCurrentState } from 'utils/redux';
import { greater_than } from '@developwithpassion/matchers_js';
import { identity } from 'utils/core/funcy';
import { criteria as where } from '@developwithpassion/match_js';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const accountSetup = viewModelAttribute(
  ({ accountSetup }) => accountSetup,
  () => createAccountSetupViewModel(getCurrentState())
);

const profile = viewModelAttribute('Main ViewModel', ({ profile }) => profile, identity);

export const selectedVendorId = viewModelAttribute(profile, val => val.selectedVendorId);

const localStorageValidators = {
  validSelectedVendorId: where({
    selectedVendorId: greater_than(0),
  }),
};

export const selectedVendor = viewModelAttribute(
  accountSetup,
  selectedVendorId,
  ({ selectedVendors, selectedVendorsList }, selectedVendorId) =>
    selectedVendors[selectedVendorId] || selectedVendorsList[0]
);

export const hasSelectedVendorInLocalStorage = () =>
  localStorageValidators.validSelectedVendorId(data());

export const hasSelectedVendor = viewModelAttribute(selectedVendor, val => !!val);

export const profileAvailable = viewModelAttribute(
  accountSetup,
  val => val.hasValidShoppingDetails
);

export const signedInBuyerDetails = viewModelAttribute(
  'Profile - Signed In Buyer Details',
  selectedVendor,
  userInfo,
  analytics_userid,
  (selectedVendor, userInfo, analytics_userid) => ({
    analytics_userid,
    userInfo,
    selectedVendor,
  })
);

const accessors = {
  ownState: profile,
  profileAvailable,
  selectedVendor,
  licenses: state => accountSetup(state).selectedVendorsList,
  canLoadSelectedVendorFromLocalStorage: hasSelectedVendorInLocalStorage,
  hasSelectedVendor,
  signedInBuyerDetails,
};

const buildView = createViewModelMap(accessors);

export const createViewModel = (state, ownProps = {}) =>
  buildView({ ...ownProps, ...state });

export default createViewModel;
