import { noOp } from 'utils/core/funcy';
import { sortOrderList } from '../../shared/sortOrders';
import { useState } from 'react';

export default ({ onSortChange = noOp } = {}) => {
  const [activeSortOptionId, setActiveSortOptionId] = useState(sortOrderList[0].id);

  const createSortSelectedHandler = id => () => {
    setActiveSortOptionId(id);
    onSortChange(id);
  };

  const sortingOptions = sortOrderList.map(({ id, ...rest }) => ({
    id,
    ...rest,
    isActive: id === activeSortOptionId,
    onClick: createSortSelectedHandler(id),
  }));

  return {
    sortingOptions,
  };
};
