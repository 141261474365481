import { createViews, orderNote } from './core';
import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { selectedVendorId } from 'components/Profile/viewModel';
import { storeFrontId } from '../../../shared/viewModel';

export const mapProductId = Number;

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const orderLineItemMap = ({
  id: productId,
  price,
  discount,
  cartQuantity,
  noteForProduct,
}) => ({
  productId,
  price,
  discount,
  quantity: cartQuantity,
  note: noteForProduct,
});

export const createOrderView = orderLineItems =>
  viewModelAttribute(
    'order',
    selectedVendorId,
    storeFrontId,
    orderLineItems,
    orderNote,
    (vendorId, storeFrontId, lineItems, internalNote) => ({
      storeFrontId,
      vendorId,
      internalNote,
      lineItems,
    })
  );

export const { cartProducts, productsForOrder, invalidCartProducts } = createViews(
  mapProductId
);
