import { useEffect } from 'react';

export default ({ trackCartViewed, cartProducts = [] }) => {
  useEffect(() => {
    if (cartProducts.length === 0) return;
    trackCartViewed({
      cartId: '', // No data yet and may not ever have this?
      products: cartProducts,
    });
  }, []);
};
