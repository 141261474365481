import * as Sentry from '@sentry/browser';
import Axios from 'axios';

const dumpGQLErrors = result => {
  if (result && result.data && result.data.errors) {
    result.data.errors.forEach(error => {
      // eslint-disable-next-line no-console
      console.log(error);
      Sentry.captureException(error);
    });
  }
};

export const runGQLRequest = async (title, endpoint, data, options) => {
  Sentry.addBreadcrumb({
    message: `Running GQL Query: ${title}`,
    data: {
      variables: data.variables || {},
      auth: 'headers.Authorization'
        .split('.')
        .reduce((acc, val) => (acc ? acc[val] : undefined), options),
    },
  });

  const result = await Axios.post(endpoint, data, options);

  dumpGQLErrors(result);

  return result;
};
