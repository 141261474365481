import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 query ($state: String!, $filter: String, $pagination: Pagination!, $isTraining: Boolean) {

  getVendorsByFilter(state: $state, pagination: $pagination, filter: $filter, isTraining: $isTraining) {
    Id,
    LocationId,
    Name
  }
}`;

export default async ({
  state = '',
  filter = '',
  pagination: { page = 1, pageSize = 100 } = {},
} = {}) => {
  if (!state) return [];

  const r = await runGQLRequest(
    'getVendorsByFilter',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        filter,
        pagination: {
          page,
          pageSize,
        },
        isTraining: config.graphql.vendorQueries.isTraining,
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.getVendorsByFilter;
};
