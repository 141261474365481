import { createRef } from 'react';
import { delayedBehaviour } from 'utils/core';
import { noOp } from 'utils/core/funcy';

export default ({ clearFilters = noOp, changeSearchTerm = noOp } = {}) => {
  const searchRef = createRef(null);

  const searchBehaviour = delayedBehaviour(() => {
    changeSearchTerm(searchRef.current.input.value);
  }, 500);

  const onSearch = searchBehaviour.restart;

  const onClearFiltersAndSearch = () => {
    searchRef.current.setState({ value: '' });

    clearFilters();
  };

  return {
    onClearFiltersAndSearch,
    onSearch,
    searchRef,
  };
};
