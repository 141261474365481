import { css } from 'emotion';

export const loadingIndicatorContainer = css`
  position: relative;
  height: 375px;
  justify-content: flex-end;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 375px;

  p {
    width: 100%;
    text-align: center;
  }

  div:first-child[role='button'] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;
