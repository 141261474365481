import { ModalWrapper, ThumbnailWrapper } from './styles';
import { Image } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import React from 'react';
import { noOp } from 'utils/core/funcy';

export const YouTubeEmbed = ({
  className = '',
  fallbackImageUrl = '',
  height = 300,
  src = '',
  thumbnailSrc = '',
  width = 700,
  youtubeVideoVisible = false,

  stopVideo = noOp,
} = {}) => (
  <>
    <ThumbnailWrapper className={`thumbnail-wrapper ${className}`} onClick={stopVideo}>
      <Image
        src={thumbnailSrc || 'error'}
        alt="YouTube Thumbnail"
        loading="lazy"
        fallback={fallbackImageUrl}
      />
      <div className="overlay">
        <PlayCircleFilled />
      </div>
    </ThumbnailWrapper>
    <ModalWrapper
      centered
      visible={youtubeVideoVisible}
      footer={null}
      onCancel={stopVideo}
      style={{ textAlign: 'center' }}
    >
      <iframe
        title="YouTube"
        width={width}
        height={height}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        aria-hidden="true"
      />
    </ModalWrapper>
  </>
);

export default YouTubeEmbed;
