import { is_null_or_empty, is_undefined } from '@developwithpassion/matchers_js';

import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';

const invalidString = is_undefined.or(is_null_or_empty);

const eventHandlers = ({ signup, login, updatePasswordStatus }) => {
  let form = null;

  const onSave = async () => {
    await form.submitForm();
    if (!form.isValid) return;
  };

  const onLogin = createEventHandler(() => {
    login();
  });

  const onValidate = ({ email }) => {
    const errors = {};

    if (invalidString(email)) errors['email'] = 'Email is required';

    return errors;
  };

  const onFormInitialized = formProps => {
    form = formProps;
  };

  const onFormSubmit = formData => {
    signup(formData);
  };

  const createPasswordChangeHandler = handleChange => e => {
    const {
      target: { value },
    } = e;
    updatePasswordStatus(value);
    handleChange(e);
  };

  return {
    onLogin,
    onSave,
    onFormInitialized,
    onValidate,
    onFormSubmit,
    createPasswordChangeHandler,
  };
};

export default eventHandlers;
