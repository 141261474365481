import { css } from 'emotion';

export const storeCardContainer = css`
  .ant-card {
    height: 300px;
  }
  .ant-card-cover {
    height: 200px;
    img {
      height: 200px;
      object-fit: cover;
      margin-top: 1px;
    }
  }
`;

export const storeCard = css`
  display: flex;
  flex-direction: column;

  && {
    .ant-card-cover {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .ant-card-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .card-actions {
    margin-top: auto;
    padding-top: 16px;
    .loading-button {
      background: var(--purp-300);
      &:before {
        background: none;
        opacity: 1;
      }
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.error {
        h5 {
          color: var(--red-300);
          text-transform: uppercase;
        }
      }
      &.pending {
        h5 {
          color: var(--green-300);
          text-transform: uppercase;
        }
        button {
          background: var(--gray-600);
          border: var(--gray-600);
        }
      }
    }
  }
`;
