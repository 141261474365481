import { css } from 'emotion';

export const landingWrapper = css`
  margin-top: 130px;
  img {
    display: block;
    width: 140px;
    height: auto;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    margin-top: 170px;
  }
`;
