import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';
import { eventMap as implEventMap } from './impl';

export const getStoreProductsKey = `${base}-getStoreProductsStatus`;

export const eventMap = {
  initialized: () => ({}),

  triggeredChange: () => ({}),

  productsUpdated: () => ({}),

  changedDetails: details => ({ details }),

  ...implEventMap,

  initializedCartForStoreFront: storeFrontId => ({ storeFrontId }),
};

export const { eventNames, events } = createEvents(base, eventMap);
