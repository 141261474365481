import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { createViewModel as createAccountSetupViews } from '../../account-setup/viewModel';
import { createViewModel as createProfileViews } from 'components/Profile/viewModel';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const sortOptions = [
  { value: 0, text: 'Pending' },
  { value: 1, text: 'Available' },
];

const browseStores = viewModelAttribute(
  'Main ViewModel',
  ({ browseStores }) => browseStores,
  identity
);

export const storeFronts = viewModelAttribute(
  browseStores,
  ({ storeFronts }) => storeFronts
);

export const accessors = {
  profile: createProfileViews,
  accountSetup: createAccountSetupViews,
  storeFronts,
};

export const createViewModel = createViewModelMap(accessors);
