import * as styles from './styles';

import { Button, Input } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import React, { Fragment } from 'react';

import Loader from 'components/Loader';
import RequestStatus from 'components/RequestStatus';
import { form } from 'utils/forms';
import { noOp } from 'utils/core/funcy';

const Signup = ({
  signupRequestStatus,
  passwordModified,
  passwordComplianceRulesDetails,
  passwordRulesSatisfied,
  hasError = false,
  errorMessage = '',
  onLogin = noOp,
  onSave = noOp,
  onFormInitialized = noOp,
  onValidate = noOp,
  onFormSubmit = noOp,
  createPasswordChangeHandler = noOp,
} = {}) => {
  const passwordComplianceRuleTemplate = ({ isSatisfied, message }) => (
    <div className={`passwordRuleContainer ${isSatisfied ? 'satisfied' : ''}`}>
      <CheckCircleFilled />
      <p className="regular">{message}</p>
    </div>
  );

  const fieldsTemplate = ({ values, handleChange, errors }) => (
    <Fragment>
      <label htmlFor="email" className="regular">
        Your Email
      </label>
      <Input
        className={errors.email}
        id="email"
        name="email"
        placeholder="Email address"
        defaultValue={values.email}
        onChange={handleChange}
        type="email"
      />
      <label htmlFor="password" className="regular">
        Create Password
      </label>
      <Input.Password
        className={errors.password}
        id="password"
        name="password"
        placeholder="Create password"
        defaultValue={values.password}
        onChange={createPasswordChangeHandler(handleChange)}
        onBlur={createPasswordChangeHandler(handleChange)}
        iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
      />
      {passwordModified &&
        passwordComplianceRulesDetails.map(passwordComplianceRuleTemplate)}

      <p className="regular">
        By signing up for an account you are agreeing to our{' '}
        <a href="https://www.growflow.com/eula/">EULA</a> and{' '}
        <a href="https://www.growflow.com/privacy-policy/">Privacy Policy</a>
      </p>
      <Button disabled={!passwordRulesSatisfied} type="primary" block onClick={onSave}>
        CREATE ACCOUNT
      </Button>
    </Fragment>
  );

  const errorTemplate = hasError ? (
    <div className="account-fail">
      <ExclamationCircleOutlined className="failure" />
      <h4>Account Creation Failed</h4>
      <p className="regular">{errorMessage}</p>
    </div>
  ) : null;

  const signupTemplate = (
    <div className={styles.signupWrapper}>
      <h3>Sign up with email</h3>

      {errorTemplate}

      {form({
        renderFields: fieldsTemplate,
        validator: onValidate,
        onSubmit: onFormSubmit,
        initialValues: { email: '', password: '' },
        showActions: false,
        formInitHandler: onFormInitialized,
        enableReinitialize: false,
      })}
      <p className="regular">
        Already have an account?{' '}
        <a href="#" onClick={onLogin}>
          Sign In
        </a>
      </p>
    </div>
  );

  const processingTemplate = (
    <div className={styles.processingMessage}>
      <Loader message="Creating your account..." />
    </div>
  );

  const successTemplate = (
    <div className={styles.signupWrapper}>
      <h3>Success!</h3>
      <p className="regular success">
        Your account has been created. Please log in with it below:
      </p>
      <Button type="primary" block size="large" onClick={onLogin}>
        LOG IN
      </Button>
    </div>
  );

  const template = (
    <RequestStatus
      status={signupRequestStatus}
      inactiveTemplate={signupTemplate}
      completedTemplate={successTemplate}
      startedTemplate={processingTemplate}
      failedTemplate={signupTemplate}
    />
  );

  return template;
};

export default Signup;
