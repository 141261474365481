import { events, getStoreFrontsKey } from './events';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { createTasks as createStoreFrontTasks } from './storeFront/tasks';
import { createViewModel } from './viewModel';
import { getCurrentState } from 'utils/redux';
import getStoreFronts from 'apiRequests/getStoreFronts';
import { logException } from 'utils/core/funcy';
import { routes } from 'router';
import { to_object } from '@developwithpassion/core_utils_js';

const { browseStores } = routes;

const _dependencies = {
  createViewModel,
  getCurrentState,
  getStoreFronts,
  createStoreFrontTasks,
};

export const createTasks = (dispatch, { dependencies = _dependencies } = {}) => {
  const {
    createViewModel,
    getCurrentState,
    getStoreFronts,
    createStoreFrontTasks,
  } = dependencies;

  const views = (state = getCurrentState()) => createViewModel(state);
  const getStoreFrontsStatusTasks = createRequestTasks(getStoreFrontsKey, dispatch);
  const { createStoreFront } = createStoreFrontTasks(dispatch);

  const changeDetails = async details => {
    dispatch(events.changedDetails(details));
  };

  const reloadVendors = async () => {
    const {
      shared: {
        profile: { hasSelectedVendor, selectedVendor: { id: vendorId } = {} },
        accountSetup: { countryState },
      },
    } = views();

    if (!hasSelectedVendor) return;

    await logException(async () => {
      const storeFronts = await getStoreFrontsStatusTasks.runRequest(
        getStoreFronts({ state: countryState, vendorId })
      );

      dispatch(
        events.changedDetails({
          storeFronts: to_object(({ id }) => id, storeFronts.map(createStoreFront)),
          initialized: true,
        })
      );
    });
  };

  const viewLocked = async () => {
    changeDetails({ unlocked: false });
  };

  const viewUnLocked = async () => {
    changeDetails({ unlocked: true });
  };

  const init = async () => {
    const { initialized } = views();
    if (initialized) return;
    reloadVendors();
  };

  const changeFilter = async filter => {
    changeDetails({ filter });
  };

  const run = async () => {
    await changeFilter('');
    await reloadVendors();
    await browseStores();
  };

  return {
    init,
    run,
    reloadVendors,
    viewLocked,
    viewUnLocked,
    changeFilter,
  };
};
