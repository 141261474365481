import './src';

import { GlobalStyle, theme } from './src/styles';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import VersionNumber from 'components/VersionNumber';
import { initialize as initializeAnalytics } from './src/analytics';
import { pipe } from 'utils/core/funcy';
import { withRedux } from 'utils/redux';
import { withResume } from './src/routes/app';

initializeAnalytics();

const withVersionNumber = Template => props => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Template {...props} />
    <VersionNumber {...props} />
  </ThemeProvider>
);

const WrapRoot = ({ element }) => element;

export default pipe(withResume, withVersionNumber, withRedux)(WrapRoot);
