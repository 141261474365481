import { css } from 'emotion';

export const menuItem = css`
  color: var(--gray-900);
  &.border {
    border-top: 1px solid var(--gray-300);
  }
  &:hover,
  &:focus {
    background: var(--gray-200);
  }
  &:active {
    background: var(--gray-200);
  }
  &.selected {
    background: var(--purp-200);
    color: var(--gray-000);
    &:hover,
    &:focus {
      background: var(--purp-300);
    }
    &:active {
      background: var(--gray-200);
    }
  }
`;
