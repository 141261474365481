import {
  createImage,
  isSourced,
  isValidImage,
  missingCategory,
  missingParentCategory,
} from '../utils';
import { is_empty, not } from '@developwithpassion/matchers_js';
import { numberOrZero } from 'utils/ui/mappers';

export const mapInitialDetails = ({
  sourcedProductId,
  imageUrl,
  imageOriginalUrl,
  image2Url,
  image2OriginalUrl,
  image3Url,
  image3OriginalUrl,
  discount,
  size,
  available,
  minCBDMax,
  maxCBDMax,
  minTHCMax,
  maxTHCMax,
  minTotalOptional,
  maxTotalOptional,
  minTotalTerpenes,
  maxTotalTerpenes,
  price,
  bulkGramsAvailable,
  quantityType,
  name: initialName = '',
  productAttributes = '',
  productCategoryParentId,
  productCategoryParentName,
  productCategoryId,
  productCategoryName,
  productStatus,
  strainName = '',
  ...rest
}) => {
  const name = [initialName, productAttributes]
    .map(val => val.trim())
    .filter(not(is_empty))
    .join(' - ');

  const listingName = `${strainName || ''} ${name}`.trim();

  return {
    ...rest,
    quantityType,
    name,
    listingName,
    strainName,
    sourcedProductId: Number(sourcedProductId),
    bulkGramsAvailable: numberOrZero(bulkGramsAvailable),
    available: Number(available),
    minTHCMax: numberOrZero(minTHCMax),
    maxTHCMax: numberOrZero(maxTHCMax),
    minCBDMax: numberOrZero(minCBDMax),
    maxCBDMax: numberOrZero(maxCBDMax),
    minTotalTerpenes: numberOrZero(minTotalTerpenes),
    maxTotalTerpenes: numberOrZero(maxTotalTerpenes),
    minTotalOptional: numberOrZero(minTotalOptional),
    maxTotalOptional: numberOrZero(maxTotalOptional),
    discount: numberOrZero(discount),
    price: Number(price),
    productCategoryParentId: productCategoryParentId || missingParentCategory.id,
    productCategoryParentName: productCategoryParentName || missingParentCategory.name,
    productCategoryId: productCategoryId || missingCategory.id,
    productCategoryName: productCategoryName || missingCategory.name,
    productStatus,
    size: numberOrZero(size),
    imageUrl,
    isSourced: isSourced({ bulkGramsAvailable, sourcedProductId }),
    isCountBased: quantityType === 'CountBased',
    images: [
      createImage(imageOriginalUrl, imageUrl),
      createImage(image2OriginalUrl, image2Url),
      createImage(image3OriginalUrl, image3Url),
    ]
      .filter(isValidImage)
      .map((data, index) => ({
        ...data,
        id: index,
      })),
  };
};
