import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

const rangeChanged = ({ start, end }) => ({ start, end });

const rangeExpanded = isExpanded => ({ isExpanded });

export const eventMap = {
  initialized: () => ({}),
  changedDetails: details => ({ details }),
  toggledFilterGroupExpanded: groupId => ({ groupId }),
  updatedFilterGroupItems: (filterIds, enabled) => ({ filterIds, enabled }),
  changedTHCRange: rangeChanged,
  changedCBDRange: rangeChanged,
  changedPriceRange: rangeChanged,
  changedTHCRangeExpanded: rangeExpanded,
  changedCBDRangeExpanded: rangeExpanded,
  changedPriceRangeExpanded: rangeExpanded,
  clearedRange: groupId => ({ groupId }),
  changed: () => ({}),
};

export const { eventNames, events } = createEvents(base, eventMap);
