import { eventNames } from '../events';
import { reduceWith } from 'utils/reducers';
import { sliceReducer } from '../sliceReducer';

export const createExtraReducerState = () => ({});

export const changedCartProductQuantity = sliceReducer(
  (slice, { payload: { storeFrontId, productId, quantity } }) => {
    const storeFrontCart = slice.cart[storeFrontId];
    if (!storeFrontCart[productId])
      storeFrontCart[productId] = { quantity: 0, note: '', shouldBeRemoved: false };

    Object.assign(storeFrontCart[productId], {
      quantity,
      shouldBeRemoved: quantity === 0,
    });
  }
);

reduceWith(changedCartProductQuantity, eventNames.changedCartProductQuantity);

export const changedCartLineItemNote = sliceReducer(
  (slice, { payload: { storeFrontId, productId, note } }) => {
    const storeFrontCart = slice.cart[storeFrontId];
    Object.assign(storeFrontCart[productId], { note });
  }
);

reduceWith(changedCartLineItemNote, eventNames.changedCartLineItemNote);

export const removedLineItemsFlaggedForDeletion = sliceReducer(
  (slice, { payload: { storeFrontId } }) => {
    let cart = slice.cart[storeFrontId];
    cart = Object.entries(cart).forEach(([key, { shouldBeRemoved }]) => {
      if (shouldBeRemoved) delete cart[key];
    });
  }
);

reduceWith(
  removedLineItemsFlaggedForDeletion,
  eventNames.removedLineItemsFlaggedForDeletion
);
