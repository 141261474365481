import Template from './template';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { page } from 'utils/views';

export default page({
  Template,
  createTasks,
  createViewModel,
});
