import { List } from 'antd';
import styled from '@emotion/styled';

export const ListItem = styled(List.Item)`
  :nth-child(odd) {
    background: #f2f2f2;
  }
  :first-child {
    border-top: solid 1px #f0f0f0;
  }
  padding: 16px 24px;
`;

export const GrandTotal = styled(List.Item)`
  padding: 16px 24px 34px 24px;
  font-weight: bold;
  font-size: 16px;
  border-top: solid 1px #f0f0f0;
`;
