import React, { Fragment } from 'react';
import { Button } from 'antd';
import { InputWrapper as Input } from './styles';
import { PlusOutlined } from '@ant-design/icons';
import { noOp } from 'utils/core/funcy';
import { preventDefaultAndStopPropagation } from 'utils/ui/createEventHandler';
import { toCurrency } from 'utils/ui/mappers';

const AddToCart = ({
  total = 0,
  quantityValue = null,
  onQuantityInputChange = noOp,
  onQuantityInputKeyPress = noOp,
  onAttemptQuantityChange = noOp,
  createQuantityTrackingRef = noOp,
  onRemoveFromCart = noOp,
  quantityIsValid = false,
} = {}) => (
  <Fragment>
    <Input
      onClick={preventDefaultAndStopPropagation(noOp)}
      className={`product-row quantity ${
        quantityIsValid ? 'add-enabled' : 'add-disabled'
      }`}
      type="number"
      size="default"
      onChange={onQuantityInputChange}
      onKeyPress={onQuantityInputKeyPress}
      suffix={
        <PlusOutlined
          onClick={preventDefaultAndStopPropagation(onAttemptQuantityChange)}
        />
      }
      ref={createQuantityTrackingRef()}
      defaultValue={quantityValue}
    />
    <div className="remove product-row">
      {quantityValue > 0 ? (
        <Fragment>
          <Button
            type="link"
            onClick={preventDefaultAndStopPropagation(onRemoveFromCart)}
          >
            <h5>Remove</h5>
          </Button>
          <p className="regular">{total ? toCurrency(total) : '$0.00'}</p>
        </Fragment>
      ) : null}
    </div>
  </Fragment>
);

export default AddToCart;
