import { identity, pipe } from 'utils/core/funcy';
import React from 'react';
import { connect } from 'react-redux';
import curry from '@developwithpassion/curry_js';
import { withInit } from './withInit';
import { withLogin } from './withLogin';
import { withRenderErrorHandling } from './withRenderErrorHandling';

const NULLO_COMPUTED_VIEWS = identity;
const NULLO_TASKS = () => ({});
const PASSTHROUGH_VIEW = Template => props => <Template {...props} />;

const iif = curry((condition, trueFunc, falseFunc) => (condition ? trueFunc : falseFunc));

const defineComponent = ({
  createTasks = NULLO_TASKS,
  createViewModel = NULLO_COMPUTED_VIEWS,
} = {}) => pipe(withInit, connect(createViewModel, createTasks), withRenderErrorHandling);

export const component = ({
  Template,
  createTasks = NULLO_TASKS,
  createViewModel = NULLO_COMPUTED_VIEWS,
} = {}) => defineComponent({ createTasks, createViewModel })(Template);

const definePage = ({
  Template,
  createTasks = NULLO_TASKS,
  createViewModel = NULLO_COMPUTED_VIEWS,
  withLayout = PASSTHROUGH_VIEW,
  requiresLogin = false,
} = {}) =>
  pipe(
    withLayout,
    defineComponent({ createTasks, createViewModel }),
    iif(requiresLogin, withLogin, PASSTHROUGH_VIEW)
  )(Template);

export const page = ({
  Template,
  createTasks = NULLO_TASKS,
  createViewModel = NULLO_COMPUTED_VIEWS,
  withLayout = PASSTHROUGH_VIEW,
} = {}) =>
  definePage({
    Template,
    createTasks,
    createViewModel,
    withLayout,
  });

export const pageWithAuth = ({
  Template,
  createTasks = NULLO_TASKS,
  createViewModel = NULLO_COMPUTED_VIEWS,
  withLayout = PASSTHROUGH_VIEW,
}) =>
  definePage({
    Template,
    createTasks,
    createViewModel,
    withLayout,
    requiresLogin: true,
  });
