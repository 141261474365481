import { addInitialReducer, reduceWith } from 'utils/reducers';
import { eventNames } from './events';
import initialState from './initialState';
import produce from 'immer';

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

export const loggedIn = produce(({ user }) => {
  user.loggedIn = true;
});

reduceWith(loggedIn, eventNames.loaded);
