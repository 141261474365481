import { condition, is_object } from '@developwithpassion/matchers_js';
import {
  nested_property_setter,
  prevent_modifications,
} from '@developwithpassion/core_utils_js';
import createObjectIterator from '@developwithpassion/create_object_iterator_js';
import curry from '@developwithpassion/curry_js';
import map_path_init from '@developwithpassion/map_path_init_js';
import { reduce } from '@developwithpassion/arrays_js';

const createKeyGenerator = curry((prefix, key) => `${prefix}/${key}`);

const createEventNames = (actionKeyNames, idGenerator) => {
  let result = {};

  const iterator = createObjectIterator({
    target: actionKeyNames,
    nested_constraint: is_object.and(condition(data => Object.keys(data).length > 0)),
  });

  iterator(({ key }) => {
    map_path_init(key, result);
    nested_property_setter(key, idGenerator(key), result);
  });

  prevent_modifications(result);

  return result;
};

const createEventNamesForPrefix = curry((prefix, actionKeyNames) =>
  createEventNames(actionKeyNames, createKeyGenerator(prefix))
);

const createDispatchDetails = (prefix, baseEvents) => {
  let eventNames = createEventNamesForPrefix(prefix, baseEvents);
  let events = reduce(
    {},
    (acc, key) => ({
      ...acc,
      [key]: (...args) => ({
        type: eventNames[key],
        payload: baseEvents[key](...args),
      }),
    }),
    Object.keys(baseEvents)
  );

  return { eventNames, events };
};

export default curry((prefix, baseEvents) =>
  createDispatchDetails(`${prefix}events`, baseEvents)
);
