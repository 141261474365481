import { capitalize, unCapitalize } from 'utils/strings';
import { is_array, is_object, not, regex } from '@developwithpassion/matchers_js';
import { nonEmptyString } from 'utils/matchers';

const isUpperCaseOnlyString = nonEmptyString.and(not(regex(/[a-z]/)));

const mapNestedObject = (mapper, target) =>
  is_array(target) ? target.map(mapper) : mapper(target);

/* The data coming back from the legacy api is typically encoded in capitalized camelCase.
 * For the purpose of consuming this data, we map results retrieved
   from the api via a mapping approach that will recursively normalize all of the keys in
   the result map. This will allow us to access results using standard JavaScript naming conventions:

   ex: {
     SomeAttribute: 1,
     THC: 2,
     Nested: {
       Attrib1: 1,
       ID: 2
     }
   }

   would become:

   {
     someAttribute: 1,
     thc: 2,
     nested: {
       attrib1: 1,
       id: 2
     }
   }
 }
*/
export const mapFromResult = target =>
  Object.entries(target || {}).reduce((acc, [key, value]) => {
    const resultValue = is_object(value) ? mapNestedObject(mapFromResult, value) : value;

    const resultKey = isUpperCaseOnlyString(key) ? key.toLowerCase() : unCapitalize(key);

    acc[resultKey] = resultValue;

    return acc;
  }, {});

/* The data going back to the legacy wholesale api is currently encoded using PascalCase naming conventions.
+ * For the purpose of sending data , we map input sent
+   to the api via a mapping approach that will recursively normalize all of the keys in
+   the result map. This will allow us to access results using standard JavaScript naming conventions:
+
+   ex: {
+     someAttribute: 1,
+     thc: 2,
+     nested: {
+       attrib1: 1,
+       id: 2
+     },
+     items: [
+     ]
+   }
+
+   would become:
+
+     SomeAttribute: 1,
+     Thc: 2,
+     Nested: {
+       Attrib1: 1,
+       Id: 2
+     },
+     Items: [
+     ]
+   }
+*/
export const mapToLegacyInput = target =>
  Object.entries(target || {}).reduce((acc, [key, value]) => {
    const resultValue = is_object(value)
      ? mapNestedObject(mapToLegacyInput, value)
      : value;

    let resultKey = capitalize(key);

    acc[resultKey] = resultValue;

    return acc;
  }, {});
