import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const storeFrontId = viewModelAttribute(
  'storeFrontId',
  ({ store: { storeFrontId } }) => storeFrontId,
  identity
);

export const currentStoreDisplayArgs = viewModelAttribute(
  'currentStoreDisplayArgs',
  storeFrontId,
  storeFrontId => ({ storeFrontId })
);

export const accessors = () => ({});
