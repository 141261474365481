import * as phoneFns from 'phone-fns';

import {
  createViewModel as createSharedViewModel,
  storeFronts,
} from '../shared/viewModel';

import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import { criteria as where } from '@developwithpassion/match_js';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const storeWithStatus = status =>
  where({
    accessStatus: status,
  });

const nulloStoreFront = {
  id: 0,
  data: {},
};

export const createViewModel = (state, { id }) => {
  const storeFront = viewModelAttribute(
    storeFronts,
    ({ [id]: val }) => val || nulloStoreFront
  );
  const storeFrontData = viewModelAttribute(storeFront, ({ data }) => ({ ...data, id }));

  const storeLocked = storeWithStatus('Locked');
  const storePendingAccess = storeWithStatus('WaitingForVerification');
  const storeUnlocked = storeWithStatus('Verified');

  const accessTitle = ({ canView, awaitingVerification }) => {
    if (canView) return { title: 'viewModelAttribute Store' };
    if (awaitingVerification) return { title: 'Request Pending' };

    return { title: 'Request Access' };
  };

  const requestAccessStatus = viewModelAttribute(
    storeFront,
    ({ storeFrontRequestAccessStatus }) =>
      createRequestStatusViews(storeFrontRequestAccessStatus)
  );

  const formattedPhoneNumber = rawPhone => {
    if (!rawPhone) return '';

    const { length } = rawPhone.toString();

    let formatString = '';
    if (length === 7) formatString = 'NNN-NNNN';
    if (length === 10) formatString = '(NNN) NNN-NNNN';
    if (length === 11) formatString = 'N (NNN) NNN-NNNN';

    if (formatString === '') return '';

    return phoneFns.format(formatString, rawPhone);
  };

  const formattedWebsiteUrl = rawWeb => {
    if (!/^https?:\/\//i.test(rawWeb)) return `http://${rawWeb}`;
    return rawWeb;
  };

  const storeFrontDetail = viewModelAttribute(storeFrontData, storeFront => {
    const awaitingVerification = storePendingAccess(storeFront);
    const canView = storeUnlocked(storeFront);
    const canRequest = storeLocked(storeFront);
    const accessMessaging = accessTitle({ canView, awaitingVerification });
    const formattedPhone = formattedPhoneNumber(storeFront.phone);
    const formattedWebsite = formattedWebsiteUrl(storeFront.website);

    return {
      ...storeFront,
      website: formattedWebsite,
      phone: formattedPhone,
      canRequest,
      awaitingVerification,
      canView,
      accessTitle: accessMessaging.title,
      accessTitleColor: accessMessaging.color,
    };
  });

  const isLocked = viewModelAttribute(
    storeFrontDetail,
    ({ canRequest, awaitingVerification }) => canRequest || awaitingVerification
  );

  const isUnlocked = viewModelAttribute(storeFrontDetail, ({ canView }) => canView);

  const accessors = {
    id: () => id,
    shared: createSharedViewModel,
    requestAccessStatus,
    isLocked,
    isUnlocked,
    storeFront: storeFrontDetail,
  };

  const builder = createViewModelMap(accessors);

  return builder(state);
};
