import Image from 'components/Image';
import ListingDetails from '../../ListingDetails';
import React from 'react';
import config from 'config';
import { listingCard } from '../styles';
import { noOp } from 'utils/core/funcy';
import { toCurrency } from 'utils/ui/mappers';

const ListingCard = (props = {}) => {
  const {
    listing: {
      id: listingCardId,
      imageUrl = '',
      products = [],
      sizes = [],
      priceRange: { start: lowestPrice = 0, end: highestPrice = 0 } = {},
      thcRange: {
        available: thcAvailable = false,
        description: thcDescription = '',
      } = {},
      cbdRange: {
        available: cbdAvailable = false,
        description: cbdDescription = '',
      } = {},
      totalOptionalRange: {
        available: totalOptionalAvailable = false,
        description: totalOptionalDescription = '',
      } = {},
      terpenesRange: {
        available: terpenesAvailable = false,
        description: terpenesDescription = '',
      } = {},
    } = {},
    storeFront: {
      showProductStatusFilter = false,
      columnOptionsDTO: {
        cbd: showCBD = false,
        thc: showTHC = false,
        totalOptional: showTotalOptional = false,
        totalTerpenes: showTotalTerpenes = false,
      } = {},
    } = {},
    flags: { wsLiveProductStatus = false } = {},
    isMobile = false,
    productWithFullDetails = noOp,
    createListingCardActions = {},
  } = props;
  const firstProduct = products.length > 0 ? productWithFullDetails(products[0].id) : {};

  const {
    strainName = '',
    name = '',
    strainTypeName = '',
    productBrandName = '',
    productStatus = '',
  } = firstProduct;

  const createListingCardActionsMobileOnly = isMobile
    ? { ...createListingCardActions(listingCardId) }
    : {};

  return (
    <div
      className={listingCard}
      key={listingCardId}
      {...createListingCardActionsMobileOnly}
    >
      <div className="image-name-container">
        <Image
          src={imageUrl || 'error'}
          alt={`image of ${strainName} ${name}`}
          fallback={config.images.fallback}
          preview={false}
          {...createListingCardActions(listingCardId)}
        />
        <p className="semi-bold">{strainTypeName}</p>
      </div>
      <div className="strain-name-container">
        <h4 className="semi-bold pointer" {...createListingCardActions(listingCardId)}>
          {strainName} {name}
        </h4>
        <p className="regular pointer" {...createListingCardActions(listingCardId)}>
          {productBrandName}
        </p>
        {showProductStatusFilter &&
        wsLiveProductStatus &&
        productStatus === 'Backorder' ? (
          <p className="product-status-backorder">(Backorder)</p>
        ) : null}
      </div>
      {isMobile ? (
        <div className="size-cost-container">
          <h5>SIZES</h5>
          <h5>
            {toCurrency(lowestPrice)}-{toCurrency(highestPrice)}
          </h5>
          <p className="regular">{sizes.join(',')}</p>
        </div>
      ) : null}
      {!isMobile ? (
        <div className="terpenes-container">
          <h5>
            {showTotalOptional && totalOptionalAvailable
              ? `TOTAL: ${totalOptionalDescription}`
              : null}
            {showTotalOptional && totalOptionalAvailable && showTHC && thcAvailable
              ? ' | '
              : null}
            {showTHC && thcAvailable ? `THC: ${thcDescription}` : null}
            {showTHC && thcAvailable && showCBD && cbdAvailable ? ' | ' : null}
            {showCBD && cbdAvailable ? `CBD: ${cbdDescription}` : null}
            {showCBD && cbdAvailable && showTotalTerpenes && terpenesAvailable
              ? ' | '
              : null}
            {showTotalTerpenes && terpenesAvailable
              ? `TERPENES: ${terpenesDescription}`
              : null}
          </h5>
        </div>
      ) : null}
      <ListingDetails isMobile={isMobile} sizes={sizes} {...props} />
    </div>
  );
};

export default ListingCard;
