import { css } from 'emotion';

export const detailContainer = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'store-info'
    'back-cart-container'
    'product-info'
    'product-description';

  .back-cart-container {
    grid-area: back-cart-container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 94px;
    .back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      color: var(--blue-700);
    }
    p {
      text-transform: uppercase;
      color: var(--blue-700);
      .icon {
        position: relative;
        top: 1px;
      }
    }
  }

  .product-status-backorder-lg {
    font-size: 15px;
    color: red;
  }

  .product-description {
    grid-area: product-description;
    p {
      margin-top: 24px;
      color: var(--gray-900);
    }
  }

  .product-info {
    grid-area: product-info;

    .strain-type {
      text-transform: uppercase;
      color: var(--gray-700);
    }
    .strain-name {
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      color: var(--gray-900);
      margin-top: 4px;
    }
    .brand-name {
      color: var(--gray-700);
      margin-top: 7px;
    }
    .add-to-cart-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: start;
      align-items: center;
      margin-top: 40px;
      h5 {
        &.header {
          text-transform: uppercase;
          color: var(--gray-600);
        }
      }
      p {
        &.product-row {
          color: var(--gray-900);
        }
      }
      .grid-header-spacer {
        height: 4px;
        grid-column: span 1 / -1;
      }
      .grid-row-spacer {
        height: 12px;
        grid-column: span 1 / -1;
      }
      .product-row {
        input {
          text-align: center;
        }
        &.remove {
          position: relative;
          justify-self: end;
          text-align: right;
          button {
            position: absolute;
            top: -20px;
            right: 0;
            padding-right: 0;
            text-transform: uppercase;
            font-size: 12px;
            h5 {
              color: var(--purp-200);
            }
            &:hover {
              h5 {
                color: var(--purp-100);
              }
            }
          }
        }
        &.inventory-oversold-row {
          grid-column: span 2;
          padding: 5px 0;
          color: var(--red-300);
        }
      }
    }
  }

  .gallary-container {
    .main-image {
      width: 100%;
    }
    .thumbnail-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 16px;
      > * + * {
        margin-left: 16px;
      }
      .thumbnail-image {
        width: 58px;
        height: 58px;
        object-fit: scale-down;
        &.selected {
          border: 2px solid var(--purp-200);
        }
      }
    }
  }

  .test-results {
    margin-top: 50px;
    h5 {
      color: var(--gray-600);
      padding-bottom: 5px;
      border-bottom: 1px solid var(--gray-400);
    }
    .test-results-flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 24px;
      }
      p {
        text-transform: uppercase;
        color: var(--gray-600);
        &:last-of-type {
          color: var(--gray-700);
        }
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'store-info store-info'
      'back-cart-container back-cart-container'
      'product-description product-info';
    grid-row-gap: 24px;
    grid-column-gap: 60px;

    .product-description {
    }
    .product-info {
      .add-to-cart-container {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    margin-top: 24px;

    .back-cart-container {
      margin-top: 0px;
    }

    grid-template-columns: 350px 1fr 300px;
    grid-template-areas:
      'back-cart-container back-cart-container store-info'
      'product-description product-info store-info';
  }
`;
