import createImplTasks from './impl';
import { createTasks as createStoreTasks } from '../../../tasks';
import { noOp } from 'utils/core/funcy';

export const createTasks = dispatch => {
  const storeTasks = createStoreTasks(dispatch);
  const { createListingCardActions } = createImplTasks({
    dispatch,
    ...storeTasks,
  });

  const init = noOp;

  return {
    ...storeTasks,
    createListingCardActions,
    init,
  };
};
