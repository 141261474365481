import { max, min } from '@developwithpassion/arrays_js';
import { sort } from '@developwithpassion/comparers_js';

const createListingComparerForSortField = ({ sortId, defaultValue }) =>
  sort.using_accessor(({ sortFields: { [sortId]: val = defaultValue } } = {}) => val);

const name = {
  id: 'alpha',
  title: 'Alphabetically',
  listingComparer: createListingComparerForSortField({
    sortId: 'alpha',
    defaultValue: '',
  }),
  productsComparer: sort.by('listingName'),
  attributeResolver: ({ listingName }) => listingName,
  reducer: (resolver, items) =>
    items
      .map(resolver)
      .reduce((acc, val) => (acc === null ? val : val < acc ? val : acc), null),
};

const sortPriceLowToHigh = {
  id: 'price-low-to-high',
  title: 'Price - Low To High',
  listingComparer: createListingComparerForSortField({
    sortId: 'price-low-to-high',
    defaultValue: 0,
  }),
  productsComparer: sort.by('price'),
  attributeResolver: ({ price }) => price,
  reducer: min,
};

const sortPriceHighToLow = {
  ...sortPriceLowToHigh,
  id: 'price-high-to-low',
  title: 'Price - High To Low',
  listingComparer: createListingComparerForSortField({
    sortId: 'price-high-to-low',
    defaultValue: 0,
  }).reverse,
  productsComparer: sortPriceLowToHigh.productsComparer.reverse,
  reducer: max,
};

const thcResolver = ({ minTHCMax, maxTHCMax }) => Math.max(minTHCMax, maxTHCMax);

const thc = {
  id: 'thc',
  title: 'Highest THC',
  listingComparer: createListingComparerForSortField({ sortId: 'thc', defaultValue: 0 })
    .reverse,
  productsComparer: sort.using_accessor(thcResolver).reverse,
  attributeResolver: thcResolver,
  reducer: max,
};

const cbdResolver = ({ minCBDMax, maxCBDMax }) => Math.max(minCBDMax, maxCBDMax);

const cbd = {
  id: 'cbd',
  title: 'Highest CBD',
  listingComparer: createListingComparerForSortField({ sortId: 'cbd', defaultValue: 0 })
    .reverse,
  productsComparer: sort.using_accessor(cbdResolver).reverse,
  attributeResolver: cbdResolver,
  reducer: max,
};

export const sortOrderList = [name, thc, cbd, sortPriceHighToLow, sortPriceLowToHigh];
