import { createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames, requestAccessActionTriggers } from './events';
import { newState as requestState } from 'state/slice/request/reducers';
import { statuses } from 'state/slice/request/viewModel';

const sliceReducer = createSliceReducer(x => x.browseStores.storeFronts);

export const newState = ({ id, data }) => ({
  id,
  data,
  storeFrontRequestAccessStatus: requestState(),
});

const changedAccessStatus = sliceReducer((slice, { payload: { id, newStatus } }) => {
  slice[id].data.accessStatus = newStatus;
});

reduceWith(changedAccessStatus, eventNames.changedAccessStatus);

const changedDetails = sliceReducer((slice, { payload: { id, details } }) => {
  Object.assign({}, slice[id], details);
});

reduceWith(changedDetails, eventNames.changedDetails);

const accessRequestStarted = sliceReducer(
  (
    slice,
    {
      payload: {
        context: { storeFrontId },
      },
    }
  ) => {
    let data = slice[storeFrontId];
    data.storeFrontRequestAccessStatus.status = statuses.started;
  }
);

reduceWith(accessRequestStarted, requestAccessActionTriggers.started);

const accessRequestCompleted = sliceReducer(
  (
    slice,
    {
      payload: {
        context: { storeFrontId },
      },
    }
  ) => {
    slice[storeFrontId].storeFrontRequestAccessStatus.status = statuses.completed;
  }
);

reduceWith(accessRequestCompleted, requestAccessActionTriggers.completed);

const accessRequestFailed = sliceReducer(
  (
    slice,
    {
      payload: {
        context: { storeFrontId },
      },
    }
  ) => {
    slice[storeFrontId].storeFrontRequestAccessStatus.status = statuses.failed;
  }
);

reduceWith(accessRequestFailed, requestAccessActionTriggers.failed);
