import { createTasks as createLoginTasks } from '../login/tasks';
import { createViewModel } from './viewModel';
import { getCurrentState } from 'utils/redux';

export const createTasks = dispatch => {
  const init = async () => {};
  const views = (state = getCurrentState()) => createViewModel(state);
  const { login } = createLoginTasks(dispatch);

  const signIn = async () => {
    const { redirectPath } = views();
    await login(redirectPath);
  };

  return {
    init,
    signIn,
  };
};
