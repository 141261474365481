import * as styles from './styles';
import { Button } from 'antd';
import React from 'react';
import config from 'config';

const ComingSoon = () => (
  <div className={styles.landing}>
    <div className={styles.details}>
      <div className={styles.heading}>
        <h1>Coming Soon!</h1>
        <h4>
          We're working hard to try to get the new ordering platform up and running
          shortly! If you'd like to be notified when we're back up, drop us a note in the
          link below.
        </h4>
      </div>

      <div className={styles.actions}>
        <a href={config.contactUris.main}>
          <Button type="primary" className={styles.contactButton}>
            Contact Us
          </Button>
        </a>
      </div>
    </div>
  </div>
);

export default ComingSoon;
