// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-setup-js": () => import("./../../../src/pages/account-setup.js" /* webpackChunkName: "component---src-pages-account-setup-js" */),
  "component---src-pages-auth-complete-js": () => import("./../../../src/pages/auth/complete.js" /* webpackChunkName: "component---src-pages-auth-complete-js" */),
  "component---src-pages-browse-stores-js": () => import("./../../../src/pages/browse-stores.js" /* webpackChunkName: "component---src-pages-browse-stores-js" */),
  "component---src-pages-busy-js": () => import("./../../../src/pages/busy.js" /* webpackChunkName: "component---src-pages-busy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

