import * as styles from './styles';

import Link from 'components/Link';
import React from 'react';
import config from 'config';
import { uris } from 'router';

const { homeUri } = uris;

export default ({ children = null } = {}) => (
  <div className={styles.landingWrapper}>
    <Link to={homeUri()}>
      <img
        loading="lazy"
        alt="GrowFlow Logo"
        src={config.images.logo.desktop}
        width={140}
        height={30}
      />
    </Link>
    {children}
  </div>
);
