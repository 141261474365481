const storeUri = ({ storeFrontId }) => `/store/${storeFrontId}`;

const cartUri = ({ storeFrontId }) => `${storeUri({ storeFrontId })}/cart`;

const listingUri = ({ storeFrontId, listingId }) =>
  `${storeUri({ storeFrontId })}/listing/${listingId}`;

const orderCompleteUri = ({ storeFrontId, orderNumber }) =>
  `${storeUri({ storeFrontId })}/order-complete/${orderNumber}`;

const listing = route => async ({ storeFrontId, listingId }) =>
  await route({ path: listingUri({ storeFrontId, listingId }) });

const orderComplete = route => async ({ storeFrontId, orderNumber }) =>
  await route({ path: orderCompleteUri({ storeFrontId, orderNumber }) });

const store = route => async ({ storeFrontId }) =>
  await route({ path: storeUri({ storeFrontId }) });

const cart = route => async ({ storeFrontId }) =>
  await route({ path: cartUri({ storeFrontId }) });

export const buildRoutes = route => ({
  listing: listing(route),
  orderComplete: orderComplete(route),
  store: store(route),
  cart: cart(route),
});

export const uris = {
  storeUri,
  cartUri,
  listingUri,
  orderCompleteUri,
};
