import { createRef, useEffect, useState } from 'react';
import { noOp } from 'utils/core/funcy';

const ignoredCharacters = /\\/gi;

const _dependencies = {
  createRef,
  useEffect,
  useState,
};

export default (
  { changeFilter, viewUnLocked = noOp, viewLocked = noOp } = {},
  dependencies = _dependencies
) => {
  const { createRef, useEffect, useState } = dependencies;
  const searchInputRef = createRef();
  const [currentTab, setCurrentTab] = useState('unlocked');
  const handleTabChange = activeKey => setCurrentTab(activeKey);

  const onFilterChanged = ({ target: { value: filter } }) => {
    changeFilter(filter.replace(ignoredCharacters, ''));
  };

  const viewStoresFronts = currentTab === 'unlocked' ? viewUnLocked : viewLocked;

  useEffect(() => {
    changeFilter('');
  }, []);

  useEffect(() => {
    viewStoresFronts();
  }, [currentTab]);

  return {
    onFilterChanged,
    searchInputRef,
    handleTabChange,
    currentTab,
    setCurrentTab,
  };
};
