import { events } from '../../reducers/events';
import { noOp } from 'utils/core/funcy';
import { routes } from 'router';

const { store } = routes;

export const createStoreListingArgs = ({ views }) => {
  const {
    storeFrontId,
    profile: {
      selectedVendor: { id: selectedVendorId },
    },
    accountSetup: { countryState },
  } = views();

  return {
    state: countryState,
    storeFrontId,
    vendorId: selectedVendorId,
  };
};

export const createViewArgs = ({ props }) => {
  const { id } = props;

  const storeFrontId = Number(id);

  return { storeFrontId };
};

export const display = store;

export const createView = ({
  dependencies,
  updateProducts,
  clearProductListings,
  changeDetails,
  reloadProducts,
  storeSelected,
  initializeCartForStoreFront,
  initializePageForStoreFront,
  views,
}) => async ({ storeFrontId }, render = true) => {
  const { isValidStoreId } = views();
  const { notFound } = dependencies;

  if (!isValidStoreId(storeFrontId)) {
    await notFound();
    return;
  }

  updateProducts(clearProductListings);

  changeDetails({
    storeFrontId,
    initialized: true,
  });

  const { storeFront } = views();
  storeSelected(storeFront);

  initializeCartForStoreFront(storeFrontId);
  initializePageForStoreFront();

  if (render) await display({ storeFrontId });

  await reloadProducts();
};

export const initializeStoreDetails = noOp;

export const createTasks = ({ dispatch, views, saveCartToLocalStorage }) => {
  const setProductListings = noOp;

  const changeCartProductQuantity = ({ productId, quantity }) => {
    const { storeFrontId } = views();
    dispatch(events.changedCartProductQuantity({ storeFrontId, productId, quantity }));
    saveCartToLocalStorage();
  };

  const changeCartProductNote = ({ productId, note }) => {
    const { storeFrontId } = views();
    dispatch(events.changedCartLineItemNote({ storeFrontId, productId, note }));
    saveCartToLocalStorage();
  };

  const init = noOp;

  return {
    init,
    changeCartProductNote,
    changeCartProductQuantity,
    setProductListings,
  };
};
