import { createTasks as createStoreTasks } from '../tasks';
import { createViewModel } from './viewModel';
import { getCurrentState } from 'utils/redux';

export const createTasks = (dispatch, props = {}) => {
  const createViews = (state = getCurrentState()) => createViewModel(state);
  const {
    resume: storeResume,
    display: backToStore,
    showDetail: view,
    changeCartProductNote,
    changeCartProductQuantity,
    reloadProducts,
  } = createStoreTasks(dispatch, props);

  const back = async () => {
    const { storeFrontId } = createViews();

    await reloadProducts();
    backToStore({ storeFrontId });
  };

  const init = async () => {
    await storeResume();

    const { listingId } = props;

    await view(listingId);
  };

  return {
    init,
    view,
    back,
    changeCartProductQuantity,
    changeCartProductNote,
  };
};
