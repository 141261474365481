import React from 'react';
import curry from '@developwithpassion/curry_js';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const hocWithMappings = curry((description, mapper, ComponentToWrap) => {
  const HOC = props => {
    const mappedProps = mapper(props);

    return <ComponentToWrap {...{ ...props, ...mappedProps }} />;
  };

  HOC.displayName = `HOC - ${description} - ${getDisplayName(ComponentToWrap)}`;

  return HOC;
});
