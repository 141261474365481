import { css } from 'emotion';

export const productCard = css`
  background: var(--gray-000);
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  padding: 20px;
  margin-top: 18px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    'image-name-container strain-name-container'
    'image-name-container terpenes-container'
    'add-to-cart-container add-to-cart-container'
    'add-note-container add-note-container';

  .image-name-container {
    grid-area: image-name-container;
    text-align: center;
    img {
      width: 124px;
      height: 124px;
      object-fit: scale-down;
    }
    p {
      text-transform: uppercase;
      color: var(--gray-900);
    }
  }

  .strain-name-container {
    grid-area: strain-name-container;
    color: var(--gray-900);
  }

  .terpenes-container {
    grid-area: terpenes-container;
    h5 {
      color: var(--gray-600);
    }
  }

  .product-status-backorder {
    font-size: 12px;
    color: red;
  }

  .add-note-container {
    grid-area: add-note-container;
  }

  .add-to-cart-container {
    align-self: start;
    grid-area: add-to-cart-container;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: start;
    align-items: center;
    h5 {
      &.header {
        text-transform: uppercase;
        color: var(--gray-600);
      }
    }
    p {
      &.product-row {
        color: var(--gray-900);
      }
    }
    .grid-header-spacer {
      height: 4px;
      grid-column: span 1 / -1;
    }
    .product-row {
      &.quantity {
        max-width: 100px;
      }
      input {
        text-align: center;
      }
      &.remove {
        position: relative;
        justify-self: end;
        text-align: right;
        button {
          position: absolute;
          top: -25px;
          right: 0;
          padding-right: 0;
          text-transform: uppercase;
          font-size: 12px;
          h5 {
            color: var(--purp-200);
          }
          &:hover {
            h5 {
              color: var(--purp-100);
            }
          }
        }
      }
      &.inventory-oversold-row {
        grid-column: span 2;
        padding: 5px 0;
        color: var(--red-300);
      }
    }
  }

  @media (min-width: 768px) {
    column-gap: 24px;
    row-gap: 10px;
    grid-template-columns: auto 1fr 1.5fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'image-name-container strain-name-container add-to-cart-container'
      'image-name-container terpenes-container add-to-cart-container'
      'image-name-container add-note-container add-to-cart-container';

    .column-spacer {
      grid-area: column-spacer;
    }
    .add-to-cart-container {
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: auto 1fr 12px 1.5fr;
    grid-template-areas:
      'image-name-container strain-name-container column-spacer add-to-cart-container'
      'image-name-container terpenes-container column-spacer add-to-cart-container'
      'image-name-container add-note-container column-spacer add-to-cart-container';
  }
`;
