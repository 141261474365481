import { routes } from 'router';
import { useEffect } from 'react';

const { landing } = routes;

export const Unauthorized = () => {
  useEffect(landing, []);

  return null;
};

export default Unauthorized;
