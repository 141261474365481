import {
  ClearButton,
  FilterLabel,
  FilterWrapper,
  Menu,
  SearchContainer,
  SliderContainer,
  SubMenuLabel,
  Tree,
} from './styles';
import FilterSearch from '../FilterSearch';
import Range from '../Range';
import React from 'react';
import { noOp } from 'utils/core/funcy';

const { SubMenu } = Menu;

const RangePlaceHolder = { start: 0, end: 0 };

const sliderTemplate = ({
  key = '',
  productsRange = RangePlaceHolder,
  valueRange = RangePlaceHolder,
  markerMapper = noOp,
  title = '',
  changeHandler = noOp,
  clearable = false,
  clearHandler = noOp,
  onMenuClick = noOp,
  getMenuIcon = noOp,
} = {}) => (
  <SubMenu
    onClick={onMenuClick}
    onTitleClick={onMenuClick}
    title={
      <SubMenuLabel>
        {getMenuIcon(key)}
        {title}
        {clearable ? (
          <div onClick={e => e.stopPropagation()}>
            <ClearButton onClick={clearHandler}>Clear</ClearButton>
          </div>
        ) : null}
      </SubMenuLabel>
    }
    key={key}
  >
    <SliderContainer>
      <Range
        min={productsRange.start}
        max={productsRange.end}
        minStart={valueRange.start}
        maxStart={valueRange.end}
        step={0.1}
        markerDisplayMapper={markerMapper}
        onChange={changeHandler}
        tooltipVisible={false}
      />
    </SliderContainer>
  </SubMenu>
);

const createFilterGroupTemplate = ({
  onTreeChecked,
  createClearFilterSelectionsHandler,
  onMenuClick = noOp,
  activeFilterGroupItems = [],
  defaultExpandAll = false,
  getMenuIcon = noOp,
}) => filterGroupOrItem => {
  const addClickableLabels = child => ({
    ...child,
    title: <FilterLabel onClick={child.onToggle}>{child.title}</FilterLabel>,
    children: child.children.map(addClickableLabels),
  });

  const treeData = filterGroupOrItem.children.map(addClickableLabels);

  return (
    <SubMenu
      onClick={onMenuClick}
      onTitleClick={onMenuClick}
      title={
        <SubMenuLabel>
          {getMenuIcon(filterGroupOrItem.key)}
          {filterGroupOrItem.title}
          {filterGroupOrItem.clearable ? (
            <div onClick={e => e.stopPropagation()}>
              <ClearButton
                onClick={createClearFilterSelectionsHandler(filterGroupOrItem.key)}
              >
                Clear
              </ClearButton>
            </div>
          ) : null}
        </SubMenuLabel>
      }
      key={filterGroupOrItem.key}
    >
      <Tree
        checkable
        switcherIcon={<></>}
        onCheck={onTreeChecked}
        checkedKeys={activeFilterGroupItems}
        defaultExpandAll={defaultExpandAll}
        treeData={treeData}
      />
    </SubMenu>
  );
};

const Filter = ({
  filterGroups = [],
  sliderFilters = [],
  openFilterGroupKeys = [],
  onTreeChecked = noOp,
  createClearFilterSelectionsHandler = noOp,
  onMenuClick = noOp,
  activeFilterGroupItems = [],
  defaultExpandAll = true,
  getMenuIcon = noOp,
  searchRef = noOp,
  onSearch = noOp,
} = {}) => (
  <FilterWrapper>
    <SearchContainer>
      <FilterSearch searchRef={searchRef} onSearch={onSearch} />
    </SearchContainer>
    <Menu mode="inline" openKeys={openFilterGroupKeys} onClick={onMenuClick}>
      {filterGroups.map(
        createFilterGroupTemplate({
          onTreeChecked,
          onMenuClick,
          createClearFilterSelectionsHandler,
          activeFilterGroupItems,
          defaultExpandAll,
          getMenuIcon,
        })
      )}
      {sliderFilters.map(sliderTemplate)}
    </Menu>
  </FilterWrapper>
);

export default Filter;
