import './reducers';
import { page, withSEO } from 'utils/views';
import ComingSoon from '../coming-soon';
import React from 'react';
import Template from './template';
import config from 'config';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';

const AppTemplate = withSEO({
  title: 'GrowFlow Live',
})(
  page({
    Template,
    createTasks,
    createViewModel,
  })
);

export const withResume = Template => props =>
  config.comingSoon ? (
    <ComingSoon />
  ) : (
    <AppTemplate {...props}>
      <Template {...props} />
    </AppTemplate>
  );
