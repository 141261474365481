import {
  cartForStoreFront,
  createViewModel as createSharedStoreViewModel,
  productWithFullDetails,
  products,
} from '../../../shared/viewModel';
import { identity, prop } from 'utils/core/funcy';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { createViewModel as createStoreViewModel } from '../../../viewModel';
import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { getCurrentState } from 'utils/redux';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const shared = viewModelAttribute(
  'Store - Creating Cart Shared Store Views',
  ({ store }) => store,
  () => createSharedStoreViewModel(getCurrentState())
);

export const store = viewModelAttribute(
  'cart-store',
  ({ store }) => store,
  () => createStoreViewModel(getCurrentState())
);

export const cartOrder = viewModelAttribute('cartOrder', prop('cartOrder'), identity);

export const orderNote = viewModelAttribute(
  'orderNote',
  cartOrder,
  ({ orderNote }) => orderNote
);

export const createViews = mapProductId => {
  const cartProducts = viewModelAttribute('cartProducts', cartForStoreFront, cart =>
    Object.entries(cart)
      .filter(([, { quantity, shouldBeRemoved }]) => quantity > 0 || shouldBeRemoved)
      .map(([key, item]) => ({ ...item, id: mapProductId(key) }))
  );

  const productsForOrder = viewModelAttribute(
    'productsForOrder',
    cartProducts,
    products,
    (cartProducts, products) =>
      cartProducts
        .filter(({ quantity }) => quantity > 0)
        .map(({ id, quantity }) => ({ product: products.get(id), quantity }))
        .filter(({ product }) => !!product)
        .map(({ product, quantity }) => ({ ...product, quantity }))
  );

  const invalidCartProducts = viewModelAttribute(
    'invalidCartProducts',
    productsForOrder,
    productWithFullDetails,
    (items, details) => items.filter(({ id }) => details(id).isRemainingInventoryOversold)
  );

  return {
    cartProducts,
    productsForOrder,
    invalidCartProducts,
  };
};

export const storeFrontCreatePurchaseOrderRequestStatus = viewModelAttribute(
  cartOrder,
  ({ storeFrontCreatePurchaseOrderRequestStatus }) =>
    createRequestStatusViews(storeFrontCreatePurchaseOrderRequestStatus)
);
