export const productMapper = product => ({
  productId: product.id.toString(),
  sku: '', // No data in product view
  category: product.productCategoryName,
  name: product.name,
  brand: product.productBrandName || '',
  variant: '', // No data in product view
  price: product.price,
  quantity: product.cartQuantity || 0,
  url: '', // Not available on product view
  imageUrl: product.imageUrl,
  coupon: '', // Not available on product?
  position: 0, // Not available on product view
});
