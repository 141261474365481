import { anything, regex } from '@developwithpassion/matchers_js';
import { identity } from 'utils/core/funcy';

const errorMappings = [
  {
    criteria: regex(/Object didn't pass validation for format email/),
    mapping: () => 'Email address is invalid',
  },
  { criteria: anything, mapping: identity },
];

export const errorMapper = originalAuthError => {
  const { mapping } = errorMappings.find(({ criteria }) => criteria(originalAuthError));

  return mapping(originalAuthError);
};
