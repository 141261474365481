import React from 'react';
import SEO from 'components/SEO';

export const withSEO = ({
  description = '',
  lang = 'en',
  meta = [],
  title = '',
}) => Template => props => (
  <>
    <SEO description={description} lang={lang} meta={meta} title={title} />
    <Template {...props} />
  </>
);
