import { route, routes, uris } from 'router';
import { createTasks as createAccountSetupTasks } from '../../../account-setup/tasks';
import { createTasks as createAuthTasks } from '../../../auth/tasks';
import { createTasks as createProfileTasks } from 'components/Profile/tasks';
import { equal_to } from '@developwithpassion/matchers_js';

const { accountSetup, landing } = routes;
const { browseStoresUri, homeUri, landingUri, signupUri } = uris;

const _criteriaToRedirectToBrowseStoresWhenAlreadyLoggedIn = () =>
  [homeUri(), landingUri(), signupUri()].map(equal_to);

const _isPathToIgnoreWhenAlreadyLoggedIn = (
  path,
  criteriaForPathsToRedirectToBrowseWhenLoggedIn = _criteriaToRedirectToBrowseStoresWhenAlreadyLoggedIn
) => !!criteriaForPathsToRedirectToBrowseWhenLoggedIn().find(criteria => criteria(path));

const _getSuccessfulStartupRoutePath = (
  initialPath,
  isPathToIgnoreWhenAlreadyLoggedIn = _isPathToIgnoreWhenAlreadyLoggedIn
) => (isPathToIgnoreWhenAlreadyLoggedIn(initialPath) ? browseStoresUri() : initialPath);

export const createTasks = ({ dispatch, ready }) => {
  const accountSetupTasks = createAccountSetupTasks(dispatch);
  const profileTasks = createProfileTasks(dispatch);
  const authTasks = createAuthTasks(dispatch);

  const start = async () => {
    const accountIsSetup = await accountSetupTasks.resume();
    await profileTasks.resume();

    if (!accountIsSetup) await accountSetup();

    return accountIsSetup;
  };

  const init = async () => {
    await route({ path: homeUri() });

    await authTasks.init();
    const { success, initialPath } = await authTasks.run();

    const action = success ? start : landing;
    const startupSuccess = await action();
    const routePath = _getSuccessfulStartupRoutePath(initialPath);

    if (startupSuccess) await route({ path: routePath });

    ready();
  };

  return {
    init,
  };
};

export const __test__ = {
  _isPathToIgnoreWhenAlreadyLoggedIn,
  _criteriaToRedirectToBrowseStoresWhenAlreadyLoggedIn,
  _getSuccessfulStartupRoutePath,
};
