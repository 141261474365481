import './reducers';
import './storeFront';
import { hocWithMappings as hoc, pageWithAuth, withSEO } from 'utils/views';
import Template from './template';
import controllerEventHandlers from './controller.eventHandlers';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Main as withLayout } from 'layouts';

const TemplateWithEventHandlers = hoc(
  'Browse Store Template - Event Handlers',
  controllerEventHandlers
);

export default withSEO({
  title: 'Browse Stores',
})(
  pageWithAuth({
    Template: TemplateWithEventHandlers(Template),
    withLayout,
    createTasks,
    createViewModel,
  })
);
