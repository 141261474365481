import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 query ($state: String!, $vendorId: Int!, $isTraining: Boolean = false) {
  getStoreFronts(state: $state, vendorId: $vendorId, isTraining: $isTraining) {
    Id
    LicenseNumber
    VendorId
    AccountId
    Name
    AccessStatus
    IsPrivateVisibility
    IsPublicVisibility
    PreOrders
    Website
    Email
    Phone
    City
    Region
    FacebookUrl
    TwitterUrl
    InstagramUrl
    Description
    IsFeatured
    Order
    ContactInfo
    LogoUrl
    ColumnOptionsDTO {
      TotalOptional
      TotalTerpenes
      CBD
      THC
      Brand
    }
    MinimumOrderValueMode
    MinimumOrderValue
    ShowProductStatusFilter
  }
}`;

export const realRequest = async ({ state, vendorId }) => {
  const r = await runGQLRequest(
    'getStoreFronts',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        vendorId,
        isTraining: config.graphql.getStoreFronts.isTraining,
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.getStoreFronts;
};
