import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { initialPath } from '../app/viewModel';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const AUTH_PATH_PATTERN = /\/auth\/complete/;

const redirectPath = viewModelAttribute(initialPath, initialPath =>
  AUTH_PATH_PATTERN.test(initialPath) ? '/' : initialPath
);

const accessors = {
  redirectPath,
};

export const createViewModel = createViewModelMap(accessors);
