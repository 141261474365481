import { useEffect, useRef, useState } from 'react';

export default (props = {}) => {
  const menuContainerRef = useRef(null);
  const { getStoreProductsStatus } = props;

  const [shouldShowLeftMenuScrollButton, setShouldShowLeftMenuScrollButton] = useState(
    false
  );
  const [shouldShowRightMenuScrollButton, setShouldShowRightMenuScrollButton] = useState(
    false
  );

  const scrollMenuContainer = ({ direction = 'right' }) => {
    const menuContainer = menuContainerRef.current;
    const { width } = menuContainer.getBoundingClientRect();
    const left =
      direction === 'right'
        ? menuContainer.scrollLeft + width / 2
        : menuContainer.scrollLeft - width / 2;
    menuContainer.scrollTo({
      left,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(({ intersectionRatio, target }) => {
          const setScroll =
            target.className === 'scroll-indicator left'
              ? setShouldShowLeftMenuScrollButton
              : setShouldShowRightMenuScrollButton;

          if (intersectionRatio === 1) setScroll(false);
          if (intersectionRatio !== 1) setScroll(true);
        });
      },
      {
        root: menuContainerRef && menuContainerRef.current,
        threshold: 1,
      }
    );

    if (menuContainerRef.current)
      menuContainerRef.current.childNodes.forEach(node => observer.observe(node));

    return () => {
      if (menuContainerRef.current)
        menuContainerRef.current.childNodes.forEach(node => observer.disconnect(node));
    };
  }, [getStoreProductsStatus.completed]);

  return {
    scrollMenuContainer,
    shouldShowLeftMenuScrollButton,
    shouldShowRightMenuScrollButton,
    menuContainerRef,
  };
};
