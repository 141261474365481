import './reducers';
import { hocWithMappings as hoc, pageWithAuth, withSEO } from 'utils/views';
import Template from './template';
import controller from './controller';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';

const TemplateWithController = hoc(
  'Account Setup Template Event Handlers',
  controller
)(Template);

export default withSEO({
  title: 'Account Settings',
})(
  pageWithAuth({
    Template: TemplateWithController,
    createTasks,
    createViewModel,
  })
);
