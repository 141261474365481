import * as analyticsImpl from 'analytics';

const dependencies = {
  analytics: analyticsImpl,
};

export default ({ storeFront }) => {
  const { analytics } = dependencies;

  const trackCartViewed = cart => {
    if (!cart.products || cart.products.length === 0) return;

    analytics.cartViewed(cart, storeFront);
  };
  return {
    trackCartViewed,
  };
};

export const __test__ = {
  dependencies,
};
