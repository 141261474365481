import curry from '@developwithpassion/curry_js';
import { eventsForKey } from './events';
import timeout from 'utils/core/timeout';

export const createTasks = curry((key, dispatch) => {
  const { events } = eventsForKey(key);

  const start = context => {
    dispatch(events.started(context));
  };

  const stop = context => {
    dispatch(events.completed(context));
  };

  const failed = context => {
    dispatch(events.failed(context));
  };

  const runRequest = async (
    request,
    { useTimeout = false, timeoutIn = Number.MAX_SAFE_INTEGER, context = {} } = {}
  ) => {
    start(context);

    const action = useTimeout ? timeout(request, timeoutIn) : request;

    try {
      const result = await action;
      stop(context);
      return result;
    } catch (e) {
      failed(context);
      throw e;
    }
  };

  return {
    runRequest,
  };
});
