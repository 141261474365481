import { css } from 'emotion';

export const image = css`
  margin: 100px auto;
`;

export const heading = css`
  margin: auto;
  width: 15%;
`;
