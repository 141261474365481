import { Button, Form } from 'antd';
import React, { Fragment } from 'react';

import { Formik } from 'formik';
import { noOp } from 'utils/core/funcy';

const createSubmitHandler = onSubmit => (values, { resetForm }) => {
  onSubmit(values);
  resetForm();
};

const actions = ({ cancelText, showCancel, onCancel, submitText, working }) => (
  <Fragment>
    <Button disabled={working} loading={working} size="large" type="primary">
      {submitText}
    </Button>

    {showCancel && (
      <Button size="large" onClick={onCancel}>
        {cancelText}
      </Button>
    )}
  </Fragment>
);

export const form = ({
  formInitHandler = noOp,
  cancelText,
  initialValues,
  showActions = true,
  showCancel = true,
  onCancel,
  onSubmit,
  renderFields,
  submitText,
  validator,
  enableReinitialize = true,
  noValidate = true,
  implementationAttributes = {},
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    onSubmit={createSubmitHandler(onSubmit)}
    validate={validator}
    {...implementationAttributes}
  >
    {formProps => {
      formInitHandler(formProps);
      return (
        <Form
          onFinish={formProps.handleSubmit}
          noValidate={noValidate}
          scrollToFirstError
        >
          {renderFields(formProps)}

          {showActions &&
            actions({
              cancelText,
              onCancel,
              showCancel,
              submitText,
              ...formProps,
            })}
        </Form>
      );
    }}
  </Formik>
);
