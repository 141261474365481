import { createViewModel } from './viewModel';
import { getCurrentState } from 'utils/redux';
import { startLogin } from 'utils/auth0';

export const createTasks = (/* dispatch*/) => {
  const init = async () => {};
  const views = (state = getCurrentState()) => createViewModel(state);

  const login = async (initialPath = views().initialPath) => {
    await startLogin(initialPath);
  };

  return {
    init,
    login,
  };
};
