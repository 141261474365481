import {
  data as dataFromLocalStorage,
  update as updateLocalStorage,
} from 'utils/localStorage';
import { buyerSignedIn } from 'analytics';
import { createTasks as createBrowseStoresTasks } from 'src/routes/browse-stores/tasks';
import { createViewModel } from './viewModel';
import { events } from './events';
import { getCurrentState } from 'utils/redux';
import { logout } from 'utils/auth0';
import { routes } from 'router';

const { accountSetup, browseStores } = routes;

export const createTasks = dispatch => {
  const views = (state = getCurrentState()) => createViewModel(state);
  const browseStoresTasks = createBrowseStoresTasks(dispatch);

  const changeDetails = details => {
    dispatch(events.changedDetails(details));
  };

  const selectVendor = async (vendorId, visitView = false) => {
    dispatch(events.selectedVendor(vendorId));

    const { signedInBuyerDetails } = views();

    buyerSignedIn(signedInBuyerDetails);

    updateLocalStorage(() => ({ selectedVendorId: vendorId }));

    await browseStoresTasks.reloadVendors();

    if (visitView) browseStores();
  };

  const repopulateDataFromLocalStorage = (canReload, key) => {
    if (!canReload) return;
    const { [key]: value } = dataFromLocalStorage();
    dispatch(events.selectedVendor(value));
  };

  const init = async () => {};

  const selectRecentVendor = async () => {
    const { hasSelectedVendor, selectedVendor } = views();
    if (!hasSelectedVendor) return;

    await selectVendor(selectedVendor.id);
  };

  const resume = async () => {
    await init();

    const { canLoadSelectedVendorFromLocalStorage } = views();

    repopulateDataFromLocalStorage(
      canLoadSelectedVendorFromLocalStorage,
      'selectedVendorId'
    );

    await selectRecentVendor();

    const { profileAvailable } = views();

    return profileAvailable;
  };

  const attemptLogout = async () => {
    changeDetails({ attemptingLogout: true });
  };

  const stopLogout = async () => {
    changeDetails({
      attemptingLogout: false,
    });
  };

  return {
    init,
    logout,
    attemptLogout,
    stopLogout,
    accountSetup,
    selectVendor,
    resume,
  };
};
