import { Button } from 'antd';
import styled from 'styled-components';

export const SortingOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid var(--gray-300);
  border-radius: 3px;
  width: 100%;
  max-height: 168px;
  overflow: scroll;
`;

export const SortingOption = styled.div`
  background: ${props => (props.isActive ? 'var(--gray-300)' : 'white')};
  padding: 9px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-900);
`;

export const FilterModalWrapper = styled.div`
  background: var(--gray-100);
  min-height: 100vh;
  .label {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--gray-600);
    text-transform: uppercase;
    margin-bottom: 17px;
  }
`;

export const FilterComponentWrapper = styled.div`
  overflow: scroll;
  background: var(--gray-100);
`;

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 45px;
`;

export const StickySection = styled.div`
  background: var(--gray-200);
  padding: 20px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px -1px 0px var(--gray-300);
  width: 100vw;
`;

export const ApplyButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ApplyButton = styled(Button)`
  background: var(--purp-200);
  color: white;
  text-transform: uppercase;
  margin: 0px 45px;
  width: 100%;
  max-height: 40px;
  border-radius: 2px;
  border: 0px;
  &:hover {
    background: var(--purp-100);
    border: 0px;
  }
`;
