import * as styles from './styles';

import { Button } from 'antd';
import Link from 'components/Link';
import React from 'react';
import { noOp } from 'utils/core/funcy';
import { uris } from 'router';

const { signupUri } = uris;

const Landing = ({ signIn = noOp } = {}) => (
  <div className={styles.contentWrapper}>
    <h3>Welcome!</h3>
    <p className="regular">
      GrowFlow Live marketplace - where building orders just got easier
    </p>
    <Button type="primary" size="large" block onClick={signIn}>
      LOG IN
    </Button>
    <p className="regular">
      New to GrowFlow? <Link to={signupUri()}>Register now</Link>
    </p>
  </div>
);

export default Landing;
