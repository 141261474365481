import { useState } from 'react';

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return {
    isModalOpen,
    toggleModal,
  };
};
