import './ux';
import { component, hocWithMappings as hoc } from 'utils/views';
import Template from './template';
import controller from './controller';
import { createTasks } from './ux/tasks';
import { createViewModel } from './ux/viewModel';

const TemplateWithController = hoc('Filter component - ux', controller);

export default component({
  Template: TemplateWithController(Template),
  createViewModel,
  createTasks,
});
