export const createEventHandler = (
  handler,
  { preventDefault = false, stopPropagation = false } = {}
) => (e, ...args) => {
  if (preventDefault) e.preventDefault();
  if (stopPropagation) e.stopPropagation();

  handler(e, ...args);
};

export const preventDefault = handler =>
  createEventHandler(handler, { preventDefault: true });

export const stopPropagation = handler =>
  createEventHandler(handler, { stopPropagation: true });

export const preventDefaultAndStopPropagation = handler =>
  createEventHandler(handler, { preventDefault: true, stopPropagation: true });
