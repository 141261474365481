import { createTasks as createFilterTasks } from '../Filter/ux/tasks';
import { createTasks as createStoreTasks } from '../../tasks';

export const createTasks = dispatch => {
  const { changeSortOrder } = createStoreTasks(dispatch);
  const filterTasks = createFilterTasks(dispatch);

  return {
    ...filterTasks,
    changeSortOrder,
  };
};
