import NavBar from 'components/NavBar';
import React from 'react';
import { uris } from 'router';

const { browseStoresUri } = uris;

export default Template => props => {
  const isBrowseStorePage = props.location.pathname.includes(browseStoresUri());

  return (
    <div id="mainBody">
      <NavBar location={props.location} />
      <div className={`inner-container ${isBrowseStorePage ? 'browse-store-page' : ''}`}>
        <Template {...props} />
      </div>
    </div>
  );
};
