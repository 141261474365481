import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames, getStoreFrontsKey } from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';

const sliceReducer = createSliceReducer(x => x.browseStores);

const newState = () => ({
  storeFronts: {},
  getStoreFrontsRequestStatus: requestState(),
  unlocked: true,
  filter: '',
  initialized: false,
});

const initialState = {
  browseStores: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

registerRequestStatusChangeReducers(
  getStoreFrontsKey,
  val => val.browseStores.getStoreFrontsRequestStatus
);
