import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 query ($state: String!, $storeFrontId: Int!, $vendorId: Int!) {
  getStoreListing(state: $state, storeFrontId: $storeFrontId, vendorId: $vendorId) {
    listings {
      id
      imageUrl
      products {
        Id
      }
    }

    products {
      Id
      Price
      Name
      Description
      Discount
      IsSample
      IsEducationalSample
      MSRP
      QuantityType
      Available
      BulkGramsAvailable
      Size
      ImageUrl
      ImageOriginalUrl
      Image2Url
      Image2OriginalUrl
      Image3Url
      Image3OriginalUrl
      VideoEmbedLink
      UnitName
      UnitShortName
      StrainName
      StrainTypeName
      StrainNotes
      ProductAttributes
      ProductCategoryId
      ProductCategoryName
      ProductCategoryParentName
      ProductCategoryParentId
      ProductBrandId
      ProductBrandName
      ProductStatus
      SourcedProductId
      MinTHCMax
      MaxTHCMax
      MinCBDMax
      MaxCBDMax
      ProductAttributes
      MinTotalOptional
      MaxTotalOptional
      MinTotalTerpenes
      MaxTotalTerpenes
    }
  }
}`;

export default async ({ state = '', storeFrontId = 0, vendorId = 0 } = {}) => {
  const r = await runGQLRequest(
    'getStoreListing',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        storeFrontId,
        vendorId,
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.getStoreListing;
};
