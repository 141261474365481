import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames } from './events';

const sliceReducer = createSliceReducer(x => x.resume);

const newState = () => ({
  busy: true,
  initialPath: ''
});

const initialState = {
  resume: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});

reduceWith(changedDetails, eventNames.changedDetails);
