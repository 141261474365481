import { component, hocWithMappings as hoc } from 'utils/views';
import { Template } from './template';
import { compose } from 'utils/core/funcy';
import controllerLaunchDarkly from 'utils/controllers/launchDarkly';
import { createTasks } from './tasks';
import listingController from '../../listing/controller';

const TemplateWithController = hoc('Listing Card - Listing UX', listingController);

const TemplateWithLaunchDarkly = hoc(
  'Cart - Controller - Launch darkly flags',
  controllerLaunchDarkly
);

const FullTemplate = compose(TemplateWithController, TemplateWithLaunchDarkly)(Template);

export default component({
  Template: FullTemplate,
  createTasks,
});
