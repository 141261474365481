import { Input } from 'antd';
import styled from '@emotion/styled';

export const InputWrapper = styled(Input)`
  .ant-input-suffix {
    margin: -4px -11px -4px 4px;
    width: 32px;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: var(--gray-200);
    color: var(--gray-400);
  }

  &.add-enabled {
    .ant-input-suffix {
      background: var(--purp-200);
      color: var(--gray-000);
      span {
        margin: -15px;
        padding: 15px;
      }
    }
  }
`;
