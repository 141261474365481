import { Button, Input, Modal, Pagination, Select } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  CartSummary,
  Filter,
  FilterModal,
  ListingCard,
  StoreInfo,
  StoreTemplate,
} from '../components';
import { Global, storeContainer } from '../styles';
import React, { Fragment, useState } from 'react';
import CartByCategory from '../components/CartByCategory';
import Loader from 'components/Loader';
import RequestStatus from 'components/RequestStatus';
import { noOp } from 'utils/core/funcy';

const Store = (props = {}) => {
  const {
    pageSize = 10,
    pageNumber = 1,
    pageListings = [],
    listingCount = 0,
    hasActiveFilters = false,
    getStoreProductsStatus,
    productsAreLoaded = false,
    onClearFiltersAndSearch = noOp,
    searchRef = noOp,
    onSearch = noOp,
    onSortChange = noOp,
    changePageAndSize = noOp,
    sortOrderList = [],
    sortOrder: { id: sortId = 1, title: sortTitle = '' } = {},

    isDesktop,
    isMobile,
    isModalOpen,
    isTabletAndBelow,
    toggleModal,
  } = props;

  const [showCartByCategory, setShowCartByCategory] = useState(false);

  const sortOptionTemplate = ({ id, title }) => (
    <Select.Option key={id} value={id}>
      {title}
    </Select.Option>
  );

  const sortingTemplate = isTabletAndBelow ? null : (
    <div className="sort-by-container">
      <p className="regular">Sort by:</p>
      <Select
        onSelect={onSortChange}
        placeholder={sortTitle}
        value={sortId}
        bordered={false}
        suffixIcon={<CaretDownOutlined />}
      >
        {sortOrderList.map(sortOptionTemplate)}
      </Select>
    </div>
  );

  const filteredResultsTemplate = () => (
    <Fragment>
      <p className="regular">{listingCount} Results</p>
      {hasActiveFilters && (
        <Fragment>
          <div className="divider" />
          <Button onClick={onClearFiltersAndSearch} type="link" size="small">
            Clear Filters &amp; Search
          </Button>
        </Fragment>
      )}
      {sortingTemplate}
    </Fragment>
  );

  const listingTemplate = data => (
    <div>
      <ListingCard key={data.id} isMobile={isMobile} listing={data} {...props} />
    </div>
  );

  const resultsTemplate = () => (
    <div className="result-clear-container">{filteredResultsTemplate()}</div>
  );

  const paginationTemplate = () => (
    <div className="result-pagination-container">
      <Pagination
        showSizeChanger
        defaultCurrent={pageNumber}
        current={pageNumber}
        onChange={changePageAndSize}
        total={listingCount}
        defaultPageSize={pageSize}
      />
    </div>
  );

  const desktopRenderer = template => template();

  const mobileRenderer = template => template({ height: 1400 });

  const responsiveTemplate = isDesktop ? desktopRenderer : mobileRenderer;

  const categoryListingsTemplate = responsiveTemplate(({} = {}) => (
    <>
      {resultsTemplate()}
      {pageListings.map(listingTemplate)}
      {paginationTemplate()}
    </>
  ));

  const filterModalTemplate = isDesktop ? null : (
    <Modal
      visible={isModalOpen}
      onCancel={toggleModal}
      footer={null}
      style={{ top: '-10px', left: '-10px', minWidth: 'calc(100% + 10px)' }}
      bodyStyle={{ backgroundColor: 'var(--gray-100)', minWidth: 'calc(100% + 10px)' }}
    >
      <FilterModal onCancel={toggleModal} />
    </Modal>
  );

  const searchContainerTemplate = isTabletAndBelow ? (
    <div className="search-container">
      <Input
        ref={searchRef}
        onChange={onSearch}
        placeholder="Search products"
        prefix={<SearchOutlined />}
      />
    </div>
  ) : null;

  const filterTemplate = isTabletAndBelow ? null : (
    <div className="filter-container">
      <div className="filter-sticky-container">
        <Filter searchRef={searchRef} onSearch={onSearch} />
      </div>
    </div>
  );

  const filterModalTriggerTemplate = isTabletAndBelow ? (
    <div className="filter-sort-button">
      <Button onClick={toggleModal}>FILTER &amp; SORT</Button>
    </div>
  ) : null;

  const storeTemplate = (
    <StoreTemplate {...props}>
      <div className={storeContainer}>
        <Global />
        <StoreInfo {...props} isDesktop={isDesktop} isProductListPage>
          {isDesktop && (
            <CartSummary
              isDesktop={isDesktop}
              setShowCartByCategory={setShowCartByCategory}
              showCartByCategory={showCartByCategory}
            />
          )}
        </StoreInfo>
        {filterModalTriggerTemplate}
        {filterModalTemplate}
        {filterTemplate}
        {searchContainerTemplate}
        <div className="results-container">{categoryListingsTemplate}</div>
      </div>
    </StoreTemplate>
  );

  const loadingTemplate = <Loader message="Loading store..." />;

  const mainTemplate = (
    <Fragment>
      <RequestStatus
        status={getStoreProductsStatus}
        completedTemplate={productsAreLoaded ? storeTemplate : loadingTemplate}
        startedTemplate={loadingTemplate}
      />
      {showCartByCategory && (
        <CartByCategory
          isVisible={showCartByCategory}
          setIsVisible={setShowCartByCategory}
          {...props}
        />
      )}
    </Fragment>
  );

  return mainTemplate;
};

export default Store;
