import './reducers';
import Template from './template';
import { component } from 'utils/views';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { withMainLayout as withLayout } from 'layouts';

export default component({
  Template,
  withLayout,
  createTasks,
  createViewModel,
});
