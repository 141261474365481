import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

const createEventKey = key => `${base}[${key}]`;

export const eventsForKey = key =>
  createEvents(createEventKey(key), {
    started: (context = {}) => ({ context }),
    completed: (context = {}) => ({ context }),
    failed: (context = {}) => ({ context }),
  });
