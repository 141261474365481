import {
  greater_than,
  greater_than_or_equal_to,
  regex,
} from '@developwithpassion/matchers_js';
import { pipe, prop } from 'utils/core/funcy';

const trim = val => val.trim();
const length = prop('length');

export const nonEmptyPasswordRule = {
  id: 'nonEmptyPassword',
  message: 'Non-empty password required',
  criteria: pipe(trim, length, greater_than(0)),
};

export const passwordLengthRule = {
  id: 'passwordLength',
  message: 'At least 8 characters',
  criteria: pipe(length, greater_than_or_equal_to(8)),
};

export const specialCharactersRule = {
  id: 'specialCharacters',
  message: 'Special Characters',
  criteria: regex(/[!@#$%^&*]/),
};

export const lowerCaseUpperCaseAndNumbersRule = {
  id: 'lowerCaseUpperCaseNumber',
  message: 'Lower case (a-z), upper case (A-Z) and numbers (0-9)',
  criteria: regex(/[A-Z]/).and(regex(/[a-z]/)).and(regex(/[0-9]/)),
};

const allPasswordRules = [
  passwordLengthRule,
  specialCharactersRule,
  lowerCaseUpperCaseAndNumbersRule,
];

export const ruleMap = allPasswordRules.reduce(
  (acc, val) => ({ ...acc, [val.id]: val }),
  {}
);

export const allRuleResults = val =>
  allPasswordRules.reduce(
    (acc, { id, criteria }) => ({ ...acc, [id]: criteria(val) }),
    {}
  );
