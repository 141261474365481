export const toHundredths = val => val.toFixed(2);

export const toOptionalDecimal = val => Math.round(val * 100) / 100;

export const buildCurrencyMapper = mapper => val => `$${mapper(val)}`;

export const toCurrency = buildCurrencyMapper(toHundredths);

export const toCurrencyDecimalIfNeeded = buildCurrencyMapper(toOptionalDecimal);

export const dropFractionalIfWhole = val => (val % 1 === 0 ? Math.trunc(val) : val);

export const centsToDollars = val => val / 100;

export const numberOrZero = val => {
  const result = Number(val);

  return isNaN(result) ? 0 : result;
};

export const toUomAbbreviation = uom => {
  switch (uom) {
    case 'Grams':
      return 'g';
    case 'Each':
      return 'ea';
    case 'Milligrams':
      return 'mg';
    default:
      return uom;
  }
};
