const launchDarklyController = () => {
  const { useFlags } = require('gatsby-plugin-launchdarkly');
  const flags = useFlags();

  return {
    flags,
  };
};

export default launchDarklyController;
