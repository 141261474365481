import Axios from 'axios';
import { authOptions } from 'utils/xhrs/authToken';
import config from 'config';

const query = `
 mutation ($state: String!, $vendorId: Int!) {
  storeFrontRemoveBuyingLicense(state: $state, vendorId: $vendorId)
}`;

export default async (state, vendorId) => {
  const r = await Axios.post(
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
        vendorId,
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.storeFrontRemoveBuyingLicense;
};
