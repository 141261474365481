import { Template, controller } from './template';
import { component, hocWithMappings as hoc } from 'utils/views';
import { createTasks } from '../../tasks';

const TemplateWithController = hoc('AddToCart component - ux', controller);

export default component({
  Template: TemplateWithController(Template),
  createTasks,
});
