import React from 'react';
import { Search } from './styles';
import { SearchOutlined } from '@ant-design/icons';
import { noOp } from 'utils/core/funcy';

const FilterSearch = ({ onSearch = noOp, searchRef = noOp }) => (
  <Search
    ref={searchRef}
    onChange={onSearch}
    prefix={<SearchOutlined />}
    placeholder="Search products"
  />
);
export default FilterSearch;
