import { Collapse, Image, Tooltip } from 'antd';
import {
  DownOutlined,
  FacebookFilled,
  GlobalOutlined,
  InstagramFilled,
  TwitterOutlined,
} from '@ant-design/icons';
import React, { Fragment, useState } from 'react';

import CartSummary from '../../cart/components/Summary';
import ReactTextFormat from 'react-text-format';
import config from 'config';
import { storeInfo } from './styles';

const StoreInfo = ({
  storeFront: {
    name = '',
    description = '',
    phone = '',
    email = '',
    logoUrl = '',
    website = '',
    instagramUrl = '',
    twitterUrl = '',
    facebookUrl = '',
    licenseNumber = '',
  } = {},
  children = null,
  isDesktop = false,
  isCartPage = false,
  isProductListPage = false,
} = {}) => {
  const [open, setOpen] = useState(false);

  const logoImage = (
    <Image
      src={logoUrl || 'error'}
      alt={`${name} logo`}
      loading="lazy"
      preview={!isDesktop}
      className="logo"
      fallback={config.images.fallback}
    />
  );

  const cartPageTemplate = (
    <div className="cart-page-container">
      {logoImage}
      {name ? <h2>{name}</h2> : null}
    </div>
  );

  const mobileAndDesktopTemplate = (
    <Fragment>
      {isDesktop && name ? <h2>{name}</h2> : null}
      {isDesktop && licenseNumber ? (
        <span className="regular light">
          {licenseNumber && <Tooltip title="License Number">#{licenseNumber}</Tooltip>}
        </span>
      ) : null}
      {email && (
        <a href={`mailto:${email}`} className="regular email">
          {email}
        </a>
      )}
      {phone && (
        <a href={`tel:${phone}`} className="regular tel">
          {phone}
        </a>
      )}
      <div className="logo-social-container">
        {logoImage}
        <div className="social-container">
          {website && (
            <Tooltip title={`${name} website`}>
              <a href={website} title="website" target="_blank" rel="noopener noreferrer">
                <GlobalOutlined />
              </a>
            </Tooltip>
          )}
          {facebookUrl && (
            <Tooltip title="Facebook">
              <a
                href={facebookUrl}
                title="facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookFilled />
              </a>
            </Tooltip>
          )}
          {instagramUrl && (
            <Tooltip title="Instagram">
              <a
                href={instagramUrl}
                title="instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramFilled />
              </a>
            </Tooltip>
          )}
          {twitterUrl && (
            <Tooltip title="Twitter">
              <a
                href={twitterUrl}
                title="twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterOutlined />
              </a>
            </Tooltip>
          )}
        </div>
      </div>
      <ReactTextFormat>
        <p className="regular-plus description">{description}</p>
      </ReactTextFormat>
    </Fragment>
  );

  const panelHeader = (
    <div className="panel-header-container">
      <h4>{name}</h4>
      <DownOutlined className={`icon arrow ${open ? 'open' : 'closed'}`} />
      <CartSummary />
    </div>
  );

  return (
    <div
      className={`${storeInfo} store-info ${isCartPage ? 'cart-page' : ''} ${
        isProductListPage ? 'product-list-page' : ''
      }`}
    >
      <div className="store-info-sticky-container">
        {children}
        {isCartPage ? (
          cartPageTemplate
        ) : isDesktop ? (
          mobileAndDesktopTemplate
        ) : (
          <Collapse
            bordered={false}
            onChange={() => setOpen(!open)}
            className="collapse-container"
          >
            <Collapse.Panel
              header={panelHeader}
              key="1"
              showArrow={false}
              className="collapse-header"
            >
              {mobileAndDesktopTemplate}
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default StoreInfo;
