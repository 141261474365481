import { createViewModelAttributeBuilder } from 'utils/viewModel';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const resume = viewModelAttribute(
  'Main ViewModel',
  ({ resume }) => resume,
  identity
);

export const initialPath = viewModelAttribute(resume, ({ initialPath }) => initialPath);

export const createViewModel = resume;
