import {
  cartWithoutEmptyLineItems as cartWithoutEmptyLineItemsImpl,
  clearProductListings,
  createViewModel as createViewModelImpl,
  setProductListings,
} from '../viewModel';
import {
  createTasks as createImplTasks,
  createStoreListingArgs,
  createView,
  createViewArgs,
  display as displayImpl,
  initializeStoreDetails,
} from './impl';
import { events, getStoreProductsKey } from '../reducers/events';
import {
  data as localStorageDataImpl,
  update as updateLocalStorageImpl,
} from 'utils/localStorage';
import { createTasks as createFilterTasksImpl } from '../components/Filter/ux/tasks';
import { createTasks as createRequestTasksImpl } from 'state/slice/request/tasks';
import { getCurrentState } from 'utils/redux';
import { logException } from 'utils/core/funcy';
import { routes } from 'router';
import { sortOrderList } from '../shared/sortOrders';
import storeListingRequest from 'apiRequests/storeListing';
import { storeSelected } from 'analytics';

const {
  listing,
  notFound: notFoundImpl,
  orderComplete: orderCompleteImpl,
  store: storeImpl,
} = routes;

const dependencies = {
  createViewModel: (state = getCurrentState()) => createViewModelImpl(state),
  createFilterTasks: dispatch => createFilterTasksImpl(dispatch),
  cartWithoutEmptyLineItems: cartWithoutEmptyLineItemsImpl,
  storeListing: storeListingRequest,
  localStorageData: localStorageDataImpl,
  updateLocalStorage: updateLocalStorageImpl,
  store: storeImpl,
  orderComplete: orderCompleteImpl,
  createRequestTasks: createRequestTasksImpl,
  notFound: notFoundImpl,
  createImplTasks,
};

export const createTasks = (
  dispatch,
  props = {},
  { allDependencies = dependencies } = {}
) => {
  const {
    createFilterTasks,
    localStorageData,
    createViewModel: views,
    createRequestTasks,
    createImplTasks,
  } = allDependencies;
  const { clearAllFilters } = createFilterTasks(dispatch);
  const getStoreProductsTasks = createRequestTasks(getStoreProductsKey, dispatch);

  const changeDetails = details => dispatch(events.changedDetails(details));

  const updateProducts = action => {
    action();
    dispatch(events.productsUpdated());
  };

  const changePage = pageNumber => {
    changeDetails({ pageNumber });
    // scroll to top on page change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changePageAndSize = (pageNumber, pageSize) => {
    changeDetails({pageNumber, pageSize});
    // scroll to top on page change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const saveCartToLocalStorage = () => {
    const { updateLocalStorage } = dependencies;
    const { cartForLocalStorage: cart } = views();
    updateLocalStorage(() => ({ cart }));
  };

  const emptyCartForStoreFront = storeFrontId => {
    dispatch(events.initializedCartForStoreFront(storeFrontId));
    saveCartToLocalStorage();
  };

  const initializeCartForStoreFront = storeFrontId => {
    const { cartWithoutEmptyLineItems } = dependencies;
    const { hasCartForStoreFront, hasCartInLocalStorage } = views();
    if (hasCartForStoreFront) return;

    if (hasCartInLocalStorage) {
      const { cart } = localStorageData();
      changeDetails({ cart: cartWithoutEmptyLineItems(cart) });
    }

    let { hasCartForStoreFront: hasCart } = views();

    if (!hasCart) emptyCartForStoreFront(storeFrontId);
  };

  const initializePageForStoreFront = () => {
    changePage(1);
  };

  const implTasks = createImplTasks({
    dispatch,
    changeDetails,
    views,
    saveCartToLocalStorage,
  });

  const reloadProducts = async () => {
    const { storeListing } = dependencies;

    await logException(async () => {
      const storeListingResult = await getStoreProductsTasks.runRequest(
        storeListing(createStoreListingArgs({ views, props }))
      );

      updateProducts(() => {
        setProductListings(storeListingResult);
        implTasks.setProductListings(storeListingResult);
      });
    });
  };

  const showDetail = async listingId => {
    const { storeFrontId } = views();
    changeDetails({ listingId });
    await reloadProducts();
    await listing({ storeFrontId, listingId });
  };

  const changeSearchTerm = (searchTerm = '') => {
    changeDetails({ searchTerm });
    changePage(1);
  };

  const display = async (...args) => {
    changeSearchTerm('');
    await displayImpl(...args);
  };

  const builderArgs = {
    dispatch,
    views,
    props,
    changeDetails,
    dependencies,
    updateProducts,
    clearProductListings,
    display,
    reloadProducts,
    storeSelected,
    initializeCartForStoreFront,
    initializePageForStoreFront,
  };

  const viewArgs = createViewArgs({ props });

  const view = createView(builderArgs);

  const init = async (render = true) => {
    const { initialized } = views();

    if (initialized) return;

    changeDetails({ initialized: true });

    initializeStoreDetails({ props, changeDetails });

    await view(viewArgs, render);
  };

  const resume = async () => {
    await init(false);
  };

  const completeOrder = async orderNumber => {
    const { orderComplete } = dependencies;
    const { storeFrontId } = views();

    clearAllFilters();

    emptyCartForStoreFront(storeFrontId);

    reloadProducts();

    await orderComplete({ storeFrontId, orderNumber });
  };

  const removeLineItemsFlaggedForDeletion = () => {
    const { cartWithoutEmptyLineItems } = dependencies;
    const { cart } = views();
    dispatch(events.changedDetails({ cart: cartWithoutEmptyLineItems(cart) }));
  };

  const changeSortOrder = (sortId = sortOrderList[0].id) => {
    changeDetails({ sortId });
    changePage(1);
  };

  const clearFiltersAndSearch = () => {
    clearAllFilters();
    changeSearchTerm('');
  };

  return {
    changeSearchTerm,
    changeSortOrder,
    clearFilters: clearFiltersAndSearch,
    changePage,
    changePageAndSize,
    resume,
    display,
    view,
    showDetail,
    completeOrder,
    removeLineItemsFlaggedForDeletion,
    reloadProducts,

    initializeCartForStoreFront,
    initializePageForStoreFront,

    __test__: {
      emptyCartForStoreFront,
      initializeCartForStoreFront,
      saveCartToLocalStorage,
    },

    ...implTasks,

    init,
  };
};

export const __test__ = {
  dependencies,
};
