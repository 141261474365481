import React, { useState } from 'react';
import { Progress } from './styles';

const ProgressBar = ({
  percent = 0,
  showInfo = false,
  status = '',
  simulateProgress = false,
} = {}) => {
  const [percentProgress, setPercentProgress] = useState(0);

  const mockLoader = () => {
    setPercentProgress(percentProgress + 10);
  };

  if (simulateProgress) {
    setTimeout(() => {
      if (percentProgress < 100) mockLoader();
    }, 20);
  }

  return (
    <Progress
      strokeColor={{ '0%': '#6b57f8', '100%': '#3061F2' }}
      percent={percentProgress || percent}
      showInfo={showInfo}
      status={status}
    />
  );
};
export default ProgressBar;
