import { useEffect, useState } from 'react';
import { noOp } from 'utils/core/funcy';
import { preventDefaultAndStopPropagation } from 'utils/ui/createEventHandler';
import { criteria as where } from '@developwithpassion/match_js';

const dependencies = {
  useEffect,
};

const StoreListingController = (props = {}) => {
  const {
    changeCartProductQuantity = noOp,
    changeCartProductNote = noOp,
    trackProductAdded = noOp,
    trackProductRemoved = noOp,
    productWithFullDetails = noOp,
    productDetail: { images = [] } = {},
  } = props;

  const { useEffect } = dependencies;
  const [activeImage, setActiveImage] = useState({
    id: 0,
    url: 'error',
    largeUrl: 'error',
  });

  const changeCartQuantity = ({ productId, proposedValue }) => {
    const product = productWithFullDetails(productId);
    const trackingAction = proposedValue > 0 ? trackProductAdded : trackProductRemoved;
    const trackingQuantity = Math.max(proposedValue, 0);

    trackingAction({ ...product, cartQuantity: trackingQuantity });

    changeCartProductQuantity({ productId, quantity: proposedValue });
  };

  const createLineItemNoteChangeHandler = ({ id: productId }) => ({
    target: { value },
  }) => {
    changeCartProductNote({ productId, note: value });
  };

  const createImageClickHandler = id =>
    preventDefaultAndStopPropagation(() => {
      setActiveImage(images.find(where({ id })));
    });

  useEffect(() => {
    if (images.length > 0) setActiveImage(images[0]);
  }, [images]);

  return {
    createImageClickHandler,
    activeImage,
    changeCartQuantity,
    createLineItemNoteChangeHandler,
  };
};

export default StoreListingController;

export const __test__ = {
  dependencies,
};
