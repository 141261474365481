import { Input } from 'antd';
import styled from 'styled-components';

export const Search = styled(Input)`
  border: solid 1px var(--gray-400);
  border-radius: 2px;
  margin-right: 24px;
  width: 100%;
  svg {
    color: var(--gray-400);
    width: unset;
    height: unset;
  }
  @media (min-width: 1440px) {
    height: 32px;
  }
`;
