import React, { Fragment, useState } from 'react';
import { AddToCart } from '../components';
import CartByCategory from '../components/CartByCategory';
import { CartSummary } from '../cart';
import Image from 'components/Image';
import { LeftOutlined } from '@ant-design/icons';
import Loader from 'components/Loader';
import OversoldInventoryWarning from '../components/OversoldInventoryWarning';
import RequestStatus from 'components/RequestStatus';
import { Skeleton } from 'antd';
import StoreInfo from '../components/StoreInfo';
import YouTubeEmbed from 'components/YouTube';
import config from 'config';
import { detailContainer } from './styles';
import { noOp } from 'utils/core/funcy';
import { toCurrency } from 'utils/ui/mappers';

export const SkeletonListing = () => <Skeleton avatar paragraph={4} />;

const Listing = (props = {}) => {
  const [showCartByCategory, setShowCartByCategory] = useState(false);
  const {
    listingDetail: {
      products = [],
      videoEmbedLink = '',
      thcRange: {
        available: thcAvailable = false,
        description: thcDescription = '',
      } = {},
      cbdRange: {
        available: cbdAvailable = false,
        description: cbdDescription = '',
      } = {},
      totalOptionalRange: {
        available: totalOptionalAvailable = false,
        description: totalOptionalDescription = '',
      } = {},
      terpenesRange: {
        available: terpenesAvailable = false,
        description: terpenesDescription = '',
      } = {},
    } = {},
    storeFront: {
      showProductStatusFilter = false,
      columnOptionsDTO: {
        cbd: showCBD = false,
        thc: showTHC = false,
        totalOptional: showTotalOptional = false,
        totalTerpenes: showTotalTerpenes = false,
      } = {},
    } = {},
    flags: { wsLiveProductStatus = false } = {},
    activeImage: { id: activeImageId, largeUrl: activeImageUrl = 'missing' } = {},
    getStoreProductsStatus = noOp,
    back = noOp,
    textQuantityForProduct = noOp,
    changeCartQuantity = noOp,
    productWithFullDetails = noOp,
    createImageClickHandler = noOp,
    isDesktop = true,
    isMobile = false,
  } = props;

  const [
    {
      description = '',
      strainTypeName,
      strainName,
      name,
      productBrandName,
      images = [],
      productStatus = '',
    } = {},
  ] = products;

  const galleryTemplate = (
    <div className="gallary-container">
      <Image
        src={activeImageUrl}
        loading="lazy"
        className="main-image"
        fallback={config.images.fallback}
      />
      <div className="thumbnail-container">
        {images.length > 0
          ? images.map(({ id, url }) => (
              <img
                key={id}
                src={url}
                loading="lazy"
                onClick={createImageClickHandler(id)}
                className={`thumbnail-image ${id === activeImageId ? 'selected' : ''}`}
              />
            ))
          : null}
        {videoEmbedLink ? <YouTubeEmbed src={videoEmbedLink} width={472} /> : null}
      </div>
    </div>
  );

  const productTemplate = ({ id }) => {
    const {
      availabilityDescription,
      sizeDisplayName,
      price,
      total,
      cartQuantity,
      isRemainingInventoryOversold,
      maxAmountInCart,
      isCountBased,
    } = productWithFullDetails(id);

    const addToCartProps = {
      id,
      changeCartQuantity,
      maxAmountInCart,
      cartQuantity,
      total,
      textQuantityForProduct,
      isCountBased,
    };

    return (
      <Fragment key={id}>
        <p className="regular product-row">{sizeDisplayName}</p>
        <p className="regular product-row">{price ? toCurrency(price) : '$0.00'}</p>
        <p className="regular product-row">{availabilityDescription}</p>
        <AddToCart {...addToCartProps} />
        <div className="grid-row-spacer" />

        <OversoldInventoryWarning
          isRemainingInventoryOversold={isRemainingInventoryOversold}
          maxAmountInCart={maxAmountInCart}
          isMobile={isMobile}
        />
      </Fragment>
    );
  };

  const mainTemplate = (
    <div className={detailContainer}>
      <div className="back-cart-container">
        <div className="back" onClick={back}>
          <LeftOutlined className="icon" />
          <p className="semi-bold">Back to products</p>
        </div>
      </div>
      <div className="product-description">
        {!isMobile ? galleryTemplate : null}
        <p className="regular-plus">{description}</p>
      </div>
      <div className="product-info">
        {isMobile ? galleryTemplate : null}
        {strainTypeName && <p className="semi-bold strain-type">{strainTypeName}</p>}
        {strainName && <p className="strain-name">{strainName}</p>}
        {name && <p className="strain-name">{name}</p>}
        {productBrandName && <h3 className="brand-name">{productBrandName}</h3>}
        {showProductStatusFilter &&
        wsLiveProductStatus &&
        productStatus === 'Backorder' ? (
          <p className="product-status-backorder-lg">(Backorder)</p>
        ) : null}
        <div className="add-to-cart-container">
          <h5 className="header">Size</h5>
          <h5 className="header">Price</h5>
          <h5 className="header">Available</h5>
          <h5 className="header">Qty</h5>
          <h5 className="header" />
          <div className="grid-header-spacer"></div>
          {products.map(productTemplate)}
        </div>
        <div className="test-results">
          <h5>TEST RESULTS</h5>
          {showTotalOptional && totalOptionalAvailable ? (
            <div className="test-results-flex-container">
              <p className="semi-bold">Total:</p>
              <p className="regular">{totalOptionalDescription}</p>
            </div>
          ) : null}
          {showTHC && thcAvailable ? (
            <div className="test-results-flex-container">
              <p className="semi-bold">THC:</p>
              <p className="regular">{thcDescription}</p>
            </div>
          ) : null}
          {showCBD && cbdAvailable ? (
            <div className="test-results-flex-container">
              <p className="semi-bold">CBD:</p>
              <p className="regular">{cbdDescription}</p>
            </div>
          ) : null}
          {showTotalTerpenes && terpenesAvailable ? (
            <div className="test-results-flex-container">
              <p className="semi-bold">Terps:</p>
              <p className="regular">{terpenesDescription}</p>
            </div>
          ) : null}
        </div>
      </div>

      <StoreInfo {...props} isDesktop={isDesktop}>
        {isDesktop ? (
          <CartSummary
            showCartByCategory={showCartByCategory}
            setShowCartByCategory={setShowCartByCategory}
            isDesktop
          />
        ) : null}
      </StoreInfo>
    </div>
  );

  const loadingTemplate = <Loader message="Loading product" />;

  return (
    <>
      <RequestStatus
        status={getStoreProductsStatus}
        completedTemplate={mainTemplate}
        startedTemplate={loadingTemplate}
      />
      {showCartByCategory && (
        <CartByCategory
          setIsVisible={setShowCartByCategory}
          isVisible={showCartByCategory}
          {...props}
        />
      )}
    </>
  );
};

export default Listing;
