import Avo from 'analytics/avo';
import { createTrackingFunction } from 'analytics/utils';

const publishStoreSelected = async ({ id: storeId, name: storeName }) => {
  Avo.storeSelected({
    storeId,
    storeName,
  });
};

export const storeSelected = createTrackingFunction(publishStoreSelected);

export const __test__ = {
  publishStoreSelected,
};
