import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import {
  addVendorLicenseKey,
  eventNames,
  getJurisdictionsKey,
  getStoreFrontUserVendorsKey,
  getUserInfoKey,
  getVendorLicensesKey,
} from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';

const sliceReducer = createSliceReducer(x => x.accountSetup);

export const getVendorLicensesRequestStatusSelector = x =>
  x.accountSetup.getLicensesRequestStatus;

export const addBuyingLicenseRequestStatusSelector = x =>
  x.accountSetup.addBuyingLicenseRequestStatus;

export const getStoreFrontUserVendorsRequestStatusSelector = x =>
  x.accountSetup.getStoreFrontUserVendorsRequestStatus;

export const getUserInfoStatusSelector = x => x.accountSetup.getUserInfoRequestStatus;

export const newState = () => ({
  contactInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
  state: '',
  states: [],
  selectedVendors: {},
  vendors: [],
  getJurisdictionsRequestStatus: requestState(),
  getUserInfoRequestStatus: requestState(),
  getLicensesRequestStatus: requestState(),
  addBuyingLicenseRequestStatus: requestState(),
  getStoreFrontUserVendorsRequestStatus: requestState(),
  vendorsPageNumber: 1,
  vendorFilter: '',
  vendorSearchIsFocused: false,
});

const initialState = {
  accountSetup: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

export const selectedVendor = sliceReducer((accountSetup, { payload: { vendor } }) => {
  accountSetup.selectedVendors[vendor.id] = vendor;
});

reduceWith(selectedVendor, eventNames.selectedVendor);

export const removedVendor = sliceReducer((accountSetup, { payload: { vendorId } }) => {
  delete accountSetup.selectedVendors[vendorId];
});

reduceWith(removedVendor, eventNames.removedVendor);

export const changedDetails = sliceReducer((accountSetup, { payload: { details } }) => {
  Object.assign(accountSetup, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

registerRequestStatusChangeReducers(
  getVendorLicensesKey,
  getVendorLicensesRequestStatusSelector
);

registerRequestStatusChangeReducers(
  addVendorLicenseKey,
  addBuyingLicenseRequestStatusSelector
);

registerRequestStatusChangeReducers(
  getStoreFrontUserVendorsKey,
  getStoreFrontUserVendorsRequestStatusSelector
);

registerRequestStatusChangeReducers(
  getJurisdictionsKey,
  val => val.accountSetup.getJurisdictionsRequestStatus
);

registerRequestStatusChangeReducers(getUserInfoKey, getUserInfoStatusSelector);
