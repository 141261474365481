import { events } from './events';
import { newState } from './reducers';

export const createTasks = dispatch => {
  const createRangeChangeTask = actionBuilder => ([start, end]) => {
    dispatch(actionBuilder({ start, end }));
    dispatch(events.changed());
  };

  const toggleFilterGroupExpand = groupId => {
    dispatch(events.toggledFilterGroupExpanded(groupId));
  };

  const updateFilterGroupItems = ({ filterIds = [], enabled = false } = {}) => {
    dispatch(events.updatedFilterGroupItems(filterIds, enabled));
    dispatch(events.changed());
  };

  const clearAllFilters = () => {
    dispatch(events.changedDetails(newState()));
    dispatch(events.changed());
  };

  const clearRange = groupId => {
    dispatch(events.clearedRange(groupId));
    dispatch(events.changed());
  };

  const changeTHCRange = createRangeChangeTask(events.changedTHCRange);

  const changeCBDRange = createRangeChangeTask(events.changedCBDRange);

  const changePriceRange = createRangeChangeTask(events.changedPriceRange);

  return {
    clearRange,
    changeTHCRange,
    changeCBDRange,
    changePriceRange,
    clearAllFilters,
    toggleFilterGroupExpand,
    updateFilterGroupItems,
  };
};
