import { appReducer, createInitialState } from '../reducers';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import config from 'config';

const logger = () => next => action => next(action);

const middleware = [logger];

let store = null;

const configureMiddleWare = middleWare =>
  config.redux.devToolsEnabled ? composeWithDevTools(middleWare) : middleWare;

export const initStore = () => {
  store =
    store ||
    createStore(
      appReducer,
      createInitialState(),
      configureMiddleWare(applyMiddleware(...middleware))
    );

  return store;
};

export const getCurrentState = () => store.getState();
export const dispatch = event => store.dispatch(event);

export const withRedux = Template => props => (
  <Provider store={initStore()}>
    <Template {...props} />
  </Provider>
);
