import * as styles from './styles';

import { Dropdown, Menu, Modal } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';
import React from 'react';
import { noOp } from 'utils/core/funcy';

const Profile = ({
  licenses = [],
  profileAvailable = false,
  selectedVendor: { id: selectedVendorId = 0, title: selectedVendorTitle = '' } = {},

  ownState: { attemptingLogout = false },

  accountSetup = noOp,
  selectVendor = noOp,
  attemptLogout = noOp,
  stopLogout = noOp,
  logout = noOp,
}) => {
  const createClickHandler = vendorId =>
    vendorId === selectedVendorId ? noOp : () => selectVendor(vendorId, true);

  const vendorLicense = ({ id, title }) => (
    <Menu.Item
      key={id}
      onClick={createClickHandler(id)}
      className={`${styles.menuItem} ${id === selectedVendorId ? 'selected' : ''}`}
    >
      <p className="regular">{title}</p>
    </Menu.Item>
  );

  const menu = (
    <Menu>
      {licenses.map(vendorLicense)}
      <Menu.Item className={`${styles.menuItem} border`} onClick={accountSetup}>
        <p className="regular">Account Settings</p>
      </Menu.Item>
      <Menu.Item className={styles.menuItem} onClick={attemptLogout}>
        <p className="regular">Logout</p>
      </Menu.Item>
    </Menu>
  );

  const logoutTemplate = (
    <Modal
      title="Log out of GrowFlow Live?"
      visible={attemptingLogout}
      onOk={logout}
      onCancel={stopLogout}
      cancelText="Cancel"
      okText="Yes, log out"
    >
      <p className="regular">
        This will sign you out of all GrowFlow Live windows you have open.
      </p>
    </Modal>
  );

  const profileAvailableTemplate = (
    <div>
      <Dropdown overlay={menu} placement="bottomCenter" arrow trigger={['click']}>
        <p className="semi-bold selected-store">
          {selectedVendorTitle} <CaretDownOutlined />
        </p>
      </Dropdown>
    </div>
  );

  const profileUnavailableTemplate = <p className="regular">Profile not available</p>;

  return (
    <>
      {profileAvailable && profileAvailableTemplate}
      {!profileAvailable && profileUnavailableTemplate}
      {logoutTemplate}
    </>
  );
};

export default Profile;
