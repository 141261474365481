import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  buildCurrencyMapper,
  dropFractionalIfWhole,
  toHundredths,
} from 'utils/ui/mappers';
import { noOp, pipe, reducerForUniqueMap } from 'utils/core/funcy';
const menuClosedIcon = <CaretRightOutlined />;
const menuOpenIcon = <CaretDownOutlined />;

const fractionalMapper = pipe(toHundredths, dropFractionalIfWhole);
const currencyMapper = buildCurrencyMapper(fractionalMapper);

export default ({
  allActiveItemsInGroup = noOp,
  activeFilterGroupItems = [],
  filterGroups = [],
  updateFilterGroupItems = noOp,
  isActiveFilterGroup = noOp,
  thcRange = {},
  cbdRange = {},
  childCategoriesForParent = noOp,
  priceRange = {},
  productsTHCRange = {},
  productsCBDRange = {},
  productsPriceRange = {},
  clearAllFilters = noOp,
  isAParentCategory = noOp,
  changeTHCRange = noOp,
  changeCBDRange = noOp,
  changePriceRange = noOp,
  clearRange = noOp,
} = {}) => {
  const [openFilterGroupMap, setOpenFilterGroupMap] = useState({});
  const [openFilterGroupKeys, setOpenFilterGroupKeys] = useState([]);

  const getAllNodes = id =>
    isAParentCategory(id) ? childCategoriesForParent(id).map(({ id }) => id) : [id];

  const onFilterChecked = (id, checked) => {
    updateFilterGroupItems({
      filterIds: getAllNodes(id),
      enabled: checked,
    });
  };

  const createClearFilterSelectionsHandler = key => () => {
    updateFilterGroupItems({
      filterIds: allActiveItemsInGroup(key),
      enabled: false,
    });
  };

  const createSliderClearFilterSelectionsHandler = key => () => {
    clearRange(key);
  };

  const filterItemWithActiveTracking = ({ id, children = [], ...rest }) => {
    const isActive = isActiveFilterGroup({ id, children, ...rest });

    return {
      id,
      children: children.map(filterItemWithActiveTracking),
      ...rest,
      clearable: isActive,
      isGroupActive: isActive,
      onToggle: () => onFilterChecked(id, !isActive),
    };
  };

  const filterGroupsWithActiveTracking = filterGroups.map(filterItemWithActiveTracking);

  const onMenuClick = ({ key }) => {
    setOpenFilterGroupMap({ ...openFilterGroupMap, [key]: !openFilterGroupMap[key] });
  };

  const getMenuIcon = key => (openFilterGroupMap[key] ? menuOpenIcon : menuClosedIcon);

  const uiTHCRange = {
    ...thcRange,
    key: thcRange.groupId,
    id: thcRange.groupId,
    title: 'THC',
    productsRange: productsTHCRange,
    valueRange: thcRange,
    changeHandler: changeTHCRange,
    markerMapper: fractionalMapper,
    clearHandler: createSliderClearFilterSelectionsHandler(thcRange.groupId),
    clearable: thcRange.changed,
    onMenuClick,
    getMenuIcon,
  };

  const uiCBDRange = {
    ...cbdRange,
    title: 'CBD',
    id: cbdRange.groupId,
    key: cbdRange.groupId,
    productsRange: productsCBDRange,
    valueRange: cbdRange,
    changeHandler: changeCBDRange,
    markerMapper: fractionalMapper,
    clearHandler: createSliderClearFilterSelectionsHandler(cbdRange.groupId),
    clearable: cbdRange.changed,
    onMenuClick,
    getMenuIcon,
  };

  const uiPriceRange = {
    ...priceRange,
    id: priceRange.groupId,
    key: priceRange.groupId,
    title: 'Price',
    productsRange: productsPriceRange,
    valueRange: priceRange,
    changeHandler: changePriceRange,
    markerMapper: currencyMapper,
    clearHandler: createSliderClearFilterSelectionsHandler(priceRange.groupId),
    clearable: priceRange.changed,
    onMenuClick,
    getMenuIcon,
  };

  const sliderFilters = [uiTHCRange, uiCBDRange, uiPriceRange];

  const expandedMenus = Object.entries(openFilterGroupMap)
    .filter(([, val]) => val)
    .map(([key]) => key);

  const allGroups = () => filterGroups.concat(sliderFilters).map(({ id }) => ({ id }));

  const resetFilterGroups = () => {
    setOpenFilterGroupMap(
      allGroups().reduce(
        reducerForUniqueMap('id', () => false),
        {}
      )
    );
  };

  useEffect(resetFilterGroups, [filterGroups]);

  useEffect(() => {
    setOpenFilterGroupKeys(expandedMenus);
  }, [openFilterGroupMap]);

  const onTreeChecked = (_, { node: { id }, checked }) => onFilterChecked(id, checked);

  return {
    activeFilterGroupItems,
    clearAllFilters,
    createClearFilterSelectionsHandler,
    filterGroups: filterGroupsWithActiveTracking,
    onMenuClick,
    openFilterGroupKeys,
    sliderFilters,
    getMenuIcon,
    onTreeChecked,
  };
};
