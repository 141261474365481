/* eslint-disable */
import * as styles from './styles';

import { Alert, Button, Input, Tabs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import ProgressBar from 'components/ProgressBar';
import Loader from 'components/Loader';
import RequestStatus from 'components/RequestStatus';
import StoreCard from './storeFront';
import { noOp } from 'utils/core/funcy';

const BrowseStores = ({
  hasNoResultsDueToFiltering = false,
  getStoreFrontsRequestStatus = 0,
  shared: {
    profile: { selectedVendor: { name: selectedVendorName = '' } = {} } = {},
  } = {},
  searchInputRef = noOp,
  storeFrontsList = [],

  ownState: { unlocked = false } = {},
  unlockedStoreFrontsCount = 0,
  lockedStoreFrontsCount = 0,
  currentTab = '',

  reloadVendors = noOp,
  viewLocked = noOp,
  viewUnLocked = noOp,
  onFilterChanged = noOp,
  handleTabChange = noOp,
  setCurrentTab = noOp,
} = {}) => {
  const storeCardTemplate = store => <StoreCard id={store.id} key={store.id} />;

  const noResultsTemplate = ({ message = '', description = '' } = {}) => (
    <Alert className="browse-alert" message={message} description={description} />
  );

  const noResultsDueToFilteringTemplate = hasNoResultsDueToFiltering
    ? noResultsTemplate({
        message: 'No stores found',
        description: "We can't find any stores that match",
      })
    : null;

  const noUnlockedStoresTemplate =
    unlockedStoreFrontsCount > 0
      ? null
      : noResultsTemplate({
          description: (
            <span>
              No unlocked stores yet! Request access from any locked store to{' '}
              <a onClick={() => setCurrentTab('locked')}>get started</a>.
            </span>
          ),
        });

  const noLockedStoresTemplate =
    lockedStoreFrontsCount > 0
      ? null
      : noResultsTemplate({
          description: 'All of them are unlocked.',
        });

  const storeFrontListTemplate =
    storeFrontsList.length === 0 ? null : (
      <div className="store-card-grid-container">
        <div className="css-grid-hack" />
        {storeFrontsList.map(storeCardTemplate)}
      </div>
    );

  const storeFrontList = (
    <div className="inner-container">
      {noResultsDueToFilteringTemplate}
      {currentTab === 'unlocked' && noUnlockedStoresTemplate}
      {currentTab === 'locked' && noLockedStoresTemplate}
      {storeFrontListTemplate}
    </div>
  );

  const storeFrontsReadyTemplate = (
    <Fragment>
      <Tabs
        activeKey={currentTab}
        type="card"
        tabBarGutter={4}
        className="store-list-container"
        onChange={handleTabChange}
      >
        <Tabs.TabPane
          tab={<p className="regular">{`Unlocked (${unlockedStoreFrontsCount})`}</p>}
          key="unlocked"
          className="tab-container"
        >
          {storeFrontList}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<p className="regular">{`Locked (${lockedStoreFrontsCount})`}</p>}
          key="locked"
          className="tab-container"
        >
          {storeFrontList}
        </Tabs.TabPane>
      </Tabs>
    </Fragment>
  );

  const failedToLoadStoreFrontsTemplate = (
    <Alert className="browse-alert" type="warning" message="Failed to load storefronts" />
  );

  const progressBarTemplate = <ProgressBar simulateProgress={true} />;

  const storeFrontsTemplate = (
    <RequestStatus
      status={getStoreFrontsRequestStatus}
      inactiveTemplate={storeFrontsReadyTemplate}
      completedTemplate={storeFrontsReadyTemplate}
      startedTemplate={progressBarTemplate}
      failedTemplate={failedToLoadStoreFrontsTemplate}
    />
  );
  return (
    <div className={styles.browseWrapper}>
      <div className="browse-header">
        <div className="inner-container">
          <h3>Browse Stores</h3>
          <Input
            className="store-search"
            ref={searchInputRef}
            prefix={<SearchOutlined />}
            size="large"
            placeholder="Search vendors..."
            onChange={onFilterChanged}
          />
        </div>
      </div>
      {/* ToDo: I am keeping this here, but setting the display: none.
                This may be handy later if a refresh is required?
                */}
      <div style={{ display: 'none' }}>
        <Button type="primary" icon={<ReloadOutlined />} onClick={reloadVendors}>
          refresh
        </Button>
      </div>
      {storeFrontsTemplate}
    </div>
  );
};

export default BrowseStores;
