import { identity, noOp } from 'utils/core/funcy';
import React from 'react';
import { Slider } from 'antd';

const marks = ({
  min = 0,
  max = 1,
  minStart = 0,
  maxStart = 1,
  markerDisplayMapper = identity,
}) => ({
  [min]: markerDisplayMapper(min),
  [minStart]: markerDisplayMapper(minStart),
  [max]: markerDisplayMapper(max),
  [maxStart]: markerDisplayMapper(maxStart),
});

const Range = ({
  onChange = noOp,
  onAfterChange = noOp,
  min = 0,
  max = 0,
  minStart = min,
  maxStart = max,
  markerDisplayMapper = identity,
  step = 1,
  ...rest
} = {}) => (
  <Slider
    range
    marks={marks({ min, max, minStart, maxStart, markerDisplayMapper })}
    min={min}
    max={max}
    onChange={onChange}
    onAfterChange={onAfterChange}
    value={[minStart, maxStart]}
    step={step}
    {...rest}
  />
);

export default Range;
