import React from 'react';

const emptyProductCell = <div className="product-row"></div>;

const OversoldInventoryWarning = ({
  isRemainingInventoryOversold = false,
  maxAmountInCart = 0,
  isMobile = false,
}) =>
  isRemainingInventoryOversold ? (
    <>
      {emptyProductCell}
      {emptyProductCell}
      {!isMobile ? emptyProductCell : null}
      <div className="regular product-row inventory-oversold-row">
        {maxAmountInCart === 0
          ? "Shoot! There's none available at this time. Please remove this from your cart."
          : `Shoot! There\'s only ${maxAmountInCart} available at this time. Please adjust the quantity.`}
      </div>
    </>
  ) : null;

export default OversoldInventoryWarning;
