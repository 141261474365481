import { css } from 'emotion';

export const storeInfo = css`
  grid-area: store-info;

  &.cart-page {
    .cart-page-container {
      margin: 32px auto 0;
      text-align: center;
      .logo {
        width: 120px;
        margin: 0 auto;
      }
      h2 {
        margin-top: 24px;
      }
    }
  }

  &:not(.cart-page) {
    margin: 0 -24px;
    h2 {
      color: var(--gray-900);
      margin-top: 24px;
    }
    a {
      &.tel,
      &.email {
        display: block;
        margin-top: 4px;
        color: var(--gray-900);
      }
      &.email {
        margin-top: 14px;
      }
    }

    .logo-social-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      .logo {
        width: 126px;
        height: 126px;
        object-fit: scale-down;
      }
      .social-container {
        flex: 1 1 auto;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        a {
          font-size: 24px;
          flex: 1 1 50%;
          color: var(--blue-700);
          text-align: center;
          &:nth-of-type(n + 3) {
            margin-top: 40px;
          }
        }
      }
    }

    .description {
      color: var(--gray-700);
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 44px;
        height: 3px;
        background: var(--gray-400);
        position: absolute;
        left: 0;
      }
    }

    @media (max-width: 1200px) {
      &:not(.product-list-page) {
        .store-info-sticky-container {
          position: fixed;
          min-width: 100vw;
        }
      }
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
      &.product-list-page {
        margin: 0;

        .store-info-sticky-container {
          position: sticky;
          top: 73px;
          z-index: 100;
          overflow: scroll;
        }
      }
    }

    @media (min-width: 1200px) {
      margin: 0;
      &.product-list-page {
        padding: 24px 24px 0;
        border-left: 1px solid var(--gray-300);
      }
    }

    @media (min-width: 1440px) {
      &.product-list-page {
        padding-right: 0;
      }
    }

    @media (min-width: 768px) and (max-width: 1439px) {
      .ant-collapse-content-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .email,
        .tel {
          flex: 1 1 100%;
        }
        .logo-social-container {
          flex: 0 1 auto;
          a {
            &:nth-of-type(even) {
              padding-left: 40px;
            }
          }
        }
        .description {
          flex: 0 1 50%;
        }
      }

      .description {
        padding-top: 24px;
        &:before {
          top: 0;
        }
      }
    }

    @media (max-width: 767px), (min-width: 1200px) {
      .description {
        padding-top: 60px;
        &:before {
          top: 30px;
        }
      }
    }

    .collapse-container {
      .collapse-header {
        .ant-collapse-header {
          line-height: 24px;
          font-size: 16px;
          font-weight: 600;
          color: var(--gray-900);
          padding: 16px 24px 12px 24px;

          @media (min-width: 1024px) {
            padding: 16px 24px 12px 24px;
          }
          @media (min-width: 1440px) {
            padding: 16px 0 12px 24px;
          }
        }
        .arrow {
          transition: 0.4s transform ease-in-out;
          &.open {
            transform: rotate(180deg);
          }
          &.closed {
            transform: rotate(0);
          }
        }
      }
    }

    .panel-header-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .icon {
        margin-left: 16px;
        font-size: 12px;
      }

      .cart-summary-container {
        margin-left: auto;
      }
    }
  }
`;
