import { is_null_or_empty, is_undefined } from '@developwithpassion/matchers_js';

import { noOp } from 'utils/core/funcy';

const invalidString = is_undefined.or(is_null_or_empty);

const eventHandlers = ({
  changeState,
  selectVendor,
  save,
  performVendorSearch = noOp,
}) => {
  let form = null;

  const onSave = async () => {
    await form.submitForm();
    if (!form.isValid) return;
  };

  const onValidate = ({ firstName, lastName, phone }) => {
    const errors = {};

    if (invalidString(firstName)) errors['firstName'] = 'First name is required';
    if (invalidString(lastName)) errors['lastName'] = 'Last name is required';
    if (invalidString(phone)) errors['phone'] = 'Phone is required';

    return errors;
  };

  const onFormInitialized = formProps => {
    form = formProps;
  };

  const onVendorSelected = id => {
    selectVendor(Number(id));
  };

  const onFilterChanged = async value => {
    if (value.length < 2) return;

    await performVendorSearch(value);
  };

  return {
    onSave,
    onFilterChanged,
    onStateChanged: changeState,
    onFormInitialized,
    onValidate,
    onVendorSelected,
    onFormSubmit: save,
  };
};

export default eventHandlers;
