import { Button, List, Modal } from 'antd';
import { GrandTotal, ListItem } from './styles';
import React from 'react';

const CartByCategory = ({ isVisible, setIsVisible, cartTotal, categoryTotals }) => (
  <Modal
    title="Total Breakdown by Category"
    closable={false}
    style={{ top: 20 }}
    bodyStyle={{ padding: '0px' }}
    visible={isVisible}
    footer={
      <Button type="primary" onClick={() => setIsVisible(false)}>
        Close
      </Button>
    }
  >
    {categoryTotals && (
      <List
        dataSource={categoryTotals}
        renderItem={(item, index) => (
          <ListItem key={index}>
            <List.Item.Meta title={item.name} />
            <div>${item?.total.toFixed(2)}</div>
          </ListItem>
        )}
      />
    )}

    <GrandTotal>
      <div>Grand Total:</div>
      <div>${cartTotal?.toFixed(2)}</div>
    </GrandTotal>
  </Modal>
);

export default CartByCategory;
