import Avo from 'analytics/avo';
import { createTrackingFunction } from 'analytics/utils';

const publishBuyerSignedIn = async ({ analytics_userid, selectedVendor, userInfo }) => {
  const { licenseNumber: licenseNum, name: licenseName } = selectedVendor;

  const { email, given_name: firstname, family_name: lastname } = userInfo;

  Avo.buyerSignedIn({
    userId_: analytics_userid,
    email,
    firstname,
    lastname,
    licenseName,
    licenseNum,
  });
};

export const buyerSignedIn = createTrackingFunction(publishBuyerSignedIn);

export const __test__ = {
  publishBuyerSignedIn,
};
