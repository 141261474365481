import Image from 'components/Image';
import { Input } from 'antd';
import { AddToCart as ModifyCartItem } from '../../../components';
import React from 'react';
import config from 'config';
import { noOp } from 'utils/core/funcy';
import { productCard } from './styles';
import { toCurrency } from 'utils/ui/mappers';

const ProductCard = ({
  isTablet = false,
  imageUrl,
  strainName,
  name,
  strainTypeName,
  productBrandName,
  totalThcCbd,
  thc,
  cbd,
  terps,
  sizeDisplayName,
  price,
  id,
  noteForProduct = '',
  maxAmountInCart,
  isRemainingInventoryOversold,
  cartQuantity,
  total,
  isCountBased,
  productStatus,
  showProductStatusFilter = false,
  textQuantityForProduct = noOp,
  changeCartQuantity = noOp,
  createLineItemNoteChangeHandler = noOp,
  createRemoveHandler = noOp,
}) => {
  const addToCartProps = {
    id,
    maxAmountInCart,
    cartQuantity,
    total,
    isCountBased,
    textQuantityForProduct,
    createRemoveHandler,
    changeCartQuantity,
  };

  return (
    <div className={productCard}>
      <div className="image-name-container">
        <Image
          src={imageUrl || 'error'}
          alt={`image of ${strainName} ${name}`}
          fallback={config.images.fallback}
        />
        <p className="semi-bold">{strainTypeName}</p>
      </div>
      <div className="strain-name-container">
        <h4 className="semi-bold">
          {strainName} {name}
        </h4>
        <p className="regular">{productBrandName}</p>
        {showProductStatusFilter && productStatus === 'Backorder' ? (
          <p className="product-status-backorder">(Backorder)</p>
        ) : null}
      </div>
      <div className="terpenes-container">
        <h5>
          {totalThcCbd ? `TOTAL: ${totalThcCbd} | ` : null}
          {thc ? `THC: ${thc} | ` : null}
          {cbd ? `CBD: ${cbd} | ` : null}
          {terps ? `TERPENES: ${terps}` : null}
        </h5>
      </div>
      <div className="add-note-container">
        <Input.TextArea
          placeholder="Add a note to your order"
          defaultValue={noteForProduct}
          onChange={createLineItemNoteChangeHandler({ id })}
        />
      </div>
      {!isTablet ? <div className="column-spacer" /> : null}
      <div className="add-to-cart-container">
        <h5 className="header">Size</h5>
        <h5 className="header">Price</h5>
        <h5 className="header">Qty</h5>
        <h5 className="header" />
        <div className="grid-header-spacer" />
        <p className="regular product-row">{sizeDisplayName}</p>
        <p className="regular product-row">{price ? toCurrency(price) : '$0.00'}</p>

        <ModifyCartItem {...addToCartProps} />

        <div className="product-row"></div>
        <div className="product-row"></div>
        {isRemainingInventoryOversold ? (
          <div className="regular product-row inventory-oversold-row">
            {`Shoot! There\'s only ${maxAmountInCart} available at this time. Please adjust the quantity.`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductCard;
