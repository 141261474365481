import Axios from 'axios';
import { authOptions } from 'utils/xhrs/authToken';
import config from 'config';

const query = `
 mutation ($details: UpdateStoreFrontUserInput!) {
  updateStoreFrontUserProfile(details: $details) {
   firstName,
   lastName,
   phone,
   state
  }
}`;

export default async ({ firstName = '', lastName = '', phone = '', state = '' } = {}) => {
  const r = await Axios.post(
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        details: { firstName, lastName, phone, state },
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );

  return r.data.data.updateStoreFrontUserProfile;
};
