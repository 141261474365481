// Generated by Avo VERSION 95.1.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "V4y90JSZXGjZutXq7tQv";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties, groupProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties) + "Group Props:" + JSON.stringify(groupProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties, "Group Props:", groupProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "jIVWRQeSvkN3NGdsvTws",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "pCk4SGvwJbFwKjPrKrQr",
    "se": (new Date()).toISOString(),
    "so": "OYCKY_yuj",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "jIVWRQeSvkN3NGdsvTws",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "pCk4SGvwJbFwKjPrKrQr",
    "se": (new Date()).toISOString(),
    "so": "OYCKY_yuj",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "pCk4SGvwJbFwKjPrKrQr",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


var SegmentStorefront20 = {
  make: function make(apiKey, options) {
    if (typeof window === 'undefined') { InternalAvoLogger.warn('window.analytics is not available in Node.js'); return; }
    var analytics = window.analytics = window.analytics || [];
    if (analytics.initialize) return;
    if (analytics.invoked) {
      if (InternalAvoLogger && InternalAvoLogger.error) {
        InternalAvoLogger.error('Segment snippet included twice.');
      }
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware'
    ];
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function(key, options){
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics._writeKey = apiKey;
    analytics.SNIPPET_VERSION = '4.13.2';

    analytics.load(apiKey, options);
  },

  /* Segment does not have revenue api, @see https://segment.com/docs/spec/track/#properties */
  logEvent: function logEvent(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined' || typeof window.analytics === 'undefined') { return; }
    window.analytics.track(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },

  setUserProperties: function setUserProperties(userProperties, integrations) {
    if (typeof window === 'undefined' || typeof window.analytics === 'undefined') { return; }
    window.analytics.identify(userProperties, Object.assign({}, {integrations: integrations}));
  },

  identify: function identify(userId, integrations) {
    if (typeof window === 'undefined' || typeof window.analytics === 'undefined') { return; }
    window.analytics.identify(userId, {}, Object.assign({}, {integrations: integrations}));
  },

  unidentify: function unidentify(integrations) {
    if (typeof window === 'undefined' || typeof window.analytics === 'undefined') { return; }
    window.analytics.identify(null, {}, Object.assign({}, {integrations: integrations}));
  },

  page: function page(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined' || typeof window.analytics === 'undefined') { return; }
    window.analytics.page(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },
};

  var Product = {
    INSIGHTS: "Insights",
    STORE_FRONT: "StoreFront",
    WHOLESALE: "Wholesale",
    RETAIL: "Retail",
  };

  function assertQuery(query, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("0_TWGeGWjT", label_ ? "query" + ": " + label_ : "query", query));
    return messages;
  }

  function assertListId(listId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("1svD83R6Pi", label_ ? "list_id" + ": " + label_ : "list_id", listId));
    return messages;
  }

  function assertStoreName(storeName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("2DDMZqbL9_", label_ ? "storeName" + ": " + label_ : "storeName", storeName));
    return messages;
  }

  function assertLastname(lastname, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("4-95CW5FP8", label_ ? "lastname" + ": " + label_ : "lastname", lastname));
    return messages;
  }

  function assertOptionalCurrency(currency, label_) {
    var messages = [];
    if (currency !== undefined && currency !== null) {
      messages = messages.concat(AvoAssert.assertString("8C1AmA8xbr", label_ ? "currency" + ": " + label_ : "currency", currency));
    }
    return messages;
  }

  function assertUrl(url, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("8LanTQ6ibA", label_ ? "url" + ": " + label_ : "url", url));
    return messages;
  }

  function assertPrice(price, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("9yj8TdMlUA", label_ ? "price" + ": " + label_ : "price", price));
    return messages;
  }

  function assertProductId(productId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("If-1Z8Grvv", label_ ? "product_id" + ": " + label_ : "product_id", productId));
    return messages;
  }

  function assertOptionalState(state, label_) {
    var messages = [];
    if (state !== undefined && state !== null) {
      messages = messages.concat(AvoAssert.assertString("JUjt2QXfnn", label_ ? "state" + ": " + label_ : "state", state));
    }
    return messages;
  }

  function assertEmail(email, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("K9C1Vpi8Sz", label_ ? "email" + ": " + label_ : "email", email));
    return messages;
  }

  function assertOptionalCurrentAccountLink(currentAccountLink, label_) {
    var messages = [];
    if (currentAccountLink !== undefined && currentAccountLink !== null) {
      messages = messages.concat(AvoAssert.assertString("NFCpIq2hSr", label_ ? "Current_Account_Link" + ": " + label_ : "Current_Account_Link", currentAccountLink));
    }
    return messages;
  }

  function assertCoupon(coupon, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("O10jmXy40T", label_ ? "coupon" + ": " + label_ : "coupon", coupon));
    return messages;
  }

  function assertLicenseName(licenseName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("OpJU5z-Emf", label_ ? "License_Name" + ": " + label_ : "License_Name", licenseName));
    return messages;
  }

  function assertLicenseNum(licenseNum, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("PFqJ9v-T9U", label_ ? "License_Num" + ": " + label_ : "License_Num", licenseNum));
    return messages;
  }

  function assertBrand(brand, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("Qrvdv4WlVz", label_ ? "brand" + ": " + label_ : "brand", brand));
    return messages;
  }

  function assertRevenue(revenue, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("Rl996LjTXp", label_ ? "revenue" + ": " + label_ : "revenue", revenue));
    return messages;
  }

  function assertName(name, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("Vc9lJW_Xmm", label_ ? "name" + ": " + label_ : "name", name));
    return messages;
  }

  function assertProducts(products, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertObject("a2he0hnDWs", label_ ? "products" + ": " + label_ : "products", products));
    return messages;
  }

  function assertVariant(variant, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("ap4zTwxfWM", label_ ? "variant" + ": " + label_ : "variant", variant));
    return messages;
  }

  function assertUserId_(userId_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-user-id", label_ ? "User Id" + ": " + label_ : "User Id", userId_));
    return messages;
  }

  function assertTax(tax, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("cEpZLK1TJ6", label_ ? "tax" + ": " + label_ : "tax", tax));
    return messages;
  }

  function assertTotal(total, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("cOOdhX79b5", label_ ? "total" + ": " + label_ : "total", total));
    return messages;
  }

  function assertCategory(category, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("e2omWXHxtc", label_ ? "category" + ": " + label_ : "category", category));
    return messages;
  }

  function assertFirstname(firstname, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("fGfT1RnU3a", label_ ? "firstname" + ": " + label_ : "firstname", firstname));
    return messages;
  }

  function assertSku(sku, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("fWv0OHavBx", label_ ? "sku" + ": " + label_ : "sku", sku));
    return messages;
  }

  function assertAffiliation(affiliation, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("fnJX95RIPR", label_ ? "affiliation" + ": " + label_ : "affiliation", affiliation));
    return messages;
  }

  function assertStoreId(storeId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("ijSSbc0fI_", label_ ? "storeId" + ": " + label_ : "storeId", storeId));
    return messages;
  }

  function assertDiscount(discount, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("m7S98D2upf", label_ ? "discount" + ": " + label_ : "discount", discount));
    return messages;
  }

  function assertFilters(filters, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertObject("mUewHj2oCa", label_ ? "filters" + ": " + label_ : "filters", filters));
    return messages;
  }

  function assertCartId(cartId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("o0QBO85DV4", label_ ? "cart_id" + ": " + label_ : "cart_id", cartId));
    return messages;
  }

  function assertOptionalProduct(product, label_) {
    var messages = [];
    if (product !== undefined && product !== null) {
      messages = messages.concat(AvoAssert.assertString("oLVGheLfwh", label_ ? "Product" + ": " + label_ : "Product", product));
      if ("Insights" !== product && "StoreFront" !== product &&
            "Wholesale" !== product && "Retail" !== product) {
        var message = (label_ ? "Product" + ": " + label_ : "Product") + " should match one of the following values [ Insights | StoreFront | Wholesale | Retail ] but you provided the value " + product
        messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "oLVGheLfwh", message: message}]);
      }
    }
    return messages;
  }

  function assertQuantity(quantity, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("pMgXLwDjPG", label_ ? "quantity" + ": " + label_ : "quantity", quantity));
    return messages;
  }

  function assertOrderId(orderId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("pdaHFOjMzf", label_ ? "order_id" + ": " + label_ : "order_id", orderId));
    return messages;
  }

  function assertShipping(shipping, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertInt("sjMkxqAbJa", label_ ? "shipping" + ": " + label_ : "shipping", shipping));
    return messages;
  }

  function assertOptionalPosition(position, label_) {
    var messages = [];
    if (position !== undefined && position !== null) {
      messages = messages.concat(AvoAssert.assertInt("t8y_7F1sfD", label_ ? "position" + ": " + label_ : "position", position));
    }
    return messages;
  }

  function assertImageUrl(imageUrl, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("wdJGgKYHSz", label_ ? "image_url" + ": " + label_ : "image_url", imageUrl));
    return messages;
  }

  function assertOptionalValue(value, label_) {
    var messages = [];
    if (value !== undefined && value !== null) {
      messages = messages.concat(AvoAssert.assertInt("ySQL1mya_z", label_ ? "value" + ": " + label_ : "value", value));
    }
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }

  function setup_(options, systemProperties, destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
        SegmentStorefront20.make(
          "IjNCXPz5omkQCl8xC25IuEp3SpIR6uRQ",
          destinationOptions.segmentStorefront20
        );
      } else {
        SegmentStorefront20.make(
          "XGwBzC5s2PKcOcdxNqPhQRF3px6KIKdu",
          destinationOptions.segmentStorefront20
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
        SegmentStorefront20.make(
          "IjNCXPz5omkQCl8xC25IuEp3SpIR6uRQ",
          destinationOptions.segmentStorefront20
        );
      }
      if (__AVO_ENV__ === 'dev') {
        SegmentStorefront20.make(
          "XGwBzC5s2PKcOcdxNqPhQRF3px6KIKdu",
          destinationOptions.segmentStorefront20
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * Signed In: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/xMrh-oMmKI}
   */
  function signedIn(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Signed In", Object.keys(properties), [
        "userId_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "xMrh-oMmKI", "0d911491c9fafe9bc3f2d65d789ba0c4182b5e8714fe5a2350b2eb03cb48d2c4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Signed In", {}, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("xMrh-oMmKI", "Signed In", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Signed In': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Signed In", {}, "xMrh-oMmKI", "0d911491c9fafe9bc3f2d65d789ba0c4182b5e8714fe5a2350b2eb03cb48d2c4");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.identify(properties.userId_, {});
      SegmentStorefront20.logEvent("Signed In", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Product Viewed: Product  viewed
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.productId - no description
   * @param {string} properties.sku - no description
   * @param {string} properties.category - no description
   * @param {string} properties.name - no description
   * @param {string} properties.brand - no description
   * @param {string} properties.variant - no description
   * @param {int} properties.price - no description
   * @param {int} properties.quantity - no description
   * @param {string} properties.url - no description
   * @param {string} properties.imageUrl - no description
   * @param {string} properties.currency - no description
   * @param {int} properties.position - no description
   * @param {int} properties.value - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/u8OCFCsSN-}
   */
  function productViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertProductId(properties.productId));
      messages = messages.concat(assertSku(properties.sku));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertName(properties.name));
      messages = messages.concat(assertBrand(properties.brand));
      messages = messages.concat(assertVariant(properties.variant));
      messages = messages.concat(assertPrice(properties.price));
      messages = messages.concat(assertQuantity(properties.quantity));
      messages = messages.concat(assertUrl(properties.url));
      messages = messages.concat(assertImageUrl(properties.imageUrl));
      messages = messages.concat(assertOptionalCurrency(properties.currency));
      messages = messages.concat(assertOptionalPosition(properties.position));
      messages = messages.concat(assertOptionalValue(properties.value));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Product Viewed", Object.keys(properties), [
        "productId",
        "sku",
        "category",
        "name",
        "brand",
        "variant",
        "price",
        "quantity",
        "url",
        "imageUrl",
        "currency",
        "position",
        "value",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "u8OCFCsSN-", "c374aba3579bcc05df7198bb5dff2f2ce4e5fb0f1aa101bf6b4b3a4ba3a7a799", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Product Viewed", {
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "currency": properties.currency,
        "position": properties.position,
        "value": properties.value,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("u8OCFCsSN-", "Product Viewed", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8C1AmA8xbr", name: "currency", value: properties.currency},
        {id: "8LanTQ6ibA", name: "url", value: properties.url},
        {id: "9yj8TdMlUA", name: "price", value: properties.price},
        {id: "If-1Z8Grvv", name: "product_id", value: properties.productId},
        {id: "Qrvdv4WlVz", name: "brand", value: properties.brand},
        {id: "Vc9lJW_Xmm", name: "name", value: properties.name},
        {id: "ap4zTwxfWM", name: "variant", value: properties.variant},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        {id: "fWv0OHavBx", name: "sku", value: properties.sku},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "pMgXLwDjPG", name: "quantity", value: properties.quantity},
        {id: "t8y_7F1sfD", name: "position", value: properties.position},
        {id: "wdJGgKYHSz", name: "image_url", value: properties.imageUrl},
        {id: "ySQL1mya_z", name: "value", value: properties.value},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Product Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Viewed", {
          "product_id": properties.productId,
          "sku": properties.sku,
          "category": properties.category,
          "name": properties.name,
          "brand": properties.brand,
          "variant": properties.variant,
          "price": properties.price,
          "quantity": properties.quantity,
          "url": properties.url,
          "image_url": properties.imageUrl,
          "currency": properties.currency,
          "position": properties.position,
          "value": properties.value,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "u8OCFCsSN-", "c374aba3579bcc05df7198bb5dff2f2ce4e5fb0f1aa101bf6b4b3a4ba3a7a799");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Product Viewed", {
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "currency": properties.currency,
        "position": properties.position,
        "value": properties.value,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Product Clicked: Product Clicked
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.productId - no description
   * @param {string} properties.sku - no description
   * @param {string} properties.category - no description
   * @param {string} properties.name - no description
   * @param {int} properties.quantity - no description
   * @param {string} properties.brand - no description
   * @param {string} properties.variant - no description
   * @param {int} properties.price - no description
   * @param {string} properties.coupon - no description
   * @param {int} properties.position - no description
   * @param {string} properties.url - no description
   * @param {string} properties.imageUrl - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/dTuf3eQFSk}
   */
  function productClicked(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertProductId(properties.productId));
      messages = messages.concat(assertSku(properties.sku));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertName(properties.name));
      messages = messages.concat(assertQuantity(properties.quantity));
      messages = messages.concat(assertBrand(properties.brand));
      messages = messages.concat(assertVariant(properties.variant));
      messages = messages.concat(assertPrice(properties.price));
      messages = messages.concat(assertCoupon(properties.coupon));
      messages = messages.concat(assertOptionalPosition(properties.position));
      messages = messages.concat(assertUrl(properties.url));
      messages = messages.concat(assertImageUrl(properties.imageUrl));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Product Clicked", Object.keys(properties), [
        "productId",
        "sku",
        "category",
        "name",
        "quantity",
        "brand",
        "variant",
        "price",
        "coupon",
        "position",
        "url",
        "imageUrl",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "dTuf3eQFSk", "a16202bd75da197f15577282d439f135d6c8d3e341802b0e88d31358d0678ebe", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Product Clicked", {
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "quantity": properties.quantity,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("dTuf3eQFSk", "Product Clicked", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8LanTQ6ibA", name: "url", value: properties.url},
        {id: "9yj8TdMlUA", name: "price", value: properties.price},
        {id: "If-1Z8Grvv", name: "product_id", value: properties.productId},
        {id: "O10jmXy40T", name: "coupon", value: properties.coupon},
        {id: "Qrvdv4WlVz", name: "brand", value: properties.brand},
        {id: "Vc9lJW_Xmm", name: "name", value: properties.name},
        {id: "ap4zTwxfWM", name: "variant", value: properties.variant},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        {id: "fWv0OHavBx", name: "sku", value: properties.sku},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "pMgXLwDjPG", name: "quantity", value: properties.quantity},
        {id: "t8y_7F1sfD", name: "position", value: properties.position},
        {id: "wdJGgKYHSz", name: "image_url", value: properties.imageUrl},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Product Clicked': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Clicked", {
          "product_id": properties.productId,
          "sku": properties.sku,
          "category": properties.category,
          "name": properties.name,
          "quantity": properties.quantity,
          "brand": properties.brand,
          "variant": properties.variant,
          "price": properties.price,
          "coupon": properties.coupon,
          "position": properties.position,
          "url": properties.url,
          "image_url": properties.imageUrl,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "dTuf3eQFSk", "a16202bd75da197f15577282d439f135d6c8d3e341802b0e88d31358d0678ebe");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Product Clicked", {
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "quantity": properties.quantity,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Product Added: Product added to cart
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.cartId - no description
   * @param {string} properties.productId - no description
   * @param {string} properties.sku - no description
   * @param {string} properties.category - no description
   * @param {string} properties.name - no description
   * @param {string} properties.brand - no description
   * @param {string} properties.variant - no description
   * @param {int} properties.price - no description
   * @param {int} properties.quantity - no description
   * @param {string} properties.coupon - no description
   * @param {int} properties.position - no description
   * @param {string} properties.url - no description
   * @param {string} properties.imageUrl - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/FikLiXH5IP}
   */
  function productAdded(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertCartId(properties.cartId));
      messages = messages.concat(assertProductId(properties.productId));
      messages = messages.concat(assertSku(properties.sku));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertName(properties.name));
      messages = messages.concat(assertBrand(properties.brand));
      messages = messages.concat(assertVariant(properties.variant));
      messages = messages.concat(assertPrice(properties.price));
      messages = messages.concat(assertQuantity(properties.quantity));
      messages = messages.concat(assertCoupon(properties.coupon));
      messages = messages.concat(assertOptionalPosition(properties.position));
      messages = messages.concat(assertUrl(properties.url));
      messages = messages.concat(assertImageUrl(properties.imageUrl));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Product Added", Object.keys(properties), [
        "cartId",
        "productId",
        "sku",
        "category",
        "name",
        "brand",
        "variant",
        "price",
        "quantity",
        "coupon",
        "position",
        "url",
        "imageUrl",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FikLiXH5IP", "3e76870d5a11d3a52dde2e0d775949ded10b7ccc10c71189c5c880e7ec9e50a2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Product Added", {
        "cart_id": properties.cartId,
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FikLiXH5IP", "Product Added", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8LanTQ6ibA", name: "url", value: properties.url},
        {id: "9yj8TdMlUA", name: "price", value: properties.price},
        {id: "If-1Z8Grvv", name: "product_id", value: properties.productId},
        {id: "O10jmXy40T", name: "coupon", value: properties.coupon},
        {id: "Qrvdv4WlVz", name: "brand", value: properties.brand},
        {id: "Vc9lJW_Xmm", name: "name", value: properties.name},
        {id: "ap4zTwxfWM", name: "variant", value: properties.variant},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        {id: "fWv0OHavBx", name: "sku", value: properties.sku},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "o0QBO85DV4", name: "cart_id", value: properties.cartId},
        {id: "pMgXLwDjPG", name: "quantity", value: properties.quantity},
        {id: "t8y_7F1sfD", name: "position", value: properties.position},
        {id: "wdJGgKYHSz", name: "image_url", value: properties.imageUrl},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Product Added': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added", {
          "cart_id": properties.cartId,
          "product_id": properties.productId,
          "sku": properties.sku,
          "category": properties.category,
          "name": properties.name,
          "brand": properties.brand,
          "variant": properties.variant,
          "price": properties.price,
          "quantity": properties.quantity,
          "coupon": properties.coupon,
          "position": properties.position,
          "url": properties.url,
          "image_url": properties.imageUrl,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "FikLiXH5IP", "3e76870d5a11d3a52dde2e0d775949ded10b7ccc10c71189c5c880e7ec9e50a2");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Product Added", {
        "cart_id": properties.cartId,
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Product Removed: Product  removed from cart
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.cartId - no description
   * @param {string} properties.productId - no description
   * @param {string} properties.sku - no description
   * @param {string} properties.category - no description
   * @param {string} properties.name - no description
   * @param {string} properties.brand - no description
   * @param {string} properties.variant - no description
   * @param {int} properties.price - no description
   * @param {int} properties.quantity - no description
   * @param {string} properties.coupon - no description
   * @param {int} properties.position - no description
   * @param {string} properties.url - no description
   * @param {string} properties.imageUrl - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/svqiuocE2M}
   */
  function productRemoved(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertCartId(properties.cartId));
      messages = messages.concat(assertProductId(properties.productId));
      messages = messages.concat(assertSku(properties.sku));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertName(properties.name));
      messages = messages.concat(assertBrand(properties.brand));
      messages = messages.concat(assertVariant(properties.variant));
      messages = messages.concat(assertPrice(properties.price));
      messages = messages.concat(assertQuantity(properties.quantity));
      messages = messages.concat(assertCoupon(properties.coupon));
      messages = messages.concat(assertOptionalPosition(properties.position));
      messages = messages.concat(assertUrl(properties.url));
      messages = messages.concat(assertImageUrl(properties.imageUrl));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Product Removed", Object.keys(properties), [
        "cartId",
        "productId",
        "sku",
        "category",
        "name",
        "brand",
        "variant",
        "price",
        "quantity",
        "coupon",
        "position",
        "url",
        "imageUrl",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "svqiuocE2M", "cff520940c535b611c3fcd5c530afd154db40a4aae39f16b98a628b4c439ae3b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Product Removed", {
        "cart_id": properties.cartId,
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("svqiuocE2M", "Product Removed", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8LanTQ6ibA", name: "url", value: properties.url},
        {id: "9yj8TdMlUA", name: "price", value: properties.price},
        {id: "If-1Z8Grvv", name: "product_id", value: properties.productId},
        {id: "O10jmXy40T", name: "coupon", value: properties.coupon},
        {id: "Qrvdv4WlVz", name: "brand", value: properties.brand},
        {id: "Vc9lJW_Xmm", name: "name", value: properties.name},
        {id: "ap4zTwxfWM", name: "variant", value: properties.variant},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        {id: "fWv0OHavBx", name: "sku", value: properties.sku},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "o0QBO85DV4", name: "cart_id", value: properties.cartId},
        {id: "pMgXLwDjPG", name: "quantity", value: properties.quantity},
        {id: "t8y_7F1sfD", name: "position", value: properties.position},
        {id: "wdJGgKYHSz", name: "image_url", value: properties.imageUrl},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Product Removed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Removed", {
          "cart_id": properties.cartId,
          "product_id": properties.productId,
          "sku": properties.sku,
          "category": properties.category,
          "name": properties.name,
          "brand": properties.brand,
          "variant": properties.variant,
          "price": properties.price,
          "quantity": properties.quantity,
          "coupon": properties.coupon,
          "position": properties.position,
          "url": properties.url,
          "image_url": properties.imageUrl,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "svqiuocE2M", "cff520940c535b611c3fcd5c530afd154db40a4aae39f16b98a628b4c439ae3b");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Product Removed", {
        "cart_id": properties.cartId,
        "product_id": properties.productId,
        "sku": properties.sku,
        "category": properties.category,
        "name": properties.name,
        "brand": properties.brand,
        "variant": properties.variant,
        "price": properties.price,
        "quantity": properties.quantity,
        "coupon": properties.coupon,
        "position": properties.position,
        "url": properties.url,
        "image_url": properties.imageUrl,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Cart Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.cartId - no description
   * @param {object} properties.products - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   * @param {string} properties.currency - no description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/C4bdBdPRSq}
   */
  function cartViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertCartId(properties.cartId));
      messages = messages.concat(assertProducts(properties.products));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(assertOptionalCurrency(properties.currency));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Cart Viewed", Object.keys(properties), [
        "cartId",
        "products",
        "storeId",
        "storeName",
        "currency"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "C4bdBdPRSq", "adae56eccdadb6e31a15c00155d1686207bf080822c3994fa454d4b7f6f7b543", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Cart Viewed", {
        "cart_id": properties.cartId,
        "products": properties.products,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        "currency": properties.currency,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("C4bdBdPRSq", "Cart Viewed", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8C1AmA8xbr", name: "currency", value: properties.currency},
        {id: "a2he0hnDWs", name: "products", value: properties.products},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "o0QBO85DV4", name: "cart_id", value: properties.cartId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Cart Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Cart Viewed", {
          "cart_id": properties.cartId,
          "products": properties.products,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          "currency": properties.currency,
          }, "C4bdBdPRSq", "adae56eccdadb6e31a15c00155d1686207bf080822c3994fa454d4b7f6f7b543");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Cart Viewed", {
        "cart_id": properties.cartId,
        "products": properties.products,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        "currency": properties.currency,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order Created: Order created
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.orderId - no description
   * @param {string} properties.affiliation - no description
   * @param {int} properties.total - no description
   * @param {int} properties.revenue - no description
   * @param {int} properties.shipping - no description
   * @param {int} properties.tax - no description
   * @param {int} properties.discount - no description
   * @param {string} properties.coupon - no description
   * @param {string} properties.currency - no description
   * @param {object} properties.products - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   * @param {string} properties.state - State the Company does business in.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/k-IZv1f49t}
   */
  function orderCreated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOrderId(properties.orderId));
      messages = messages.concat(assertAffiliation(properties.affiliation));
      messages = messages.concat(assertTotal(properties.total));
      messages = messages.concat(assertRevenue(properties.revenue));
      messages = messages.concat(assertShipping(properties.shipping));
      messages = messages.concat(assertTax(properties.tax));
      messages = messages.concat(assertDiscount(properties.discount));
      messages = messages.concat(assertCoupon(properties.coupon));
      messages = messages.concat(assertOptionalCurrency(properties.currency));
      messages = messages.concat(assertProducts(properties.products));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(assertOptionalState(properties.state));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order Created", Object.keys(properties), [
        "orderId",
        "affiliation",
        "total",
        "revenue",
        "shipping",
        "tax",
        "discount",
        "coupon",
        "currency",
        "products",
        "storeId",
        "storeName",
        "state"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "k-IZv1f49t", "f5b68f4073150a312153469d532f5733b0f7f54567cb35e21b25396d81b5b36c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order Created", {
        "order_id": properties.orderId,
        "affiliation": properties.affiliation,
        "total": properties.total,
        "revenue": properties.revenue,
        "shipping": properties.shipping,
        "tax": properties.tax,
        "discount": properties.discount,
        "coupon": properties.coupon,
        "currency": properties.currency,
        "products": properties.products,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        "state": properties.state,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("k-IZv1f49t", "Order Created", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "8C1AmA8xbr", name: "currency", value: properties.currency},
        {id: "JUjt2QXfnn", name: "state", value: properties.state},
        {id: "O10jmXy40T", name: "coupon", value: properties.coupon},
        {id: "Rl996LjTXp", name: "revenue", value: properties.revenue},
        {id: "a2he0hnDWs", name: "products", value: properties.products},
        {id: "cEpZLK1TJ6", name: "tax", value: properties.tax},
        {id: "cOOdhX79b5", name: "total", value: properties.total},
        {id: "fnJX95RIPR", name: "affiliation", value: properties.affiliation},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "m7S98D2upf", name: "discount", value: properties.discount},
        {id: "pdaHFOjMzf", name: "order_id", value: properties.orderId},
        {id: "sjMkxqAbJa", name: "shipping", value: properties.shipping},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order Created': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Created", {
          "order_id": properties.orderId,
          "affiliation": properties.affiliation,
          "total": properties.total,
          "revenue": properties.revenue,
          "shipping": properties.shipping,
          "tax": properties.tax,
          "discount": properties.discount,
          "coupon": properties.coupon,
          "currency": properties.currency,
          "products": properties.products,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          "state": properties.state,
          }, "k-IZv1f49t", "f5b68f4073150a312153469d532f5733b0f7f54567cb35e21b25396d81b5b36c");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Order Created", {
        "order_id": properties.orderId,
        "affiliation": properties.affiliation,
        "total": properties.total,
        "revenue": properties.revenue,
        "shipping": properties.shipping,
        "tax": properties.tax,
        "discount": properties.discount,
        "coupon": properties.coupon,
        "currency": properties.currency,
        "products": properties.products,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        "state": properties.state,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Product List Filtered: Product list filtered
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.listId - no description
   * @param {string} properties.category - no description
   * @param {object} properties.filters - no description
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/HkUnq3Y4Ok}
   */
  function productListFiltered(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertListId(properties.listId));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertFilters(properties.filters));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Product List Filtered", Object.keys(properties), [
        "listId",
        "category",
        "filters",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "HkUnq3Y4Ok", "21ead3f8ea0f09539f9ce5340da842fd310b589a90a931ba1d72ba31a30e4ffb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Product List Filtered", {
        "list_id": properties.listId,
        "category": properties.category,
        "filters": properties.filters,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("HkUnq3Y4Ok", "Product List Filtered", messages, [
        {id: "1svD83R6Pi", name: "list_id", value: properties.listId},
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        {id: "mUewHj2oCa", name: "filters", value: properties.filters},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Product List Filtered': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Filtered", {
          "list_id": properties.listId,
          "category": properties.category,
          "filters": properties.filters,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "HkUnq3Y4Ok", "21ead3f8ea0f09539f9ce5340da842fd310b589a90a931ba1d72ba31a30e4ffb");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Product List Filtered", {
        "list_id": properties.listId,
        "category": properties.category,
        "filters": properties.filters,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Buyer Signed In: Event sent when user selects a Buyer License from StoreFront
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   * @param {string} properties.email - Email Address of User/Contact
   * @param {string} properties.firstname - User's First Name
   * @param {string} properties.lastname - User's Family Name
   * @param {string} properties.licenseName - License Name
   * @param {string} properties.licenseNum - License # the user
   * @param {string} properties.currentAccountLink - The canonical "entrance url" (i.e. the link that someone would need to navigate to in order to view the app as the current user sees it.
   * @param {string} properties.product - The current GrowFlow Product being used.
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/a7AbC8xvRx}
   */
  function buyerSignedIn(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(assertEmail(properties.email));
      messages = messages.concat(assertFirstname(properties.firstname));
      messages = messages.concat(assertLastname(properties.lastname));
      messages = messages.concat(assertLicenseName(properties.licenseName));
      messages = messages.concat(assertLicenseNum(properties.licenseNum));
      messages = messages.concat(assertOptionalCurrentAccountLink(properties.currentAccountLink));
      messages = messages.concat(assertOptionalProduct(properties.product));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Buyer Signed In", Object.keys(properties), [
        "userId_",
        "email",
        "firstname",
        "lastname",
        "licenseName",
        "licenseNum",
        "currentAccountLink",
        "product"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "a7AbC8xvRx", "f5fef9f96d606cbb47c0c806261f1e6b2c5fddb6a089ed340e154fb9fedad5be", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Buyer Signed In", {}, {
        "email": properties.email,
        "firstname": properties.firstname,
        "lastname": properties.lastname,
        "License_Name": properties.licenseName,
        "License_Num": properties.licenseNum,
        "Current_Account_Link": properties.currentAccountLink,
        "Product": properties.product,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("a7AbC8xvRx", "Buyer Signed In", messages, [], [
        {id:"4-95CW5FP8", name: "lastname", value: properties.lastname},
        {id:"K9C1Vpi8Sz", name: "email", value: properties.email},
        {id:"NFCpIq2hSr", name: "Current_Account_Link", value: properties.currentAccountLink},
        {id:"OpJU5z-Emf", name: "License_Name", value: properties.licenseName},
        {id:"PFqJ9v-T9U", name: "License_Num", value: properties.licenseNum},
        {id:"fGfT1RnU3a", name: "firstname", value: properties.firstname},
        {id:"oLVGheLfwh", name: "Product", value: properties.product},
        ]);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Buyer Signed In': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Buyer Signed In", {}, "a7AbC8xvRx", "f5fef9f96d606cbb47c0c806261f1e6b2c5fddb6a089ed340e154fb9fedad5be");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.identify(properties.userId_, {});
      SegmentStorefront20.setUserProperties({
        "email": properties.email,
        "firstname": properties.firstname,
        "lastname": properties.lastname,
        "License_Name": properties.licenseName,
        "License_Num": properties.licenseNum,
        "Current_Account_Link": properties.currentAccountLink,
        "Product": properties.product,
        }, {});
      SegmentStorefront20.logEvent("Buyer Signed In", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Store Selected: User selected a specific storefront after Signing In
   *
   * @param {object} properties - the properties associatied with this event
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/UJkLpRZapS}
   */
  function storeSelected(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Store Selected", Object.keys(properties), [
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "UJkLpRZapS", "329183801bdae67ea036ea814edd5aff569d74ea1293644d6637793d1e7cdc24", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Store Selected", {
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("UJkLpRZapS", "Store Selected", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Store Selected': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Store Selected", {
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "UJkLpRZapS", "329183801bdae67ea036ea814edd5aff569d74ea1293644d6637793d1e7cdc24");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Store Selected", {
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Products Searched: User freestyle text search of Products within a Store
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.query - Query searched
   * @param {int} properties.storeId - Database Id for the Store
   * @param {string} properties.storeName - Name of the Store
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/FKa-ydsgaI}
   */
  function productsSearched(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertQuery(properties.query));
      messages = messages.concat(assertStoreId(properties.storeId));
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Products Searched", Object.keys(properties), [
        "query",
        "storeId",
        "storeName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FKa-ydsgaI", "6265910c01891623f8d82f850195c1753f530ae2be96b19435c29f6eadab19a6", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Products Searched", {
        "query": properties.query,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FKa-ydsgaI", "Products Searched", messages, [
        {id: "0_TWGeGWjT", name: "query", value: properties.query},
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "ijSSbc0fI_", name: "storeId", value: properties.storeId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Products Searched': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Products Searched", {
          "query": properties.query,
          "storeId": properties.storeId,
          "storeName": properties.storeName,
          }, "FKa-ydsgaI", "6265910c01891623f8d82f850195c1753f530ae2be96b19435c29f6eadab19a6");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Products Searched", {
        "query": properties.query,
        "storeId": properties.storeId,
        "storeName": properties.storeName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Store Viewed: A Buyer Selected a Store and the Sellable products were viewed
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.storeName - Name of the Store
   * @param {string} properties.productId - no description
   * @param {string} properties.name - no description
   * @param {string} properties.category - no description
   * @param {string} properties.brand - no description
   * @param {object} properties.products - no description
   *
   * @see {@link https://www.avo.app/schemas/pCk4SGvwJbFwKjPrKrQr/events/Uy5WZx8buE}
   */
  function storeViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertStoreName(properties.storeName));
      messages = messages.concat(assertProductId(properties.productId));
      messages = messages.concat(assertName(properties.name));
      messages = messages.concat(assertCategory(properties.category));
      messages = messages.concat(assertBrand(properties.brand));
      messages = messages.concat(assertProducts(properties.products));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Store Viewed", Object.keys(properties), [
        "storeName",
        "productId",
        "name",
        "category",
        "brand",
        "products"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Uy5WZx8buE", "bba2666b8b07af3a5943c2091819ff7e181e7c1c5d7e089c48ba90713223b0fb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Store Viewed", {
        "storeName": properties.storeName,
        "product_id": properties.productId,
        "name": properties.name,
        "category": properties.category,
        "brand": properties.brand,
        "products": properties.products,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Uy5WZx8buE", "Store Viewed", messages, [
        {id: "2DDMZqbL9_", name: "storeName", value: properties.storeName},
        {id: "If-1Z8Grvv", name: "product_id", value: properties.productId},
        {id: "Qrvdv4WlVz", name: "brand", value: properties.brand},
        {id: "Vc9lJW_Xmm", name: "name", value: properties.name},
        {id: "a2he0hnDWs", name: "products", value: properties.products},
        {id: "e2omWXHxtc", name: "category", value: properties.category},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Store Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Store Viewed", {
          "storeName": properties.storeName,
          "product_id": properties.productId,
          "name": properties.name,
          "category": properties.category,
          "brand": properties.brand,
          "products": properties.products,
          }, "Uy5WZx8buE", "bba2666b8b07af3a5943c2091819ff7e181e7c1c5d7e089c48ba90713223b0fb");
      }
      // destination SegmentStorefront20
      SegmentStorefront20.logEvent("Store Viewed", {
        "storeName": properties.storeName,
        "product_id": properties.productId,
        "name": properties.name,
        "category": properties.category,
        "brand": properties.brand,
        "products": properties.products,
        }, {});
    } else {
      // do nothing
    }
  }

  exports.Product = Product;
  exports.setAvoLogger = setAvoLogger;
  exports.signedIn = signedIn;
  exports.productViewed = productViewed;
  exports.productClicked = productClicked;
  exports.productAdded = productAdded;
  exports.productRemoved = productRemoved;
  exports.cartViewed = cartViewed;
  exports.orderCreated = orderCreated;
  exports.productListFiltered = productListFiltered;
  exports.buyerSignedIn = buyerSignedIn;
  exports.storeSelected = storeSelected;
  exports.productsSearched = productsSearched;
  exports.storeViewed = storeViewed;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["signedIn","productViewed","productClicked","productAdded","productRemoved","cartViewed","orderCreated","productListFiltered","buyerSignedIn","storeSelected","productsSearched","storeViewed"]
