import { hocWithMappings as hoc } from 'utils/views';
import useMedia from 'utils/hooks/useMedia';

const defaultMobileMaxWidth = 768;
const defaultDesktopMinWidth = 1200;
const defaultTabletMaxWidth = 1023;

export const resolutionNames = {
  mobile: 'mobile',
  tabletAndBelow: 'tabletAndBelow',
  betweenTabletAndDesktop: 'betweenTabletAndDesktop',
  desktop: 'desktop',
};

const breakpointDetection = ({
  mobileMaxWidth = defaultMobileMaxWidth,
  desktopMinWidth = defaultDesktopMinWidth,
  tabletMaxWidth = defaultTabletMaxWidth,
} = {}) => () => {
  const isMobile = useMedia(`(max-width: ${mobileMaxWidth}px)`);
  const isDesktop = useMedia(`(min-width: ${desktopMinWidth}px)`);
  const isTabletAndBelow = useMedia(`(max-width: ${tabletMaxWidth}px)`);
  const isBetweenTabletAndDesktop = !(isMobile || isTabletAndBelow || isDesktop);

  const resolutionRanges = [
    { isActive: isMobile, name: resolutionNames.mobile },
    { isActive: isTabletAndBelow, name: resolutionNames.tabletAndBelow },
    {
      isActive: isBetweenTabletAndDesktop,
      name: resolutionNames.betweenTabletAndDesktop,
    },
    { isActive: isDesktop, name: resolutionNames.desktop },
  ];

  const activeResolutionRange = resolutionRanges.find(({ isActive }) => isActive);

  return {
    isMobile,
    isDesktop,
    isTabletAndBelow,
    isBetweenTabletAndDesktop,
    activeResolutionRange,
  };
};

export const withBreakpointDetection = (breakpoints = {}) =>
  hoc('Breakpoint Detection', breakpointDetection(breakpoints));
