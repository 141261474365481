import React, { useEffect } from 'react';
import { noOp } from 'utils/core/funcy';

export const withInit = Template => {
  let initialized = false;

  const Component = props => {
    useEffect(() => {
      const init = props.init || noOp;

      (async () => {
        if (initialized) return;
        initialized = true;
        await init();
      })();
    }, []);

    return <Template {...props} />;
  };

  return Component;
};
