import { or_criteria as any, criteria as where } from '@developwithpassion/match_js';
import { is_null_or_undefined, not } from '@developwithpassion/matchers_js';
import { nonEmptyString } from 'utils/matchers/strings';

export const validStringValue = not(is_null_or_undefined).and(nonEmptyString);

export const createImage = (originalUrl, url) => ({
  url,
  originalUrl,
  largeUrl: validStringValue(originalUrl) ? originalUrl : url,
});

export const isValidImage = any({
  url: validStringValue,
  originalUrl: validStringValue,
});

export const missingParentCategory = {
  id: 'na-parent-category',
  name: 'N/A',
};

export const missingCategory = {
  id: 'na-category',
  name: 'N/A',
};

export const isSourced = where({
  bulkGramsAvailable: validStringValue,
  sourcedProductId: validStringValue,
});
