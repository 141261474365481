import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import {
  listingsMap,
  productWithFullDetails,
  store,
  storeFront,
  storeFrontId,
  textQuantityForProduct,
} from '../shared/viewModel';
import { createViewModel as baseViews } from '../viewModel';
import { fileAbsolute } from 'paths.macro';
import { identity } from 'utils/core/funcy';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

export const listingId = viewModelAttribute(
  'listingId',
  ({ store: { listingId } }) => listingId,
  identity
);

const listingDetail = viewModelAttribute(
  'listingDetail',
  listingId,
  listingsMap,
  store,
  (id, map) => map[id] || {}
);

const productId = viewModelAttribute(
  'productDetail',
  listingDetail,
  ({ products: [{ id = 0 } = {}] = [] } = {}) => id
);

const productDetail = viewModelAttribute(
  'productDetailImp',
  productId,
  productWithFullDetails,
  (productId, productWithFullDetails) =>
    productId !== 0 ? productWithFullDetails(productId) : {}
);

const accessors = {
  listingId,

  storeFrontId,

  storeFront,

  listingDetail,

  productDetail,

  productWithFullDetails,

  textQuantityForProduct,
};

const builder = createViewModelMap(accessors);

const buildView = state => ({
  ...baseViews(state),
  ...builder(state),
});

export const createViewModel = buildView;
