import * as Sentry from '@sentry/browser';
import { Alert } from 'antd';

import React from 'react';

export const errorTemplate = (
  <Alert
    type="error"
    showIcon
    message="Something unexpected happened and we are busy looking into it for you!"
  />
);
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return errorTemplate;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
