import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames } from './events';

const sliceReducer = createSliceReducer(x => x.profile);

export const newState = () => ({
  selectedVendorId: 0,
  attemptingLogout: false,
  initialized: false,
});

const initialState = {
  profile: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

export const selectedVendor = sliceReducer((profile, { payload: { vendorId } }) => {
  profile.selectedVendorId = vendorId;
});

reduceWith(selectedVendor, eventNames.selectedVendor);

export const changedDetails = sliceReducer((profile, { payload: { details } }) => {
  Object.assign(profile, details);
});

reduceWith(changedDetails, eventNames.changedDetails);
