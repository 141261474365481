/* eslint-disable */

import { css } from 'emotion';

export const contentWrapper = css`
  width: 330px;
  background: var(--gray-000);
  color: var(--gray-900);
  margin: 40px auto 0;
  border-radius: 2px;
  text-align: center;
  padding: 24px;
  border: 1px solid var(--gray-300);

  p,
  button {
    margin-top: 24px;
  }

  p {
    color: var(--gray-900);
  }
`;
