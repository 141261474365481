import config from 'config';
import { useState } from 'react';

const reassignVideoSrc = x => {
  x.src = x.src;
};

const stopPlayer = x => x.stop();

export default ({ src = '', fallbackImageUrl = config.images.fallback } = {}) => {
  const [youtubeVideoVisible, setYoutubeVideoVisible] = useState(false);
  const srcSplit = src.split('/');
  const srcId = srcSplit[srcSplit.length - 1];
  const thumbnailSrc = `https://img.youtube.com/vi/${srcId}/0.jpg`;

  const stopVideo = () => {
    document.querySelectorAll('iframe').forEach(reassignVideoSrc);
    document.querySelectorAll('video').forEach(stopPlayer);
    setYoutubeVideoVisible(!youtubeVideoVisible);
  };

  return {
    fallbackImageUrl,
    thumbnailSrc,
    youtubeVideoVisible,

    stopVideo,
  };
};
