import { css } from 'emotion';

export const setupWrapper = css`
  padding: 0 24px;
  img {
    display: block;
    width: 140px;
    height: auto;
    margin: 127px auto 0;
  }
  @media (min-width: 768px) {
    margin: 178px auto 0;
  }
`;

export const setupContainer = css`
  min-width: 330px;
  max-width: 636px;
  margin: 39px auto 0;
  background: var(--gray-000);
  padding: 24px;
  border: 1px solid var(--gray-300);

  .greeting {
    text-align: center;
  }
  p {
    margin-top: 24px;
  }

  .ant-select {
    .anticon svg {
      color: var(--gray-400);
    }
  }

  form {
    margin-top: 24px;
    > div + div {
      margin-top: 24px;
    }
    input[type='text'] {
      margin-top: 2px;
    }
  }

  .state-wrapper {
    margin-top: 24px;
    label {
      display: block;
    }
    .state-selector {
      width: 100%;
      margin-top: 2px;
    }
  }

  .license-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    p {
      margin: 0;
      flex: 1 0 100%;
      color: var(--gray-700);
    }
    button {
      margin-left: auto;
    }
  }

  .search-licenses,
  .buttonContainer {
    margin-top: 24px;
  }

  .search-licenses {
    .search-autocomplete {
      width: 100%;
      margin-top: 2px;
    }
  }

  .setup-alert {
    margin-top: 24px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    > button + button {
      margin-left: 24px;
    }
  }
  .remove-button {
    padding-right: 0;
  }
  @media (min-width: 768px) {
    form {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      > div + div {
        margin-top: 0;
      }
      > div {
        flex: 1 1 auto;
        max-width: calc(50% - 24px);
        &:nth-child(3n) {
          margin-top: 24px;
        }
        &:nth-child(even) {
          margin-left: 24px;
        }
      }
    }

    .state-wrapper {
      .state-selector {
        width: calc(50% - 24px);
      }
    }

    .license-row {
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      p {
        flex: 1 1 auto;
      }
      button {
        margin: initial;
      }
    }
  }
`;
