import React from 'react';
import Unauthorized from 'src/routes/unauthorized';
import { loggedIn } from 'utils/auth0';

let skipLoginCheck = false;

const skipLogin = () => {
  skipLoginCheck = true;
};

export const __test__ = {
  skipLogin,
};

export const withLogin = Template => props =>
  loggedIn() || skipLoginCheck ? <Template {...props} /> : <Unauthorized />;
