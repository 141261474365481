import './reducers';
import { hocWithMappings as hoc, page, withSEO } from 'utils/views';
import Signup from './template';
import controllerEventHandlers from './controller.eventHandlers';
import { createTasks } from './tasks';
import { createViewModel } from './viewModel';
import { Landing as withLayout } from 'layouts';

const TemplateWithEventHandlers = hoc('Login Event Handlers', controllerEventHandlers);

export default withSEO({
  title: 'Sign Up',
})(
  page({
    Template: TemplateWithEventHandlers(Signup),
    withLayout,
    createTasks,
    createViewModel,
  })
);


