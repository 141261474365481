import { addInitialReducer, reduceWith } from 'utils/reducers';
import { eventNames, getStoreProductsKey } from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';
import { createExtraReducerState } from './impl';
import { eventNames as filterEventNames } from '../components/Filter/ux/events';
import { sliceReducer } from './sliceReducer';
import { sortOrderList } from '../shared/sortOrders';

export const newState = () => ({
  changes: 0,
  storeFrontId: 0,
  cart: {},
  initialized: false,
  getStoreProductsStatus: requestState(),
  productsUpdated: false,
  listingId: '',
  searchTerm: '',
  sortId: sortOrderList[0].id,
  pageNumber: 1,
  pageSize: 10,
  ...createExtraReducerState(),
});

const initialState = {
  store: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

export const initializedCartForStoreFront = sliceReducer(
  (slice, { payload: { storeFrontId } }) => {
    slice.cart[storeFrontId] = {};
  }
);

reduceWith(initializedCartForStoreFront, eventNames.initializedCartForStoreFront);

export const productsUpdated = sliceReducer(slice => {
  slice.productsUpdated = !slice.productsUpdated;
});

reduceWith(productsUpdated, eventNames.productsUpdated);

export const triggeredChange = sliceReducer(slice => {
  slice.changes = slice.changes + 1;
});

const filtersChanged = sliceReducer(slice => {
  slice.pageNumber = 1;
});

reduceWith(filtersChanged, filterEventNames.changed);

registerRequestStatusChangeReducers(
  getStoreProductsKey,
  val => val.store.getStoreProductsStatus
);
