import { events, storeFrontCreatePurchaseOrderKey } from '../events';
import { createTasks as createImplTasks } from './impl';
import { createTasks as createRequestTasks } from 'state/slice/request/tasks';
import { createTasks as createStoreTasks } from '../../tasks';
import { createViewModel } from '../viewModel';
import { getCurrentState } from 'utils/redux';
import { logException } from 'utils/core/funcy';
import { newState } from '../reducers';
import { routes } from 'router';

const { cart } = routes;

const _dependencies = {
  createViewModel: (state = getCurrentState()) => createViewModel(state),
  createRequestTasks,
  createStoreTasks,
  createImplTasks,
};

export const createTasks = (dispatch, props = {}, dependencies = _dependencies) => {
  const {
    createViewModel: views,
    createRequestTasks,
    createStoreTasks,
    createImplTasks,
  } = dependencies;
  const storeFrontCreatePurchaseOrderTasks = createRequestTasks(
    storeFrontCreatePurchaseOrderKey,
    dispatch
  );
  const {
    changeCartProductQuantity,
    completeOrder,
    display,
    resume,
    removeLineItemsFlaggedForDeletion,
    changeCartProductNote,
    reloadProducts,
  } = createStoreTasks(dispatch, props);

  const init = async () => {
    await resume();
  };

  const displayCart = async () => {
    const { currentStoreDisplayArgs } = views();

    await cart(currentStoreDisplayArgs);
  };

  const changeDetails = details => {
    dispatch(events.changedDetails(details));
  };

  const {
    placeOrder: placeOrderImpl,
    viewCart: viewCartImpl,
    ...implRest
  } = createImplTasks({
    changeDetails,
    completeOrder,
    views,
    storeFrontCreatePurchaseOrderTasks,
    dispatch,
    removeLineItemsFlaggedForDeletion,
    reloadProducts,
  });

  const viewCart = async () => {
    await viewCartImpl();
    await displayCart();
  };

  const changeOrderNote = value => {
    changeDetails({
      orderNote: value,
    });
  };

  const backToStore = async () => {
    const { currentStoreDisplayArgs } = views();
    await reloadProducts();
    await display(currentStoreDisplayArgs);
    removeLineItemsFlaggedForDeletion();
  };

  const placeOrder = async () => {
    await logException(async () => {
      await reloadProducts();

      const { canPlaceOrder } = views();

      if (!canPlaceOrder) return;

      const { orderNumber } = await placeOrderImpl();

      await completeOrder(orderNumber);

      changeDetails(newState());
    });
  };

  return {
    init,
    changeCartProductQuantity,
    changeCartProductNote,
    changeOrderNote,
    placeOrder,
    backToStore,
    viewCart,
    ...implRest,
  };
};

export const __test__ = {
  dependencies: _dependencies,
};
