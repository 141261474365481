import { css } from 'emotion';

export const failure = css`
  background: var(--red-200);
`;

export const processingMessage = css`
  color: var(--blue-700);
`;

export const signupWrapper = css`
  width: 330px;
  margin: 40px auto 0;
  border-radius: 2px;
  background: var(--gray-000);
  padding: 24px;
  border: 1px solid var(--gray-300);

  h3 {
    color: var(--gray-900);
    text-align: center;
  }

  label {
    display: block;
    margin-top: 24px;
  }

  input[type='text'],
  input[type='password'] {
    margin-top: 2px;
  }

  p,
  button {
    margin-top: 24px;
  }

  p {
    color: var(--gray-700);
    &:last-child {
      text-align: center;
    }
    &.success {
      text-align: center;
    }
  }

  .ant-input-password-icon {
    fill: var(--purp-200);
    color: var(--purp-200);
    &:hover,
    &:focus {
      fill: var(--purp-100);
      color: var(--purp-100);
    }
    &:active {
      fill: var(--purp-300);
      color: var(--purp-300);
    }
  }

  .passwordRuleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    p {
      margin: 0 0 0 9px;
      text-align: left;
      &.success {
        color: var(--green-200);
      }
      &.error {
        color: var(--red-200);
      }
    }
    svg {
      color: var(--red-200)
      fill: var(--red-200)
    }
    &.satisfied {
      svg {
  color: var(--green-200);
  fill: var(--green-200);
      }
    }
  }

  .account-fail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 24px;
    h4 {
      margin: 0 0 0 9px;
      color: var(--red-200);
    }
    p {
      margin: 4px 0 0;
      flex: 1 0 100%;
      color: var(--red-200);
    }
    svg {
      color: var(--red-200)
      fill: var(--red-200)

    }
  }
`;
