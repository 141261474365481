import { component, hocWithMappings as hoc } from 'utils/views';
import Template from './template';
import controllerListing from '../../../listing/controller';
import { createTasks } from './tasks';
import createViewModel from '../../../shared/viewModel';

const TemplateWithController = hoc('Product Card - Listing UX', controllerListing);

export default component({
  Template: TemplateWithController(Template),
  createViewModel,
  createTasks,
});
