import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

export const signupKey = `${base}-signup`;

export const eventMap = {
  initialized: () => ({}),
  changedDetails: details => ({ details }),
};

export const { eventNames, events } = createEvents(base, eventMap);
