import { byType } from './reducerFilters';
import curry from '@developwithpassion/curry_js';
import { each } from '@developwithpassion/arrays_js';
import produce from 'immer';
import { regex } from '@developwithpassion/matchers_js';

const initCriteria = byType(regex(/@@(redux\/)?INIT/));

let reducer = state => state;
let initReducer = (state = {}) => state;

export const combineReducers = (previous, next) => (state, action) =>
  next(previous(state, action), action);

export const createFilteredReducer = (filter, actualReducer) => (state, action) =>
  filter(action) ? actualReducer(state, action) : state;

export const add = next => (reducer = combineReducers(reducer, next));

export const addInitial = next => (initReducer = combineReducers(initReducer, next));

export const reduceWith = (listener, ...events) =>
  each(event => add(createFilteredReducer(byType(event), listener)), events);

export const appReducer = (...args) => reducer(...args);

export const addInitialReducer = reducer =>
  addInitial(state => ({ ...reducer(state), ...state }));

export const mainInitReducer = (...args) => initReducer(...args);

add(createFilteredReducer(initCriteria, state => initReducer(state)));

export const createInitialState = () => initReducer();

export const createSliceReducer = curry((selector, reducerAction) => (state, action) =>
  produce(state, draft => {
    const data = selector(draft);
    reducerAction(data, action);
  })
);
