import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames } from './events';

const sliceReducer = createSliceReducer(x => x.auth);

export const newState = () => ({
  message: 'Logging In',
  isInitializationComplete: false,
});

const initialState = {
  auth: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

export const changedDetails = sliceReducer((auth, { payload: { details } }) => {
  Object.assign(auth, details);
});

reduceWith(changedDetails, eventNames.changedDetails);
