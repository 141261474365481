import { completeLogin, initAuthClient, resumeLogin } from 'utils/auth0';
import { createViewModel } from './viewModel';
import { getCurrentState } from 'utils/redux';

export const createTasks = (/* dispatch*/) => {
  const views = (state = getCurrentState()) => createViewModel(state);

  const init = async () => {
    await initAuthClient();
  };

  const complete = async () => {
    const { success, appState: { startingUri = '/busy' } = {} } = await completeLogin();

    return { success, startingUri };
  };

  const run = async () => {
    const { success, startingUri } = await complete();

    if (success) return { success, initialPath: startingUri };

    const { redirectPath } = views();

    const loggedIn = await resumeLogin();

    return { success: loggedIn, initialPath: redirectPath };
  };

  return {
    init,
    completeLogin,
    run,
  };
};
