const create = (behaviour, delay) => {
  let timer_id = null;

  const start = async () =>
    new Promise((resolve, reject) => {
      timer_id = setTimeout(() => {
        try {
          resolve(behaviour());
        } catch (e) {
          reject(e);
        }
      }, delay);
    });

  const stop = () => {
    clearTimeout(timer_id);
    timer_id = null;
  };

  const restart = async () => {
    stop();
    return await start();
  };

  return {
    start,
    stop,
    restart,
  };
};

export default create;
