import { between, greater_than_or_equal_to } from '@developwithpassion/matchers_js';
import { useState } from 'react';

export default ({ categoryRows = [] } = {}) => {
  const [currentCategorySectionId, setCurrentCategorySectionId] = useState(null);

  const categoryTrackers = categoryRows.map((data, index) => {
    const criteria =
      index === categoryRows.length - 1
        ? greater_than_or_equal_to(data.rowIndex)
        : between(data.rowIndex - 1, categoryRows[index + 1].rowIndex - 2);
    return {
      isActive: criteria,
      data,
    };
  });

  const onItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
    if (categoryRows.length === 0) return;

    const activeCategory = categoryTrackers.find(
      ({ isActive }) => isActive(visibleStartIndex) || isActive(visibleStopIndex)
    );

    const {
      data: {
        category: { id },
      },
    } = activeCategory;

    if (currentCategorySectionId === id) return;

    setCurrentCategorySectionId(id);
  };

  return {
    onItemsRendered,
    currentCategorySectionId,
  };
};
