export const eventMap = {
  changedCartProductQuantity: ({ storeFrontId, productId, quantity }) => ({
    storeFrontId,
    productId,
    quantity,
  }),

  changedCartLineItemNote: ({ storeFrontId, productId, note }) => ({
    storeFrontId,
    productId,
    note,
  }),

  removedLineItemsFlaggedForDeletion: storeFrontId => ({ storeFrontId }),
};
