import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';

export const getJurisdictionsKey = `${base}-getJurisdictions`;
export const getVendorLicensesKey = `${base}-getVendorLicenses`;
export const getUserInfoKey = `${base}-getUserInfo`;
export const addVendorLicenseKey = `${base}-addVendorLicenses`;
export const getStoreFrontUserVendorsKey = `${base}-getStoreFrontUserVendors`;

export const eventMap = {
  initialized: () => ({}),

  selectedVendor: vendor => ({ vendor }),

  removedVendor: vendorId => ({ vendorId }),

  changedDetails: details => ({ details }),
};

export const { eventNames, events } = createEvents(base, eventMap);
