import * as Sentry from '@sentry/browser';
export { default as debounce } from 'lodash/debounce';
export { default as curry } from '@developwithpassion/curry_js';
import curry from '@developwithpassion/curry_js';

// This file consists of a minimum set of functional utils that
// can be used to "lightly" introduce functional composition styles
// along with introduction of point-free styles where applicable
// Identity - https://en.wikipedia.org/wiki/Identity_function
export const identity = val => val;

export const noOp = identity;

// Creates a composite function which executes provided functions in right->left order
export const compose = (...functions_to_compose) => {
  if (functions_to_compose.length === 0) return noOp;
  if (functions_to_compose.length === 1) return functions_to_compose[0];

  return functions_to_compose.reduce((a, b) => (...args) => a(b(...args)));
};

// Creates a composite function which executes provided functions in left->right order
export const pipe = (...functions_to_compose) =>
  compose(...functions_to_compose.reverse());

// Returns the value of property specified by 'key' on the target instance, curried
// for deferred execution against a target which is useful in pipelines
export const prop = curry((key, target) => target[key]);

// Perform an action against a `target` returning the target once the action has been performed,
// can be used in pipelines target is deferred
export const tap = curry((visitor, target) => {
  visitor(target);
  return target;
});

const createMessageBuilder = title => val => {
  /* eslint-disable no-console */
  console.log(`***INSPECT: ${title}`);
  console.log(val);
  /* eslint-enable no-console */
};

// Dumps `val` to the console prefixed by the message defined in `createMessageBuilder`
// along with a provided title. Useful for inspecting
// values as they are going through a pipeline
export const inspect = curry((title, val) => tap(createMessageBuilder(title), val));

// Catch any exceptions thrown by `func` which can be async, and simply log the exception to the console
export const logException = async func => {
  try {
    await func();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    Sentry.captureException(e);
  }
};

const reducerMapAssignmentStyles = {
  object: (target, key, val) => ({
    ...target,
    [key]: val,
  }),

  map: (target, key, val) => target.set(key, val),
};

const _reducerForUniqueMap = curry(
  (assignmentStyle, attributeName, itemMapper) => (acc, item) =>
    assignmentStyle(acc, item[attributeName], itemMapper(item))
);

export const reducerForUniqueMap = (attributeName, itemMapper = prop(attributeName)) =>
  _reducerForUniqueMap(reducerMapAssignmentStyles.object, attributeName, itemMapper);

export const reducerForUniqueInsertionOrderMap = (
  attributeName,
  itemMapper = prop(attributeName)
) => _reducerForUniqueMap(reducerMapAssignmentStyles.map, attributeName, itemMapper);
