import {
  ApplyButton,
  ApplyButtonContainer,
  FilterComponentWrapper,
  FilterModalWrapper,
  ModalSection,
  SortingOption,
  SortingOptionsWrapper,
  StickySection,
} from './styles';
import Filter from '../Filter';
import React from 'react';
import { noOp } from 'utils/core/funcy';

const SortingOptions = ({ sortingOptions = [] } = {}) => (
  <SortingOptionsWrapper>
    {sortingOptions.map(option => (
      <SortingOption key={option.key} isActive={option.isActive} onClick={option.onClick}>
        {option.title}
      </SortingOption>
    ))}
  </SortingOptionsWrapper>
);

const FilterModal = ({
  sortingOptions = [],
  onSortSelect = noOp,
  activeSortingOption = '',
  onCancel = noOp,
} = {}) => (
  <FilterModalWrapper>
    <ModalSection>
      <span className="label">Sort By</span>
      <SortingOptions
        sortingOptions={sortingOptions}
        onSortSelect={onSortSelect}
        activeSortingOption={activeSortingOption}
      />
    </ModalSection>
    <ModalSection>
      <span className="label">Filters</span>
      <FilterComponentWrapper>
        <Filter />
      </FilterComponentWrapper>
    </ModalSection>
    <StickySection>
      <ApplyButtonContainer>
        <ApplyButton onClick={onCancel} type="primary">
          Close
        </ApplyButton>
      </ApplyButtonContainer>
    </StickySection>
  </FilterModalWrapper>
);

export default FilterModal;
