import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames } from './events';

const sliceReducer = createSliceReducer(val => val.storeFilters);

const createRange = () => ({
  start: 0,
  end: 0,
  changed: false,
  isExpanded: false,
});

const createRangeReducer = attribute =>
  sliceReducer(({ [attribute]: range }, { payload: { start, end } }) => {
    Object.assign(range, {
      start,
      end,
      changed: true,
    });
  });

export const newState = () => ({
  expandedFilterGroups: {},
  selectedFilterGroupItems: {},
  thcRange: createRange(),
  cbdRange: createRange(),
  priceRange: createRange(),
});

const initialState = {
  storeFilters: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});
reduceWith(changedDetails, eventNames.changedDetails);

const updatedFilterGroupItems = sliceReducer(
  ({ selectedFilterGroupItems }, { payload: { filterIds, enabled } }) => {
    filterIds.forEach(id => {
      selectedFilterGroupItems[id] = enabled;
    });
  }
);
reduceWith(updatedFilterGroupItems, eventNames.updatedFilterGroupItems);

reduceWith(createRangeReducer('thcRange'), eventNames.changedTHCRange);
reduceWith(createRangeReducer('cbdRange'), eventNames.changedCBDRange);
reduceWith(createRangeReducer('priceRange'), eventNames.changedPriceRange);

const clearedRange = sliceReducer((slice, { payload: { groupId } }) => {
  slice[groupId] = createRange();
});
reduceWith(clearedRange, eventNames.clearedRange);

export const __test__ = {
  createRangeReducer,
  createRange,
  initialState,
};
