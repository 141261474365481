import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { identity, prop } from 'utils/core/funcy';
import { createViewModel as createRequestStatusViews } from 'state/slice/request/viewModel';
import { fileAbsolute } from 'paths.macro';
import { nonEmptyString } from 'utils/matchers';
import { ruleMap } from './passwordComplianceRules';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);
const signup = viewModelAttribute('Main ViewModel', ({ signup }) => signup, identity);

const errorMessage = viewModelAttribute(signup, prop('errorMessage'));

const hasError = viewModelAttribute(errorMessage, nonEmptyString);

const complianceRulesMet = viewModelAttribute(signup, prop('complianceRulesMet'));

const passwordModified = viewModelAttribute(signup, prop('passwordModified'));

const passwordComplianceRulesDetails = viewModelAttribute(complianceRulesMet, val =>
  Object.entries(val).reduce(
    (acc, [id, isSatisfied]) => acc.concat({ ...ruleMap[id], isSatisfied }),
    []
  )
);

const passwordRulesSatisfied = viewModelAttribute(complianceRulesMet, val =>
  Object.values(val).every(identity)
);

const signupRequestStatus = viewModelAttribute(signup, ({ signupRequestStatus }) =>
  createRequestStatusViews(signupRequestStatus)
);

const accessors = {
  hasError,
  errorMessage,
  signupRequestStatus,
  passwordModified,
  passwordComplianceRulesDetails,
  passwordRulesSatisfied,
};

export const createViewModel = createViewModelMap(accessors);
