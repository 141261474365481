import { authOptions, runGQLRequest } from 'utils/xhrs';
import config from 'config';

const query = `
 query ($state: String!) {
  getStoreFrontUserVendors(state: $state) {
    Id,
    UserId,
    BuyerVendorId,
    BuyerVendorName,
    BuyerVendorLicenseNumber
  }
}`;

export default async state => {
  const r = await runGQLRequest(
    'getStoreFrontUserVendors',
    config.legacyGQL.endpoint,
    {
      operationName: null,
      variables: {
        state: state.toLowerCase(),
      },
      query,
    },
    {
      headers: {
        ...(await authOptions()),
      },
    }
  );
  return r.data.data.getStoreFrontUserVendors;
};
