import { routes } from 'router';

const { busy } = routes;

export const createTasks = (/* dispatch*/) => {
  const init = async () => {};

  const display = async () => {
    busy();
  };

  return {
    display,
    init,
  };
};
