import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';

export default ({ showDetail }) => {
  const createListingCardActions = listingCardId => ({
    onClick: createEventHandler(() => showDetail(listingCardId)),
  });

  return {
    createListingCardActions,
  };
};
