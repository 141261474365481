import Lottie from 'react-lottie';
import React from 'react';
import loaderData from './loader.json';
import { loadingIndicatorContainer } from './styles';

const Loader = ({ width = 375, height = 375, message = null }) => (
  <div className={loadingIndicatorContainer}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loaderData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      width={width}
      height={height}
    />
    {message ? <p style={{ textAlign: 'center' }}>{message}</p> : null}
  </div>
);

export default Loader;
