import { addInitialReducer, createSliceReducer, reduceWith } from 'utils/reducers';
import { eventNames, storeFrontCreatePurchaseOrderKey } from './events';
import {
  registerReducers as registerRequestStatusChangeReducers,
  newState as requestState,
} from 'state/slice/request/reducers';

const sliceReducer = createSliceReducer(x => x.cartOrder);

export const newState = () => ({
  storeFrontCreatePurchaseOrderRequestStatus: requestState(),
  orderNote: '',
});

const initialState = {
  cartOrder: newState(),
};

const initialized = (/* state*/) => initialState;

addInitialReducer(initialized);

const changedDetails = sliceReducer((slice, { payload: { details } }) => {
  Object.assign(slice, details);
});

reduceWith(changedDetails, eventNames.changedDetails);

registerRequestStatusChangeReducers(
  storeFrontCreatePurchaseOrderKey,
  val => val.cartOrder.storeFrontCreatePurchaseOrderRequestStatus
);
