import { currentPath, route } from 'router';
import { createTasks as createImplTasks } from './impl';
import { createViewModel } from '../viewModel';
import { events } from '../events';
import { getCurrentState } from 'utils/redux';

const _dependencies = {
  createViewModel,
};

export const createTasks = (dispatch, { dependencies = _dependencies } = {}) => {
  const { createViewModel } = dependencies;

  const views = (state = getCurrentState()) => createViewModel(state);

  const changeDetails = details => {
    dispatch(events.changedDetails(details));
  };

  const captureInitialPath = (path = currentPath()) => {
    changeDetails({ initialPath: path });
  };

  const ready = () => {
    changeDetails({ busy: false });
  };

  const implTasks = createImplTasks({
    dispatch,
    ready,
    captureInitialPath,
    currentPath,
    changeDetails,
    views,
  });

  const { init: implInit } = implTasks;

  const init = async () => {
    captureInitialPath();
    await route({ path: '/' });
    await implInit();
  };

  const handle404 = async () => {
    window.location.href = '/';
  };

  return {
    handle404,
    ...implTasks,
    init,
  };
};
