import { css } from 'emotion';

export const navContainer = css`
  position: sticky;
  top: 0;
  z-index: 101;
  background: var(--gray-100);
  border-bottom: 1px solid var(--gray-400);
  box-shadow: 0 4px 6px -4px var(--gray-400);
  .inner-container {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    transform: translateY(0);

    > * {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .logo {
    flex: 1 1 auto;
    img {
      width: 40px;
      height: auto;
    }
  }

  .page-indicator {
    &.active-path {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        box-shadow: inset 0px -2px 0px #6b57f8;
      }
    }
  }

  p {
    &.browse,
    &.selected-store {
      color: var(--blue-700);
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        color: var(--blue-500);
      }
    }
  }

  @media (min-width: 768px) {
    .inner-container {
      justify-content: flex-start;
      > :last-child {
        margin-left: 46px;
      }
      .logo {
        img {
          width: 93px;
        }
      }
    }
  }
`;
