import base from 'paths.macro';
import createEvents from 'utils/redux/createEvents';
import { eventsForKey } from 'state/slice/request/events';

export const storeFrontRequestAccessKey = `${base}-storeFrontRequestAccessStatus`;
export const { eventNames: requestAccessActionTriggers } = eventsForKey(
  storeFrontRequestAccessKey
);

export const eventMap = {
  initialized: () => ({}),
  changedDetails: (id, details) => ({ id, details }),
  requestAccessActions: eventsForKey(storeFrontRequestAccessKey),
  changedAccessStatus: (id, newStatus) => ({ id, newStatus }),
};

export const { eventNames, events } = createEvents(base, eventMap);
