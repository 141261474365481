import { Alert, Button, Input } from 'antd';
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  WarningFilled,
} from '@ant-design/icons';
import React, { Fragment, useEffect, useState } from 'react';
import CartByCategory from '../../components/CartByCategory';
import ProductCard from '../components/ProductCard';
import ProgressBar from 'components/ProgressBar';
import RequestStatus from 'components/RequestStatus';
import StoreInfo from '../../components/StoreInfo';
import { cartPageContainer } from './styles';
import { createEventHandler } from 'utils/ui/createEventHandler';
import { noOp } from 'utils/core/funcy';

const LiveCart = (props = {}) => {
  const {
    storeFrontCreatePurchaseOrderRequestStatus = 0,
    cartProducts = [],
    invalidCartProducts = [],
    cartTotal = 0.0,
    backToStore = noOp,
    productWithFullDetails = noOp,
    isDesktop = true,
    isTabletAndBelow: isTablet = false,
    orderNote = '',
    placeOrder = noOp,
    changeOrderNote = noOp,
    canPlaceOrder = false,
    cartAlerts = [],
    flags: { sfShopByCategory = false, wsLiveProductStatus = false } = {},
    storeFront: { showProductStatusFilter = false },
  } = props;

  const [showCartByCategory, setShowCartByCategory] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (storeFrontCreatePurchaseOrderRequestStatus.failed) setLoading(false);
  }, [storeFrontCreatePurchaseOrderRequestStatus.failed]);

  const changeHandler = createEventHandler(({ target: { value } }) =>
    changeOrderNote(value)
  );

  const placeOrderButtonTemplate = (
    <Button
      type="primary"
      disabled={!canPlaceOrder || loading}
      onClick={() => {
        setLoading(true);
        placeOrder();
      }}
    >
      PLACE ORDER
    </Button>
  );

  const orderNoteTemplate = (
    <Input.TextArea
      value={orderNote}
      onChange={changeHandler}
      autoSize
      placeholder="Add a note to your order"
    />
  );

  const placeOrderTemplate = (
    <div className="place-order-container">
      <div className="total-container">
        <h5>TOTAL</h5>
        {sfShopByCategory && cartProducts.length > 0 ? (
          <a onClick={() => setShowCartByCategory(true)}>
            <p className="semi-bold">${cartTotal.toFixed(2)}</p>
          </a>
        ) : (
          <p className="semi-bold">${cartTotal.toFixed(2)}</p>
        )}
      </div>
      {placeOrderButtonTemplate}
    </div>
  );

  const backToProductsTemplate = (
    <div className="back-cart" onClick={backToStore}>
      <LeftOutlined className="icon" />
      <p className="semi-bold">Back to products</p>
    </div>
  );

  const invalidItemsTemplate = (
    <Fragment>
      <Alert
        className="cart-errors-container"
        type="error"
        message={
          <p>{invalidCartProducts.length} items in your cart have recently changed</p>
        }
        description={invalidCartProducts.map(({ listingName }, index) => (
          <p>
            {index + 1}. {listingName} doesn't have enough inventory remaining, please
            adjust the quantity in your cart
          </p>
        ))}
        showIcon
        icon={<ExclamationCircleOutlined />}
      />
    </Fragment>
  );

  const cartAlertMessageTemplate = ({ type, message }) => {
    const messageWrapper = (
      <div>
        {message} <a onClick={backToStore}>Continue Shopping</a>
      </div>
    );

    return (
      <Alert showIcon icon={<WarningFilled />} type={type} message={messageWrapper} />
    );
  };

  const cartAlertsTemplate = (
    <div className="cart-alerts">{cartAlerts.map(cartAlertMessageTemplate)}</div>
  );

  const cartHeaderTemplate = (
    <div className="cart-header-container">
      {isDesktop ? (
        <div className="desktop-header">
          <StoreInfo {...props} isCartPage isDesktop={false} />
          {cartAlertsTemplate}
          <div className="nav-container">
            {invalidCartProducts.length > 0 ? invalidItemsTemplate : null}
            {backToProductsTemplate}
            {placeOrderTemplate}
          </div>
        </div>
      ) : (
        <Fragment>
          {backToProductsTemplate}
          <StoreInfo {...props} isCartPage isDesktop={false} />
          {cartAlertsTemplate}
          {placeOrderTemplate}
        </Fragment>
      )}
      {orderNoteTemplate}
    </div>
  );

  const productsTemplate = (
    <Fragment>
      {cartProducts.map(({ id }) => (
        <ProductCard
          key={id}
          {...productWithFullDetails(id)}
          isTablet={isTablet}
          showProductStatusFilter={showProductStatusFilter && wsLiveProductStatus}
        />
      ))}
    </Fragment>
  );

  const failedToCreateOrderTemplate = (
    <Fragment>
      <Alert type="warning" description="Failed to create order." />
      {productsTemplate}
    </Fragment>
  );

  const progressBarTemplate = <ProgressBar simulateProgress={true} />;

  return (
    <div className={cartPageContainer}>
      {cartHeaderTemplate}
      <RequestStatus
        status={storeFrontCreatePurchaseOrderRequestStatus}
        inactiveTemplate={productsTemplate}
        completedTemplate={progressBarTemplate}
        startedTemplate={progressBarTemplate}
        failedTemplate={failedToCreateOrderTemplate}
      />
      <div className="bottom-place-order">{placeOrderTemplate}</div>
      {showCartByCategory && (
        <CartByCategory
          setIsVisible={setShowCartByCategory}
          isVisible={showCartByCategory}
          {...props}
        />
      )}
    </div>
  );
};

export default LiveCart;
