import { Menu as AntdMenu, Slider as AntdSlider, Tree as AntdTree, Button } from 'antd';
import styled from 'styled-components';

export const FilterWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800;900&display=swap');
  background: var(--gray-100);
  overflow-y: scroll;
  height: 100%;
  i {
    display: none;
  }
  svg {
    width: 10px;
    height: 10px;
    color: var(--gray-600);
  }
  .ant-menu-submenu-title {
    padding-left: 0px !important;
  }
  .ant-input-affix-wrapper > input.ant-input::placeholder {
    color: #949495;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--purp-200);
    box-shadow: 0 0 0 2px rgba(107, 87, 248, 0.2);
  }
`;

export const Tree = styled(AntdTree)`
  width: 100%;
  background: var(--gray-100);
  padding: 9px 24px;

  .ant-tree-list-holder-inner .ant-tree-treenode {
    padding: 9px 0px;
  }

  .ant-tree-checkbox,
  .ant-tree-checkbox-inner {
    background-color: unset;
    border-radius: 2px;
    &:hover {
      border-color: var(--purp-200);
    }
  }

  .ant-tree-checkbox {
    border-radius: 2px;
    margin: 4px 5px 0px 0px;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background: var(--purp-200);
    border-color: var(--purp-200);
    border-radius: 2px;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background: var(--purp-200);
    border: solid 1px var(--purp-200);
    border-radius: 2px;
    &::after {
      position: absolute;
      display: table;
      border-bottom: 2px solid white;
      opacity: 1;
      -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
      top: 20%;
      width: 7px;
      height: 9px;
      background-color: unset;
    }
  }

  .ant-tree-title {
    color: var(--gray-900);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 9px;
  }
`;
// .ant-tree-node-content-wrapper:hover,
// .ant-tree-node-content-wrapper-open:hover {
//   background: none;
// }

export const SubMenuLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--gray-700);
  text-transform: uppercase;
`;

export const Menu = styled(AntdMenu)`
  padding-left: 0;
  margin-left: 0;
  padding-bottom: 200px;
  width: 100%;
  background-color: var(--gray100);
  overflow: scroll;
  border: 0px;
  i {
    display: none;
  }
  svg {
    width: 10px;
    height: 10px;
    color: var(--gray-600);
  }
  div.ant-tree-list-holder-inner {
    padding-bottom: 25px;
  }
  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  .ant-tree .ant-tree-switcher {
    width: 0px;
  }
`;

export const SliderContainer = styled.div`
  padding: 7px 24px;
  background: var(--gray-100);
  .ant-slider .ant-slider-handle-1,
  .ant-slider .ant-slider-handle-2 {
    height: 10px;
    width: 10px;
    margin-top: -3px;
    border-color: var(--purp-200);
    &:hover {
      border-color: var(--purp-200);
    }
  }
  .ant-slider .ant-slider-mark {
    margin-left: 5px;
    max-width: 225px;
    width: 90%;
    border-color: var(--purp-200);
    &:hover {
      border-color: var(--purp-200);
    }
  }
  .ant-slider-mark-text:not(:first-of-type):not(:last-of-type) {
    transform: translate(-30%, -150%) !important;
    min-width: 50px;
    display: flex;
    justify-content: space-around;
  }
  .ant-slider .ant-slider-track {
    background: var(--purp-300);
    &:hover {
      background: var(--purp-300);
    }
  }
  margin-left: -14px;
  width: calc(100% + 14px);
`;

export const Slider = styled(AntdSlider)``;

export const SearchContainer = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const ClearButton = styled(Button)`
  display: inline;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  padding: 0px;
  margin-right: 24px;
  border: 0;
  color: var(--purp-200);
  font-size: 14px;
  background: var(--gray-100);
  &:hover {
    background: var(--gray-100);
  }
`;

export const FilterLabel = styled.button`
  background: none;
  border: 0px;
  cursor: pointer;
  color: var(--gray-900);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;
