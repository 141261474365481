import { Progress as AntdProgress } from 'antd';
import styled from 'styled-components';

export const Progress = styled(AntdProgress)`
  .ant-progress-outer {
    padding: 0px;
    margin: 0px;
  }
  .ant-progress-inner {
    border-radius: 0px;
  }
  .ant-progress-bg {
    border-radius: 0px;
  }
  position: absolute;
  top: -8px;
  left: 0px;
  min-width: 100vw;
  padding: 0;
  margin: 0;
  z-index: 9000;
  background: var(--gray-100);
`;
